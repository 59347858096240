<template>
  <!-- справочная информация -->
  <v-card v-bind="$attrs">
    <v-expansion-panels :class="{ border }" flat :mandatory="open">
      <v-expansion-panel>
        <v-expansion-panel-header
          class="text-button text--secondary cursor-help"
          :hide-actions="open"
          ><span
            ><v-icon left small color="secondary"
              >mdi-help-circle-outline</v-icon
            >{{ caption }}</span
          ></v-expansion-panel-header
        >
        <v-expansion-panel-content
          class="text-justify text-body-2 text--secondary"
        >
          <v-form readonly>
            <slot />
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
export default {
  name: 'HelpCard',
  inheritAttrs: false,
  props: {
    open: {
      default: false,
    },
    caption: {
      default: 'Справочная информация',
    },
    border: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.cursor-help {
  cursor: help;
}
.border {
  border: 2px solid rgba(0, 147, 0, 0.99);
}
</style>
