export const P_MNN1 = 1
export const P_MNN2 = 2
export const P_MNN3 = 3
export const P_MNN4 = 4

export const PARSER_VERSION = '1'

//ЗАМЕНА ДЛЯ BR В ЭЛЕМЕНТЕ MAMMOTH
export const BR_COSTUL = '←!→'

//РАССТАВЛЯЕМ ФАЙЛЫ ПО ИМЕНОВАНЫМ ГРУППАМ
const GET_CLEAR_FILE_NAME = 'см\\..*?файл:\\s*?(?<file>.+?\\.[a-z]{3,4})(?=[<])'

export const CONDITION_PARSE = [
  'заявленные показания к применению лекарственного препарата согласно инструкции по применению.*?<em>(.*?)<\\/em>.*?государственная регистрация лекарственного препарата',
]

export const REPRODUCED_TEXT_PARSE = [
  'сведения о воспроизведённых лекарственных препаратах \\(при наличии\\).*?<em>(.*?)<\\/em>.*?<\\/',
]

export const LOC_DATA_PARSE = [
  'данные о производстве лекарственного препарата в Российской Федерации.*?<em>(.*?)<\\/em>.*?предлагаемые к включению лекарственные формы',
]

export const lpCompareDrug_ADDRESS_PARSE = [
  'наименование и адрес \\(место нахождения\\) юридического лица.*?<em>(.*?)<\\/em>.*?данные о производстве лекарственного препарата',
]

export const RU_FILES_PARSE = [
  'регистрационное удостоверение(.*?)наименование и адрес \\(место нахождения\\)',
]

export const RU_DATECONFIRM_PARSE = [
  'дата подтверждения государственной регистрации лекарственного препарата \\(при наличии\\).*<em>(.*?)<\\/em>.*?регистрационное удостоверение<',
]

export const RU_DATE_PARSE = [
  'дата.*?<em>(.*?)<\\/em>.*?номер регистрационного удостоверения',
]

export const RU_NUMBER_PARSE = [
  'номер регистрационного удостоверения.*?<em>(.*?)<\\/em>.*?дата подтверждения государственной регистрации',
]

export const FORMA_PARSE = [
  'предлагаемые к включению лекарственные формы зарегистрированного лекарственного препарата.*?<em>(.*?)<\\/em>.*?сведения о воспроизведённых лекарственных',
]

export const ATH_PARSE = [
  'код анатомо-терапевтическо-химической классификации лекарственного препарата.*?<em>(.*?)<\\/em>.*?заявленные показания к применению',
]

export const EPID_NECESSITY_PARSE = [
  'статистического наблюдения \\(при наличии таких статистических данных\\)(.*?)наличие научно обоснованных данных',
]

export const JUSTIFICATION14VZN_PARSE = [
  'уже включенными в перечни лекарственных препаратов(.*?)наличие научно обоснованной информации о преимуществах',
]

export const ADVANTAGE_PARSE = [
  'заболеваемости и смертности в Российской Федерации(.*?)востребованность \\(социальная значимость\\) лекарственного',
]

export const REGION_PARSE = [
  'финансируемых за счет средств бюджетов субъектов Российской Федерации(.*?)наличие лекарственного препарата в перечне стратегически',
]

export const LIST_PARSE = [
  '1141\\-р(.*?)наличие \\(локализация\\) производства',
]

export const LOCALIZATION_PARSE = [
  'наличие \\(локализация\\) производства лекарственного препарата в Российской Федерации(.*?)$',
]

export const EPID_DATA_PARSE = [
  'источников и эпидемиологических исследований распространенности заболевания\\)(.*?)клинические данные — полнотекстовые версии',
]

export const CLIN_DATA_PARSE = [
  '871\\)(.*?)данные о терапевтической эквивалентности',
]

export const EQUIVALENCE_DATA_PARSE = [
  'наименование исследования, выходные данные\\)(.*?)данные о клинико-экономических \\(фармакоэкономических\\) характеристиках',
]

export const ECONOMY_PARSE = [
  'список литературы — автор, наименование исследования, выходные данные\\) \\(\\*\\*\\*\\)(.*?)данные о стоимости и цене лекарственного препарата',
]

export const SALESVOLUME_PARSE = [
  'по лекарственным формам зарегистрированных лекарственных препаратов(.*?)данные отчётов о результатах мониторинга безопасности',
]

export const SECURITYMONITORING_PARSE = [
  'безопасности лекарственного препарата \\(в Российской Федерации и \\(или\\) за рубежом\\)(.*?)данные предоставленные заявителем в инициативном порядке',
]

export const INITIATIVE_PARSE = [
  'данные предоставленные заявителем в инициативном порядке(.*?)$',
]

export const APPLICANT_PARSE = [
  'имя отчество \\(при наличии\\) гражданина(.*?)ответственное лицо, должность',
]

export const FULLNAME_PARSE = [
  'ответственное лицо, должность(.*?)адрес \\(место нахождения\\) или место жительства',
]

export const ADDRESS_PARSE = [
  'адрес \\(место нахождения\\) или место жительства(.*?)телефон \\(факс\\)',
]

export const PHONE_PARSE = ['телефон \\(факс\\)(.*?)электронная почта']

export const EMAIL_PARSE = ['электронная почта(.*?)$']

export const FIELD_VISIBILITY = {
  gnvlp: true,
  vzn14: true,
  isOrphan: true,
  isOrphanByIndication: true,
  applicant: true,
  fullName: true,
  address: true,
  phone: true,
  email: true,
  nameType: true,
  mnn: true,
  ath: true,
  lForma: true,
  ruNumber: true,
  ruDate: true,
  ruDateConfirm: true,
  ruFiles: true,
  lpCompareDrug: true,
  localizationData: true,
  reproducedLP: true,
  //reproducedLPtext: true,
  //reproducedLPloadedText: true,
  condition: true,
  comment: true,
  epidNecessity: true,
  justification14vzn: true,
  advantages: true,
  region: true,
  list: true,
  localization: true,
  epidData: true,
  clinData: true,
  equivalence: true,
  economy: true,
  DACdata: true,
  salesVolume: true,
  securityMonitoring: true,
  initiative: true,
}

export const MNN_PARSE = {
  [P_MNN1]: {
    name: 'международное непатентованное наименование',
    phrase: [
      'международное непатентованное наименование.*?<em>(.*?)<\\/em>.*?в случае отсутствия международного непатентованного и группировочного наименований',
    ],
  },
  [P_MNN2]: {
    name: 'группировочное наименование',
    phrase: [
      'в случае отсутствия международного непатентованного наименования.*?<em>(.*?)<\\/em>.*?в случае отсутствия международного непатентованного и группировочного наименований',
    ],
  },
  [P_MNN3]: {
    name: 'химическое наименование',
    phrase: [
      'в случае отсутствия международного непатентованного и группировочного наименований.*?<em>(.*?)<\\/em>.*?в случае отсутствия иных наименований',
    ],
  },
  [P_MNN4]: {
    name: 'торговое наименование',
    phrase: [
      'в случае отсутствия иных наименований.*?<em>(.*?)<\\/em>.*?код анатомо-терапевтическо-химической классификации',
    ],
  },
}

export const REPRODUCED_DEFAULT_TEXT =
  'Нет воспроизведенных или биоаналоговых (биоподобных) лекарственных препаратов'

const TEXT_BLOCK1 =
  'включения лекарственного препарата в перечни лекарственных препаратов(.*?)информация о заявителе'
const TEXT_BLOCK2 =
  'Информация о заявителе(.*?)Информация о лекарственном препарате'
const TEXT_BLOCK3 =
  'информация о лекарственном препарате(.*?)сведения и данные о лекарственном препарате'
const TEXT_BLOCK4 =
  'сведения и данные о лекарственном препарате(.*?)Обоснования для включения лекарственного препарата'
const TEXT_BLOCK5 =
  'Обоснования для включения лекарственного препарата(.*?)Общее количество представленных документов'
const TEXT_BLOCK55 =
  'данные о стоимости и цене лекарственного препарата(.*?)данные о фактических объёмах продаж лекарственного препарата'

// Базовый класс ошибок
export class ParseError extends Error {
  constructor(message, error) {
    super(message)
    this.name = 'ParseError'
    this.error = error
    //this.msg = message;
  }
}

/**
 * ЦАРЬ ФУНКЦИЯ
 * @param html
 * @returns {{nameType, ath: (string|*), lForma: (string|*), vzn14: boolean, gnvlp: boolean, mnn}}
 * @constructor
 */
export function MainLpParse(html = null) {
  try {
    const { nameType, mnn } = getParseObject(html, MNN_PARSE, TEXT_BLOCK3)
    const ruFiles = GetWithFiles(html, RU_FILES_PARSE, TEXT_BLOCK3)
    const epidNecessity = GetWithFiles(html, EPID_NECESSITY_PARSE, TEXT_BLOCK4)
    const justification14vzn = GetWithFiles(
      html,
      JUSTIFICATION14VZN_PARSE,
      TEXT_BLOCK4
    )
    const advantages = GetWithFiles(html, ADVANTAGE_PARSE, TEXT_BLOCK4)
    const region = GetWithFiles(html, REGION_PARSE, TEXT_BLOCK4)
    const list = GetWithFiles(html, LIST_PARSE, TEXT_BLOCK4)
    const epidData = GetWithFiles(html, EPID_DATA_PARSE, TEXT_BLOCK5)
    const clinData = GetWithFiles(html, CLIN_DATA_PARSE, TEXT_BLOCK5)
    const economy = GetWithFiles(html, ECONOMY_PARSE, TEXT_BLOCK5)
    const localization = GetWithFiles(html, LOCALIZATION_PARSE, TEXT_BLOCK4)
    const salesVolume = GetWithFiles(html, SALESVOLUME_PARSE, TEXT_BLOCK5)
    const securityMonitoring = GetWithFiles(
      html,
      SECURITYMONITORING_PARSE,
      TEXT_BLOCK5
    )
    const initiative = GetWithFiles(html, INITIATIVE_PARSE, TEXT_BLOCK5)
    const equivalence = GetWithFiles(html, EQUIVALENCE_DATA_PARSE, TEXT_BLOCK5)
    const DACdata = GetWithDACTable(html, TEXT_BLOCK55)

    return {
      vzn14: GetBooleanBy(html, 'дор.*?\\sлек.*?\\sпре.*?', TEXT_BLOCK1),
      gnvlp: GetBooleanBy(html, 'жи.*?\\sне.*?\\sва.*?\\sле.*?', TEXT_BLOCK1),
      ath: getParseArray(html, ATH_PARSE, TEXT_BLOCK3),
      lForma: getParseArray(html, FORMA_PARSE, TEXT_BLOCK3),
      applicant: getParseArray(html, APPLICANT_PARSE, TEXT_BLOCK2),
      fullName: getParseArray(html, FULLNAME_PARSE, TEXT_BLOCK2),
      address: getParseArray(html, ADDRESS_PARSE, TEXT_BLOCK2),
      phone: getParseArray(html, PHONE_PARSE, TEXT_BLOCK2),
      email: getParseArray(html, EMAIL_PARSE, TEXT_BLOCK2),
      nameType: nameType?.trim(),
      mnn: mnn?.trim(),
      ruNumber: getParseArray(html, RU_NUMBER_PARSE, TEXT_BLOCK3),
      ruDate: getParseArray(html, RU_DATE_PARSE, TEXT_BLOCK3),
      ruDateConfirm: getParseArray(html, RU_DATECONFIRM_PARSE, TEXT_BLOCK3),
      ruFiles: ruFiles,
      lpCompareDrug: getParseArray(
        html,
        lpCompareDrug_ADDRESS_PARSE,
        TEXT_BLOCK3
      ),
      localizationData: getParseArray(html, LOC_DATA_PARSE, TEXT_BLOCK3),
      /*reproducedLPloadedText: getParseArray(
        html,
        REPRODUCED_TEXT_PARSE,
        TEXT_BLOCK3
      ),*/
      reproducedLP: true,
      reproducedLPtext: getParseArray(html, REPRODUCED_TEXT_PARSE, TEXT_BLOCK3),
      isOrphan: false,
      isOrphanByIndication: false,
      condition: getParseArray(html, CONDITION_PARSE, TEXT_BLOCK3),
      comment: '',
      epidNecessity: epidNecessity,
      justification14vzn: justification14vzn,
      advantages: advantages,
      region: region,
      list: list,
      localization: localization,
      epidData: epidData,
      clinData: clinData,
      equivalence: equivalence,
      economy: economy,
      DACdata: DACdata,
      salesVolume: salesVolume,
      securityMonitoring: securityMonitoring,
      initiative: initiative,
      pVersion: PARSER_VERSION,
    }
  } catch (error) {
    throw new ParseError(`Ошибка обработки файла: "${error}"`, error)
  }
}

/**
 * ПАРСИНГ ПУНКТА 5.5 из БД_ОТЗ
 * @param inputString
 * @param textBlock
 * @returns {{files: [], tableData: [], text: string}}
 * @constructor
 */
function GetWithDACTable(inputString, textBlock) {
  let block = inputString.matchAll(RegExp(textBlock, 'gi'))
  block = Array.from(block)?.[0]?.[1] || ''

  if (/<table>/.test(block)) {
    let tr = block.matchAll(/<tr>(?<tr>.*?)<\/tr>/gi)
    tr = Array.from(tr)

    const table = []
    for (let i = 1; i < tr.length; i++) {
      let obj = {}
      let td = tr[i].groups.tr.matchAll(/<td>(?<td>.*?)<\/td>/gi)
      td = Array.from(td)
      obj = {
        col1: clearTags(td[0].groups.td),
        col2: clearTags(td[1].groups.td),
        col3: clearTags(td[2].groups.td),
        col4: clearTags(td[3].groups.td),
        col5: clearTags(td[4].groups.td),
      }
      table.push(obj)
    }

    block = block.replace(/<table>.*?<\/table>/gi, '')

    let fn = block.matchAll(GET_CLEAR_FILE_NAME)
    fn = Array.from(fn)

    const data = fn.reduce(
      (files, elem) => [...files, clearTags(elem.groups.file)],
      []
    )

    const text = clearTags(
      block
        .replaceAll(RegExp(GET_CLEAR_FILE_NAME, 'gi'), '\n')
        .replaceAll(BR_COSTUL, '\n')
    )

    return { data, text, table }
  }
  return { data: [], text: '', table: [] }
}

/**
 * РАЗБИРАЕМ ТЕКСТ НА ФАЙЛЫ И ТЕКСТ ОПИСАНИЯ (КОММЕНТАРИИ)
 * @param html
 * @param PHRASE
 * @param BLOCK
 * @returns {{data, text}}
 * @constructor
 */
function GetWithFiles(html, PHRASE, BLOCK) {
  try {
    let text = getParseArray(html, PHRASE, BLOCK, '', false)
    const fn = Array.from(text.matchAll(GET_CLEAR_FILE_NAME))

    const data = fn.reduce((files, elem) => {
      const file = clearTags(elem.groups.file)
      //text = text.replace(elem[0], fn.length > 1 ? `Файл: ${file}\n` : '')
      text = text.replace(elem[0], '')
      return [...files, file]
    }, [])

    //text = clearTags(text.replaceAll(RegExp(GET_CLEAR_FILE_NAME, 'gi'), '=файл=') )
    text = clearTags(text)
    if (text.startsWith('Комментарий: '))
      text = text.replace('Комментарий: ', '')

    return { text, data }
  } catch (error) {
    throw new Error('[GetWithFiles] ' + error)
  }
}

/**
 * ВЫРЕЗАЕМ ВСЕ ТЕГИ,
 * @param inputString
 * @returns {*}
 */
function clearTags(inputString) {
  //чистим теги
  return (
    inputString
      // </p> </li> <br /> замена на перенос строки
      .replace(/<([/](p|li)|br [/])>/gi, '\n')
      // все оставниеся теги
      .replace(/(<([^>]+)>)/gi, '')
      // парные переносы строк
      .replace(/[\n](\s*[\n])+/gi, '\n')
      .trim()
  )
}

/**
 * СМОТРИМ ЕСТЬ ИЛИ НЕТ СООТВЕТСТВИЕ ЗАДАННОМУ ВЫРАЖЕНИЮ В УКАЗАННОМ БЛОКЕ
 * @param inputString
 * @param rule
 * @param textBlock
 * @returns {boolean}
 * @constructor
 */
function GetBooleanBy(inputString, rule, textBlock) {
  if (!inputString || !rule) return false
  let block = inputString.matchAll(RegExp(textBlock, 'gi'))
  block = Array.from(block)
  block = block[0][1].replace(/<s>.*?<\/s>/gi, '')
  const regexp = RegExp(rule, 'gi')

  return regexp.test(block)
}

/**
 * ПАРСИМ ЗНАЧЕНИЯ ЛП ПО ПРАВИЛАМ ИЗ УКАЗАННОГО ОБЪЕКТА В УКАЗАННОМ ТЕКСТОВОМ БЛОКЕ,
 * КОТОРЫЙ ВЫЧЛЕНЯАМ ИЗ ОБЩЕЙ МАССЫ ТАКЖЕ ПО ПРАВИЛУ
 * @param inputString
 * @param object
 * @param textBlock
 * @returns {string|{nameType, mnn}}
 */
function getParseObject(inputString, object, textBlock) {
  if (!inputString || !textBlock || !object) return ''

  let block = inputString.matchAll(RegExp(textBlock, 'gi'))
  block = Array.from(block)?.[0]?.[1] || ''

  for (let key in object) {
    const phrase = object[key].phrase
    let nameType = object[key].name

    for (let item in phrase) {
      const regexp = RegExp(phrase[item], 'gi')
      let p = block.matchAll(regexp)
      p = Array.from(p)?.[0]?.[1]

      if (p) return { nameType: nameType, mnn: p }
    }
  }
  return { nameType: '', mnn: '' }
}

/**
 * ПАРСИМ ЗНАЧЕНИЯ ЛП ПО ПРАВИЛАМ ИЗ УКАЗАННОГО МАССИВА В УКАЗАННОМ ТЕКСТОВОМ БЛОКЕ,
 * КОТОРЫЙ ВЫЧЛЕНЯАМ ИЗ ОБЩЕЙ МАССЫ ТАКЖЕ ПО ПРАВИЛУ
 * @param inputString
 * @param rules
 * @param defaultValue
 * @param textBlock
 * @param cleanTag
 * @returns {string|*}
 */
function getParseArray(
  inputString,
  rules,
  textBlock,
  defaultValue = '',
  cleanTag = true
) {
  if (!inputString || !rules || !textBlock) return ''

  let block = inputString.matchAll(RegExp(textBlock, 'gi'))
  block = Array.from(block)?.[0]?.[1] || ''

  for (let key in rules) {
    const regexp = RegExp(rules[key], 'gi')
    let result = block.matchAll(regexp)
    result = Array.from(result)?.[0]?.[1]

    if (result) {
      result = result.replaceAll(BR_COSTUL, '\n')
      return cleanTag ? clearTags(result) : result
    }
  }
  return defaultValue
}
