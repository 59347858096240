<template>
  <v-container fluid>
    <v-card shaped outlined class="mt-2">
      <v-card-text>
        <div class="sticky">
          <CompareRow
            hideUseBtn
            editable
            v-slot="{ value, position, dataResult, isResult }"
            :dense="titleGone"
          >
            <AnalogSelect
              v-model="selectedData[position]"
              :items="getItems(value, dataResult)"
              :hide-exists="isResult"
              :clearable="!isResult"
              @change="analogSelectChange(position)"
              :hide-use-count="titleGone"
              :disabled="titleGone"
            />
          </CompareRow>
          <CompareRow
            v-if="!titleGone"
            hideUseBtn
            v-slot="{
              value,
              isResult,
              changeResult,
              position,
              dataResult,
              change,
            }"
            dense
          >
            <div class="align-self-center" v-if="dataReady">
              <btn-with-confirm
                v-if="isResult"
                v-show="showRemoveBtn(value, position)"
                icon-main="mdi-minus"
                icon-small
                x-small
                left
                text
                color="error"
                class="fit-content"
                @click="toggleAnalItemAL(value, change)"
              >
                не использовать как аналог
              </btn-with-confirm>
              <btn-with-confirm
                v-if="isResult"
                v-show="showAddBtn(value, position)"
                icon-main="mdi-plus"
                icon-small
                x-small
                left
                text
                color="success"
                @click="toggleAnalItemAL(value, change)"
              >
                использовать как аналог
              </btn-with-confirm>
              <v-btn
                v-if="!isResult"
                x-small
                text
                class="fit-content"
                color="primary"
                title="добавить блок полностью"
                @click="addItemAL(value, changeResult, position, dataResult)"
                :disabled="
                  selectedData[position] === 0 ||
                  !canAddItemAl(value, position, dataResult)
                "
              >
                <v-icon right dark class="mx-2" v-if="position === 2"
                  >mdi-arrow-left-thin</v-icon
                >
                добавить аналог
                <v-icon right dark class="mx-2" v-if="position === 1"
                  >mdi-arrow-right-thin</v-icon
                >
              </v-btn>
            </div>
          </CompareRow>
        </div>
        <IntersectionDiv @gone="onTitleGone" rm="90" dont-use-store />

        <v-card shaped outlined class="mt-2">
          <v-card-title class="pb-0 text-button">Паспорт аналога</v-card-title>
          <v-card-text>
            <!-- паспорт аналога -->
            <v-divider />
            <CompareRow
              root="AL"
              :root-index="selectedData"
              tag="ath"
              v-slot="{ value, change }"
            >
              <AtcTextEditor
                :value="value"
                label="АТХ"
                @change="change"
                @input="change"
                :maxlength="maxLengthAth"
              />
            </CompareRow>
            <CompareRow
              root="AL"
              :root-index="selectedData"
              tag="lForma"
              v-slot="{ value, change }"
            >
              <AutoGrowTextEditor
                :value="value"
                label="Лекарственная форма"
                :rows="2"
                @change="change"
                @input="change"
              />
            </CompareRow>
            <CompareRow
              root="AL"
              :root-index="selectedData"
              tag="condition"
              v-slot="{ value, change }"
            >
              <AutoGrowTextEditor
                :value="value"
                label="Показание"
                :rows="5"
                @change="change"
                @input="change"
              />
            </CompareRow>
          </v-card-text>
        </v-card>

        <!-- Встречается в КР -->
        <v-card outlined class="mt-3">
          <v-card-title class="pb-0 text-button"
            >Клинические рекомендации</v-card-title
          >
          <v-card-text>
            <CompareAnalogCr :selectedAnalog="selectedData" />
          </v-card-text>
        </v-card>

        <!-- Данные об аналогах заполняются и проверяются на последнем этапе повторно -->
        <v-card outlined class="mt-3">
          <v-card-title class="pb-0 text-button"
            >Данные об аналогах</v-card-title
          >
          <v-card-text>
            <ReferenceTip
              label='Экспертиза "Данные об аналогах" проводится на последнем этапе!'
            />
            <CompareRow
              root="AL"
              :root-index="selectedData"
              :root-sub="`AD`"
              tag="0"
              v-slot="{ value, change }"
            >
              <v-switch
                class="mt-0"
                :input-value="value.manual"
                label="Ручная корректировка данных"
                title="Выполнить ручную корректировку данных (отменить ручную корректирвку можно только выполнив замену данных из справочника ЛП)"
                color="secondary"
                disabled
                @change="manual => change({ ...value, manual })"
                :messages="
                  value.manual
                    ? 'Внимание! Данные были откорректированы вручную.'
                    : ''
                "
              />
            </CompareRow>
            <CompareRow
              root="AL"
              :root-index="selectedData"
              :root-sub="`AD[0]`"
              tag="ru"
              v-slot="{ value, change }"
              :changeUpdate="changeUpdate"
            >
              <v-text-field
                :value="value"
                label="РУ (номер РУ или кол-во РУ)"
                @change="change"
                @input="change"
              />
            </CompareRow>
            <CompareRow
              root="AL"
              :root-index="selectedData"
              :root-sub="`AD[0]`"
              tag="ruEndDate"
              v-slot="{ value, change }"
              :changeUpdate="changeUpdate"
            >
              <v-text-field
                :value="value"
                label="Дата окончания РУ"
                @change="change"
                @input="change"
              />
            </CompareRow>
            <CompareRow
              root="AL"
              :root-index="selectedData"
              :root-sub="`AD[0]`"
              tag="obr"
              v-slot="{ value, change }"
              :changeUpdate="changeUpdate"
            >
              <AutoGrowTextEditor
                :value="value"
                label="Обращение (выпуск в гражданский оборот)"
                @change="change"
                @input="change"
                rows="1"
              />
            </CompareRow>
            <CompareRow
              root="AL"
              :root-index="selectedData"
              :root-sub="`AD[0]`"
              tag="govPack"
              v-slot="{ value, change }"
              :changeUpdate="changeUpdate"
            >
              <v-text-field
                :value="value"
                label="Гос. закупки: упаковки"
                @change="change"
                @input="change"
              />
            </CompareRow>
            <CompareRow
              root="AL"
              :root-index="selectedData"
              :root-sub="`AD[0]`"
              tag="govPrice"
              v-slot="{ value, change }"
              :changeUpdate="changeUpdate"
            >
              <v-text-field
                :value="value"
                label="Гос. закупки: стоимость"
                @change="change"
                @input="change"
              />
            </CompareRow>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import CompareRow from '@/components/compare/CompareRow'
import { copyObject } from '@/lib/objects'
import {
  DEFAULT_CR_ANALOG_AL,
  ATH_NAME_LENGTH,
  ANALOG_SAME_FUNC,
  ANALOG_CR_SAME_FUNC,
} from './const'
import CompareAnalogCr from './CompareAnalogCr'
import AtcTextEditor from '@/components/editors/AtcTextEditor'
import ReferenceTip from '@/components/ReferenceTip'
import btnWithConfirm from '@/components/buttons/btnWithConfirm'
import AutoGrowTextEditor from '@/components/editors/AutoGrowTextEditor'
import IntersectionDiv from '@/components/overlay/IntersectionDiv'
import AnalogSelect from './analogSelect.vue'
import { mapGetters } from 'vuex'

export default {
  props: {
    dataReady: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AtcTextEditor,
    AutoGrowTextEditor,
    CompareRow,
    CompareAnalogCr,
    btnWithConfirm,
    AnalogSelect,
    ReferenceTip,
    IntersectionDiv,
  },
  data: () => ({
    selectedData: [0, 0, 0],
    renderComponent: true,
    maxLengthAth: ATH_NAME_LENGTH,
    titleGone: false,
  }),
  computed: {
    ...mapGetters([
      'compare_expertise_0_Data',
      'compare_expertise_1_Data',
      'compare_data',
    ]),
  },
  methods: {
    changeUpdate: () => ({ tag: 'manual', value: true }),
    onTitleGone(isGone) {
      this.titleGone = isGone
    },
    findAnalogIn(analog, AL) {
      return !!analog && !AL?.find(el => ANALOG_SAME_FUNC(el, analog))
    },
    canAddItemAl(donor, position, dataResult) {
      return this.findAnalogIn(
        donor?.AL[this.selectedData[position]],
        dataResult.AL
      )
    },
    addItemAL(donor, changeResult, position, dataResult) {
      const index = this.selectedData[position]
      const newData = copyObject(dataResult)
      const dplp = donor.AL[index]
      // ищем похожий аналог
      let index0 = newData.AL.findIndex(el => ANALOG_SAME_FUNC(el, dplp))
      if (index0 < 0) {
        index0 = newData.AL.push(copyObject(dplp)) - 1
        newData.AL[index0].CR = newData.CR.map(() => DEFAULT_CR_ANALOG_AL())
      } else {
        newData.AL[index0].anal = dplp.anal
      }
      const CR = []
      // собираем все КР которые используются
      donor.AL[index].CR.forEach((item, i) => {
        if (item.use) {
          CR.push({ cr: donor.CR[i], al: item })
        }
      })
      // проходим все КР и смотрим если такой КР нет, добавляем в общий список и для всех аналогов, иначе просто используем
      CR.forEach(({ cr, al }) => {
        const findIndex = newData.CR.findIndex(el =>
          ANALOG_CR_SAME_FUNC(el, cr)
        )

        if (findIndex > -1) {
          newData.AL[index0].CR[findIndex] = copyObject(al)
        } else {
          const f = newData.CR.push(copyObject(cr)) - 1
          newData.AL.forEach(item => {
            item.CR.push(DEFAULT_CR_ANALOG_AL())
          })
          newData.AL[index0].CR[f] = copyObject(al)
        }
      })

      changeResult(newData)
      // переключились на новый аналог
      this.$set(this.selectedData, 0, index0)
    },

    toggleAnalItemAL(value, change) {
      const index = this.selectedData[0]
      const newData = copyObject(value)

      newData.AL[index].anal = !newData.AL[index].anal
      change(newData)
    },

    getItems(value, result) {
      if (!value?.AL?.length) return []
      return value?.AL.map(({ mnn, lForma, anal, ath, CR }, index) => {
        const item = {
          index,
          mnn,
          lForma,
          anal,
          ath,
        }
        const exist = this.findAnalogIn(item, result?.AL)
        // кол-во упоминаний в КР
        const count = CR?.reduce((cnt, { use }) => cnt + (use ? 1 : 0), 0) ?? 0
        return { ...item, exist, count }
      }).sort((a, b) => {
        if (a.index === 0) return -1
        if (b.index === 0) return 1
        return a.mnn?.localeCompare(b.mnn) || a.lForma?.localeCompare(b.lForma)
      })
    },
    showRemoveBtn(v, position) {
      const selectedItem = this.selectedData[position] ?? 0

      if (selectedItem === 0 || !v.AL[selectedItem].anal) {
        return false
      } else {
        return true
      }
    },
    showAddBtn(v, position) {
      const selectedItem = this.selectedData[position] ?? 0

      if (selectedItem !== 0 && !v.AL[selectedItem].anal) {
        return true
      }
    },
    analogSelectChange(position) {
      // прошёл выбор аналога
      if (position) {
        const another = position == 1 ? 2 : 1
        const index = this.selectedData[position] ?? -1
        const me = this[`compare_expertise_${position - 1}_Data`]?.AL?.[index]
        const AL = this[`compare_expertise_${another - 1}_Data`]?.AL

        let newIndex = null
        if (me && AL) {
          // пробуем найти соответствие для крайних
          newIndex = AL?.findIndex(el => ANALOG_SAME_FUNC(el, me))
          if (newIndex < 0) newIndex = null
        }
        // ставим найденный аналог по выбранному с другой стороны
        this.$set(this.selectedData, another, newIndex)
      }
    },
  },
}
</script>

<style scoped>
.fit-content {
  max-width: fit-content;
}
.sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 110px;
  z-index: 2;
  background-color: white;
  border-bottom: 1px solid #424242;
}
</style>
