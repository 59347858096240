var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-select',_vm._g(_vm._b({attrs:{"items":_vm.items,"item-text":"mnn","item-value":"index","persistent-hint":"","hint":_vm.itemHint},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('AnalogIcon',{attrs:{"value":item.anal,"index":item.index,"left":"","disabled":!_vm.hideExists && !item.exist && !!item.index}}),_vm._v(" "+_vm._s(item.mnn)+" ")],1)]}},{key:"item",fn:function(ref){
var _obj;

var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;return [_c('v-list-item',_vm._g(_vm._b({attrs:{"dense":""}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('AnalogIcon',{attrs:{"value":item.anal,"index":item.index,"disabled":!_vm.hideExists && !item.exist && !!item.index}})],1),_c('v-list-item-content',{class:( _obj = {}, _obj["text--disabled"] = !item.anal || (!_vm.hideExists && !item.exist), _obj )},[_c('v-list-item-title',[_vm._v(_vm._s(item.ath)+" : "+_vm._s(item.mnn)+" "),(item.count)?_c('span',{staticClass:"ml-2 text--secondary",attrs:{"title":("Упоминается в " + (item.count) + " КР")}},[_vm._v("( "+_vm._s(item.count)+" )")]):_vm._e()]),_c('v-list-item-subtitle',{staticClass:"text-truncate",attrs:{"title":item.lForma}},[_vm._v(_vm._s(item.lForma))])],1)],1)]}},(_vm.length > 1 && !_vm.hideUseCount)?{key:"append-outer",fn:function(){return [_c('v-chip',{staticClass:"my-0",attrs:{"small":""}},[_vm._v(_vm._s(_vm.index + 1)+" из "+_vm._s(_vm.length))])]},proxy:true}:null],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},'v-select',_vm.$attrs,false),_vm.$listeners)),(!_vm.hideUseCount && _vm.itemCount)?_c('span',{staticClass:"mt-2 text-caption"},[_vm._v(" * Встречается в "+_vm._s(_vm.itemCount)+" КР")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }