<template>
  <v-container fluid>
    <!-- выбор экспертиз КЕИ / АВБ -->
    <v-card shaped outlined class="mt-2">
      <v-card-title class="pb-0 text-button">Выбор экспертизы</v-card-title>
      <v-card-text>
        <CompareRow root="ka" hideUseBtn editable v-slot="{ value, position }">
          <v-select
            v-model="selectedData[position]"
            class="font-weight-bold"
            :items="getItems(value)"
            item-text="name"
            @change="changePosition(position)"
            item-value="index"
            hide-details
            ><template #append-outer v-if="getItems(value).length > 1"
              ><v-chip small class="my-0"
                >{{ selectedData[position] + 1 }} из
                {{ getItems(value).length }}</v-chip
              >
            </template>
          </v-select>
        </CompareRow>
        <!-- управление всем блоком выбранной экспертизы -->
        <CompareRow
          root="ka"
          :root-index="selectedData"
          append
          @append="updateAppend"
          remove
          @remove="removeItem"
          v-slot="{ value }"
          ><v-alert
            v-if="!value"
            type="warning"
            border="top"
            colored-border
            elevation="1"
            >Нет данных</v-alert
          ></CompareRow
        >

        <!-- Паспорт экспертизы -->
        <v-card shaped outlined>
          <v-card-text>
            <CompareRow
              root="ka"
              :root-index="selectedData"
              tag="shortName"
              v-slot="{ value, change }"
            >
              <v-text-field
                label="Короткое название"
                :value="value"
                @change="change"
              />
            </CompareRow>
            <!--  мкб 10 без расшифровки-->
            <CompareRow
              root="ka"
              :root-index="selectedData"
              tag="mkb10_codes"
              v-slot="{ value, change, isResult }"
              @set-value="(i, data) => copyMkbCodes(i, data)"
            >
              <v-text-field
                label="МКБ-10 (коды)"
                readonly
                @change="change"
                :value="value"
              >
                <template #append-outer v-if="isResult">
                  <v-btn @click="showMkbTree" icon>
                    <v-icon> mdi-store-search-outline </v-icon>
                  </v-btn>
                </template>
              </v-text-field>
              <!-- Диалог выбора кодов МКБ сюда, что бы видеть данные слота -->
              <MkbTreeSelectDialog
                v-if="isResult && mkbTree"
                :codes-string="value"
                @close-mkb-tree="closeMkbTree"
                @confirm-selection="
                  (codes, codesWithNames) =>
                    confirmMkbCodes(codes, codesWithNames, change)
                "
              />
            </CompareRow>
            <!-- mkb10 с расшифровкой -->
            <CompareRow
              root="ka"
              :root-index="selectedData"
              tag="mkb10"
              hide-use
              v-slot="{ value, change }"
            >
              <AutoGrowTextEditor
                label="МКБ-10"
                readonly
                @change="change"
                @input="change"
                :value="value"
              />
            </CompareRow>
            <!-- Показание -->
            <CompareRow
              root="ka"
              :root-index="selectedData"
              tag="condition"
              v-slot="{ value, change }"
            >
              <AutoGrowTextEditor
                label="Показание"
                @change="change"
                @input="change"
                :value="value"
              />
            </CompareRow>

            <CompareRow
              root="ka"
              :root-index="selectedData"
              tag="qLevels"
              hide-use-btn
              v-slot="{ data, change }"
            >
              <span class="text-body-1" v-if="data">
                Методологическое качество:
                <strong>{{
                  calcQLevels(data, change) | capitalize
                }}</strong></span
              >
            </CompareRow>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
    <!-- Экспертиза: КЕИ/АВБ - критерии -->
    <v-row>
      <v-col cols="12">
        <v-tabs v-model="activeTab" show-arrows center-active class="mt-2">
          <v-tab
            v-for="(tabName, tabIndex) in criteriaList"
            :key="tabIndex"
            :title="tabName.name"
            ><span :class="someClass(tabIndex)"
              >{{ tabIndex + 1 }}.
              {{ tabName.name.split(' ')[0] || '' }}...</span
            ></v-tab
          >

          <v-tab-item
            v-for="(tabName, tabIndex) in criteriaList"
            :key="tabIndex"
          >
            <v-card outlined class="mt-2">
              <v-card-title class="pb-0 text--secondary"
                >{{ tabIndex + 1 }}. {{ tabName.name }}</v-card-title
              >
              <v-card-text>
                <div class="mb-0 mt-2 text-center text-button">
                  Методологическое качество
                  <v-btn
                    title="знач. Экспертизы КЭИ"
                    @click="showMethod()"
                    icon
                    small
                    ><v-icon
                      v-text="expand ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                    ></v-icon
                  ></v-btn>
                </div>
                <v-expand-transition>
                  <div v-if="expand" class="justify-center d-flex flex-wrap">
                    <v-card outlined>
                      <v-card-text>
                        <ReferenceTip
                          >Оценка метод. кач. основной экспертизы</ReferenceTip
                        >
                        <v-radio-group
                          hide-details
                          class="mt-0 pa-0"
                          row
                          dense
                          readonly
                          :value="
                            initMain[position].elements[tabIndex].correspond
                          "
                        >
                          <v-radio
                            color="green"
                            :value="true"
                            :label="EX_CORRESPOND"
                          />
                          <v-radio
                            color="red"
                            :value="false"
                            :label="EX_NOT_CORRESPOND"
                          />
                        </v-radio-group>
                      </v-card-text>
                    </v-card>
                  </div>
                </v-expand-transition>

                <CompareRow
                  root="ka"
                  :root-index="selectedData"
                  :root-sub="`elements[${tabIndex}]`"
                  tag="correspond"
                  v-slot="{ value, change }"
                >
                  <v-card elevation="0">
                    <v-card-text>
                      <v-radio-group
                        hide-details
                        class="mt-0 mb-4 pa-0"
                        row
                        dense
                        :value="value"
                        @change="change"
                      >
                        <v-radio
                          color="green"
                          :value="true"
                          :label="EX_CORRESPOND"
                        />
                        <v-radio
                          color="red"
                          :value="false"
                          :label="EX_NOT_CORRESPOND"
                        />
                      </v-radio-group>
                    </v-card-text>
                  </v-card>
                </CompareRow>

                <v-divider />
                <div class="mb-0 mt-2 text-center text-button">
                  Обоснование
                  <v-btn
                    title="знач. Экспертизы КЭИ"
                    @click="showCriteria()"
                    icon
                    small
                    ><v-icon
                      v-text="
                        expandCriteria ? 'mdi-chevron-up' : 'mdi-chevron-down'
                      "
                    ></v-icon
                  ></v-btn>
                </div>
                <v-expand-transition>
                  <div v-if="expandCriteria" class="justify-center">
                    <v-card outlined>
                      <v-card-text>
                        <ReferenceTip
                          >Обоснование основной экспертизы</ReferenceTip
                        >
                        <AutoGrowTextEditor
                          full-width
                          readonly
                          :value="
                            initMain[position].elements[tabIndex].criteria
                          "
                          rows="5"
                        />
                      </v-card-text>
                    </v-card>
                  </div>
                </v-expand-transition>

                <CompareRow
                  root="ka"
                  :root-index="selectedData"
                  :root-sub="`elements[${tabIndex}]`"
                  tag="criteria"
                  v-slot="{ value, change, data }"
                >
                  <AutoGrowTextEditor
                    v-if="data"
                    @change="change"
                    @input="change"
                    :value="value"
                    rows="10"
                  />
                </CompareRow>

                <v-divider />
                <div class="mb-0 mt-2 text-center text-button">
                  Комментарий
                  <v-btn
                    title="знач. Экспертизы КЭИ"
                    @click="showComment()"
                    icon
                    small
                    ><v-icon
                      v-text="
                        expandComment ? 'mdi-chevron-up' : 'mdi-chevron-down'
                      "
                    ></v-icon
                  ></v-btn>
                </div>
                <v-expand-transition>
                  <div v-if="expandComment" class="justify-center">
                    <v-card outlined>
                      <v-card-text>
                        <ReferenceTip
                          >Комментарий основной экспертизы</ReferenceTip
                        >
                        <AutoGrowTextEditor
                          full-width
                          readonly
                          :value="initMain[position].elements[tabIndex].comment"
                          rows="5"
                        />
                      </v-card-text>
                    </v-card>
                  </div>
                </v-expand-transition>

                <CompareRow
                  root="ka"
                  :root-index="selectedData"
                  :root-sub="`elements[${tabIndex}]`"
                  tag="comment"
                  v-slot="{ value, change, data }"
                >
                  <AutoGrowTextEditor
                    v-if="data"
                    @change="change"
                    @input="change"
                    :value="value"
                    rows="2"
                  />
                </CompareRow>

                <v-divider />
                <div class="mb-0 mt-2 text-center text-button">Тэги</div>

                <CompareRow
                  hide-use-btn
                  root="ka"
                  :root-index="selectedData"
                  :root-sub="`elements[${tabIndex}]`"
                  tag="tagsSelected"
                  v-slot="{ value, change, isResult }"
                  class="mt-4"
                >
                  <CriterionTags
                    v-if="renderComponent && value"
                    :tags="getTags(tabIndex)"
                    :value="value"
                    :clearable="isResult"
                    hide-title
                    flat
                    outlined
                    @change="change"
                  />
                </CompareRow>

                <CompareRow
                  root="ka"
                  :root-index="selectedData"
                  :root-sub="`elements[${tabIndex}]`"
                  tag="tagsSelected"
                  @change="changeIt()"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>

    <!-- Тут все поля для представления о ЛП (resume) -->
    <v-card outlined shaped class="mt-2">
      <v-card-title>
        Информация для сводного заключения и представления об ЛП
      </v-card-title>
      <v-card-text>
        <CompareRow
          root="ka"
          :root-index="selectedData"
          root-sub="resume"
          tag="scenario"
          hide-use
          v-slot="{ value, data }"
        >
          <v-combobox
            v-if="data"
            label="Кол-во сценариев"
            :value="value"
            class="mt-3 font-weight-bold"
            :items="getResumeScenarioArr"
            @change="v => doChangeScenario(v, data)"
          />
        </CompareRow>
        <!-- Экспертиза Автор, год -->
        <v-card shaped outlined class="mt-2">
          <v-card-title class="text-subtitle-2">Автор, год</v-card-title>
          <v-card-text class="pb-0">
            <CompareRow
              root="ka"
              :root-index="selectedData"
              root-sub="resume"
              tag="author"
              v-slot="{ value, change }"
            >
              <v-text-field
                :value="value"
                placeholder="ФИО автора, год"
                @change="change"
                @input="change"
              />
            </CompareRow>
          </v-card-text>
        </v-card>

        <!-- Экспертиза Эпидемиология только для АВБ -->
        <v-card v-if="!kei" outlined class="mt-2">
          <v-card-title class="text-subtitle-2"
            >Численность целевой популяции</v-card-title
          >
          <v-card-text class="pb-0">
            <CompareRow
              v-for="idx in getScenarioMaxArray"
              :key="idx"
              root="ka"
              :root-index="selectedData"
              root-sub="resume.epidemiology"
              :tag="String(idx)"
              :hide-use="idx >= getScenarioResult"
              v-slot="{ value, change, data }"
            >
              <AutoGrowTextEditor
                v-if="data"
                :label="getScenarioMaxCount > 1 ? `Сценарий ${idx + 1}` : ''"
                @change="change"
                @input="change"
                :value="value"
              />
            </CompareRow>
          </v-card-text>
        </v-card>

        <!-- Экспертиза ГАЛОК только дял КЕИ -->
        <v-card v-if="kei" outlined class="mt-2">
          <v-card-title class="text-subtitle-2"
            >Использованный метод</v-card-title
          >
          <v-card-text class="pb-0">
            <CompareRow
              root="ka"
              :root-index="selectedData"
              root-sub="resume"
              tag="method"
              v-slot="{ value, change }"
            >
              <KeiMethodEditor
                @change="change"
                @input="change"
                :value="value"
                readonly
                class="mb-2"
              />
            </CompareRow>
          </v-card-text>
        </v-card>

        <!--Текущая практика, только для АВБ -->
        <v-card v-if="!kei" outlined class="mt-2">
          <v-card-title class="text-subtitle-2">Текущая практика</v-card-title>
          <v-card-text class="pb-0">
            <CompareRow
              v-for="idx in getScenarioMaxArray"
              :key="idx"
              root="ka"
              :root-index="selectedData"
              root-sub="resume.currentState"
              :tag="String(idx)"
              :hide-use="idx >= getScenarioResult"
              v-slot="{ value, change, data }"
            >
              <AutoGrowTextEditor
                v-if="data"
                :label="getScenarioMaxCount > 1 ? `Сценарий ${idx + 1}` : ''"
                @change="change"
                @input="change"
                :value="value"
              />
            </CompareRow>
          </v-card-text>
        </v-card>

        <!--Ожидаемая практика, только для АВБ -->
        <v-card v-if="!kei" outlined class="mt-2">
          <v-card-title class="text-subtitle-2"
            >Ожидаемая практика</v-card-title
          >
          <v-card-text class="pb-0">
            <CompareRow
              v-for="idx in getScenarioMaxArray"
              :key="idx"
              root="ka"
              :root-index="selectedData"
              root-sub="resume.expectedState"
              :tag="String(idx)"
              :hide-use="idx >= getScenarioResult"
              v-slot="{ value, change, data }"
            >
              <AutoGrowTextEditor
                v-if="data"
                :label="getScenarioMaxCount > 1 ? `Сценарий ${idx + 1}` : ''"
                @change="change"
                @input="change"
                :value="value"
              />
            </CompareRow>
          </v-card-text>
        </v-card>

        <!--Препарат сравнения, только для КЕИ -->
        <v-card v-if="kei" outlined class="mt-2">
          <v-card-title class="text-subtitle-2"
            >Препарат сравнения</v-card-title
          >
          <v-card-text class="pb-0">
            <CompareRow
              v-for="idx in getScenarioMaxArray"
              :key="idx"
              root="ka"
              :root-index="selectedData"
              root-sub="resume.analog"
              :tag="String(idx)"
              :hide-use="idx >= getScenarioResult"
              v-slot="{ value, change, data }"
            >
              <AutoGrowTextEditor
                v-if="data"
                :label="getScenarioMaxCount > 1 ? `Сценарий ${idx + 1}` : ''"
                rows="1"
                @change="change"
                @input="change"
                :value="value"
              />
            </CompareRow>
          </v-card-text>
        </v-card>

        <!--Клинические преимущества, только для КЕИ -->
        <v-card v-if="kei" outlined class="mt-2">
          <v-card-title class="text-subtitle-2">
            Клинические преимущества
          </v-card-title>
          <v-card-text class="pb-0">
            <CompareRow
              v-for="idx in getScenarioMaxArray"
              :key="idx"
              root="ka"
              :root-index="selectedData"
              root-sub="resume.clinicalAdvantages"
              :tag="String(idx)"
              :hide-use="idx >= getScenarioResult"
              v-slot="{ value, change, data }"
            >
              <AutoGrowTextEditor
                v-if="data"
                :label="getScenarioMaxCount > 1 ? `Сценарий ${idx + 1}` : ''"
                @change="change"
                @input="change"
                :value="value"
              />
            </CompareRow>
          </v-card-text>
        </v-card>

        <!--Экономические преимущества, только для КЕИ -->
        <v-card v-if="kei" outlined class="mt-2">
          <v-card-title class="text-subtitle-2"
            >Экономические преимущества</v-card-title
          >
          <v-card-text class="pb-0">
            <CompareRow
              v-for="idx in getScenarioMaxArray"
              :key="idx"
              root="ka"
              :root-index="selectedData"
              root-sub="resume.econAdvantages"
              :tag="String(idx)"
              :hide-use="idx >= getScenarioResult"
              v-slot="{ value, change, data }"
            >
              <AutoGrowTextEditor
                v-if="data"
                :label="getScenarioMaxCount > 1 ? `Сценарий ${idx + 1}` : ''"
                @change="change"
                @input="change"
                :value="value"
              />
            </CompareRow>
          </v-card-text>
        </v-card>

        <!--Основные результаты, для КЕИ и для АВБ-->
        <v-card outlined class="mt-2">
          <v-card-title class="text-subtitle-2"
            >Основные результаты</v-card-title
          >
          <v-card-text class="pb-0">
            <CompareRow
              v-for="idx in getScenarioMaxArray"
              :key="idx"
              root="ka"
              :root-index="selectedData"
              root-sub="resume.results"
              :tag="String(idx)"
              :hide-use="idx >= getScenarioResult"
              v-slot="{ value, change, data }"
            >
              <AutoGrowTextEditor
                v-if="data"
                :label="getScenarioMaxCount > 1 ? `Сценарий ${idx + 1}` : ''"
                @change="change"
                @input="change"
                :value="value"
              />
            </CompareRow>
          </v-card-text>
        </v-card>

        <!--Комментарий (сводное заключение), для КЕИ и для АВБ-->
        <v-card outlined class="mt-2">
          <v-card-title class="text-subtitle-2"
            >Комментарий (сводное заключение)</v-card-title
          >
          <v-card-text class="pb-0">
            <CompareRow
              root="ka"
              :root-index="selectedData"
              root-sub="resume"
              tag="comment"
              v-slot="{ value, change }"
            >
              <AutoGrowTextEditor
                @change="change"
                @input="change"
                :value="value"
              />
            </CompareRow>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>

    <!-- Комментарий, для АВБ и для КЕИ -->
    <v-card outlined class="mt-2">
      <v-card-title>Заметки</v-card-title>
      <v-card-text class="pb-0">
        <CompareRow
          root="ka"
          :root-index="selectedData"
          tag="note"
          v-slot="{ value, change, data }"
        >
          <AutoGrowTextEditor
            v-if="data"
            @change="change"
            @input="change"
            :value="value"
          />
        </CompareRow>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { copyObject } from '@/lib/objects'
import CompareRow from '@/components/compare/CompareRow'
import AutoGrowTextEditor from '@/components/editors/AutoGrowTextEditor'
import CriterionTags from './tags/CriterionTags'
import {
  calcQLevel,
  doChangeAvbResumeScenario,
  doChangeKeiResumeScenario,
  EX_AVB_RESUME_SCENARIO_ARR,
  EX_CORRESPOND,
  EX_KEI_RESUME_SCENARIO_ARR,
  EX_NOT_CORRESPOND,
} from './const'
import KeiMethodEditor from '@/components/expModAvbKei/KeiMethodEditor'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import MkbTreeSelectDialog from '@/components/dict/indications/allForms/MkbTreeSelectDialog'
import { SUB_EX_AVB, SUB_EX_KEI } from '@/store/const/expertise'
import ReferenceTip from '@/components/ReferenceTip'

export default {
  components: {
    KeiMethodEditor,
    CompareRow,
    AutoGrowTextEditor,
    MkbTreeSelectDialog,
    CriterionTags,
    ReferenceTip,
  },
  props: {
    dataReady: {
      type: Boolean,
      default: false,
    },
    kei: {
      type: Boolean,
      default: true,
    },
    initMainExp: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    loading: false,
    error: '',
    initData: null,
    position: 0,
    selectedData: [0, 0, 0],
    activeTab: 0,
    criteriaList: null,
    renderComponent: true,
    mkbTree: false,
    expand: false,
    expandCriteria: false,
    expandComment: false,
  }),
  computed: {
    ...mapGetters([
      'compare_data',
      'compare_task_0',
      'compare_task_1',
      'compare_data_ready',
      'compare_expertise_0_Data',
      'compare_expertise_1_Data',
    ]),
    ...mapGetters(['storedKeiCriteria', 'storedAvbCriteria']),
    EX_CORRESPOND: () => EX_CORRESPOND,
    EX_NOT_CORRESPOND: () => EX_NOT_CORRESPOND,
    getExpType() {
      return this.$route.meta.ex_type_sub
    },
    getScenarioResult() {
      return (
        this.compare_data?.ka?.[this.selectedData[0]]?.resume?.scenario ?? 0
      )
    },
    getScenarioMaxCount() {
      return this.getScenarioMaxArray.length || 0
    },
    getScenarioMaxArray() {
      // массив индексов сценариев - берём по среднему или 1
      return Array.apply(
        null,
        Array(
          Math.max(
            this.compare_data?.ka?.[this.selectedData[0]]?.resume?.scenario ||
              1,
            this.compare_expertise_0_Data?.ka?.[this.selectedData[1]]?.resume
              ?.scenario || 1,
            this.compare_expertise_1_Data?.ka?.[this.selectedData[2]]?.resume
              ?.scenario || 1
          )
        )
      ).map((_, i) => i)
    },
    getResumeScenarioArr() {
      return this.kei
        ? EX_KEI_RESUME_SCENARIO_ARR()
        : EX_AVB_RESUME_SCENARIO_ARR()
    },
    storedCriteria() {
      return this.kei ? this.storedKeiCriteria : this.storedAvbCriteria
    },
    initExpCriteria() {
      return this.kei ? SUB_EX_KEI : SUB_EX_AVB
    },
    expElements() {
      return this.kei ? this.initData?.kei?.ka : this.initData?.avb?.ka
    },
    initMain() {
      return this.kei ? this.initMainExp?.kei?.ka : this.initMainExp?.avb?.ka
    },
  },
  created() {
    this.kei ? this.LOAD_KEI_CRITERIA() : this.LOAD_AVB_CRITERIA()
  },
  async mounted() {
    this.loading = true
    try {
      const { expertise } = await this.GET_PLP_REPORT([this.initExpCriteria])
      this.initData = expertise
    } catch (err) {
      this.error = `${err.message} ${err.error}`.trimEnd()
      throw err
    } finally {
      this.loading = false
    }
  },
  methods: {
    ...mapMutations(['COMPARE_PART_DATA']),
    ...mapActions(['LOAD_KEI_CRITERIA', 'LOAD_AVB_CRITERIA', 'GET_PLP_REPORT']),
    someClass(tabIndex) {
      const ready = this.compare_data_ready
      const { correspond } = !ready
        ? this.compare_expertise_0_Data?.ka[this.selectedData[1]]?.elements[
            tabIndex
          ] || {}
        : this.compare_data?.ka[this.selectedData[0]]?.elements[tabIndex] || {}
      if (correspond === false) return 'error--text'
      if (correspond === true) return 'success--text'
      return ''
    },
    updateAppend(object, index) {
      this.selectedData[0] = index
    },
    removeItem(object, index, count) {
      if (count === index) this.selectedData[0] = count - 1
    },
    forceRender() {
      this.renderComponent = false
      this.$nextTick(() => {
        this.renderComponent = true
      })
    },
    changeIt() {
      this.forceRender()
    },
    showMethod() {
      this.expand = !this.expand
    },
    showCriteria() {
      this.expandCriteria = !this.expandCriteria
    },
    showComment() {
      this.expandComment = !this.expandComment
    },

    getItems(value) {
      return (
        value?.reduce((items, item, index) => {
          if (!this.criteriaList) this.criteriaList = item.elements
          items.push({ index: index, name: item.shortName })
          return items
        }, []) || []
      )
    },

    calcQLevels(expertise, change) {
      const title = calcQLevel(this.getExpType, expertise.elements)
      return change(title)
    },

    showMkbTree() {
      this.mkbTree = true
    },
    closeMkbTree() {
      this.mkbTree = false
    },
    confirmMkbCodes(codes, codesWithNames, change) {
      change(codes, 'mkb10', codesWithNames)
      this.mkbTree = false // закрываем показ диалога
    },
    copyMkbCodes(i, data) {
      // докопируем коды
      this.COMPARE_PART_DATA({
        root: `ka[${this.selectedData[i]}]`,
        tag: 'mkb10',
        value: data?.mkb10,
        // резерв
        tag2: 'mkb10_codes',
        value2: data?.mkb10_codes,
      })
    },
    doChangeScenario(scenario, data) {
      const resume = copyObject(data)

      if (this.kei) doChangeKeiResumeScenario(resume, scenario)
      else doChangeAvbResumeScenario(resume, scenario)

      // отправить обновление
      this.COMPARE_PART_DATA({
        root: `ka[${this.selectedData[0]}]`,
        tag: 'resume',
        value: resume,
      })
    },
    getTags(index) {
      return this.storedCriteria?.[index]?.tags || []
    },
    changePosition(position) {
      this.position = position
    },
  },
}
</script>
