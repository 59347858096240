var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"mt-2",attrs:{"shaped":"","outlined":""}},[_c('v-card-text',[_c('div',{staticClass:"sticky"},[_c('CompareRow',{attrs:{"hideUseBtn":"","editable":"","dense":_vm.titleGone},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var position = ref.position;
var dataResult = ref.dataResult;
var isResult = ref.isResult;
return [_c('AnalogSelect',{attrs:{"items":_vm.getItems(value, dataResult),"hide-exists":isResult,"clearable":!isResult,"hide-use-count":_vm.titleGone,"disabled":_vm.titleGone},on:{"change":function($event){return _vm.analogSelectChange(position)}},model:{value:(_vm.selectedData[position]),callback:function ($$v) {_vm.$set(_vm.selectedData, position, $$v)},expression:"selectedData[position]"}})]}}])}),(!_vm.titleGone)?_c('CompareRow',{attrs:{"hideUseBtn":"","dense":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var value = ref.value;
          var isResult = ref.isResult;
          var changeResult = ref.changeResult;
          var position = ref.position;
          var dataResult = ref.dataResult;
          var change = ref.change;
return [(_vm.dataReady)?_c('div',{staticClass:"align-self-center"},[(isResult)?_c('btn-with-confirm',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRemoveBtn(value, position)),expression:"showRemoveBtn(value, position)"}],staticClass:"fit-content",attrs:{"icon-main":"mdi-minus","icon-small":"","x-small":"","left":"","text":"","color":"error"},on:{"click":function($event){return _vm.toggleAnalItemAL(value, change)}}},[_vm._v(" не использовать как аналог ")]):_vm._e(),(isResult)?_c('btn-with-confirm',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAddBtn(value, position)),expression:"showAddBtn(value, position)"}],attrs:{"icon-main":"mdi-plus","icon-small":"","x-small":"","left":"","text":"","color":"success"},on:{"click":function($event){return _vm.toggleAnalItemAL(value, change)}}},[_vm._v(" использовать как аналог ")]):_vm._e(),(!isResult)?_c('v-btn',{staticClass:"fit-content",attrs:{"x-small":"","text":"","color":"primary","title":"добавить блок полностью","disabled":_vm.selectedData[position] === 0 ||
                !_vm.canAddItemAl(value, position, dataResult)},on:{"click":function($event){return _vm.addItemAL(value, changeResult, position, dataResult)}}},[(position === 2)?_c('v-icon',{staticClass:"mx-2",attrs:{"right":"","dark":""}},[_vm._v("mdi-arrow-left-thin")]):_vm._e(),_vm._v(" добавить аналог "),(position === 1)?_c('v-icon',{staticClass:"mx-2",attrs:{"right":"","dark":""}},[_vm._v("mdi-arrow-right-thin")]):_vm._e()],1):_vm._e()],1):_vm._e()]}}],null,false,2188818397)}):_vm._e()],1),_c('IntersectionDiv',{attrs:{"rm":"90","dont-use-store":""},on:{"gone":_vm.onTitleGone}}),_c('v-card',{staticClass:"mt-2",attrs:{"shaped":"","outlined":""}},[_c('v-card-title',{staticClass:"pb-0 text-button"},[_vm._v("Паспорт аналога")]),_c('v-card-text',[_c('v-divider'),_c('CompareRow',{attrs:{"root":"AL","root-index":_vm.selectedData,"tag":"ath"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var value = ref.value;
                var change = ref.change;
return [_c('AtcTextEditor',{attrs:{"value":value,"label":"АТХ","maxlength":_vm.maxLengthAth},on:{"change":change,"input":change}})]}}])}),_c('CompareRow',{attrs:{"root":"AL","root-index":_vm.selectedData,"tag":"lForma"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var value = ref.value;
                var change = ref.change;
return [_c('AutoGrowTextEditor',{attrs:{"value":value,"label":"Лекарственная форма","rows":2},on:{"change":change,"input":change}})]}}])}),_c('CompareRow',{attrs:{"root":"AL","root-index":_vm.selectedData,"tag":"condition"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var value = ref.value;
                var change = ref.change;
return [_c('AutoGrowTextEditor',{attrs:{"value":value,"label":"Показание","rows":5},on:{"change":change,"input":change}})]}}])})],1)],1),_c('v-card',{staticClass:"mt-3",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"pb-0 text-button"},[_vm._v("Клинические рекомендации")]),_c('v-card-text',[_c('CompareAnalogCr',{attrs:{"selectedAnalog":_vm.selectedData}})],1)],1),_c('v-card',{staticClass:"mt-3",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"pb-0 text-button"},[_vm._v("Данные об аналогах")]),_c('v-card-text',[_c('ReferenceTip',{attrs:{"label":"Экспертиза \"Данные об аналогах\" проводится на последнем этапе!"}}),_c('CompareRow',{attrs:{"root":"AL","root-index":_vm.selectedData,"root-sub":"AD","tag":"0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var value = ref.value;
                var change = ref.change;
return [_c('v-switch',{staticClass:"mt-0",attrs:{"input-value":value.manual,"label":"Ручная корректировка данных","title":"Выполнить ручную корректировку данных (отменить ручную корректирвку можно только выполнив замену данных из справочника ЛП)","color":"secondary","disabled":"","messages":value.manual
                  ? 'Внимание! Данные были откорректированы вручную.'
                  : ''},on:{"change":function (manual) { return change(Object.assign({}, value, {manual: manual})); }}})]}}])}),_c('CompareRow',{attrs:{"root":"AL","root-index":_vm.selectedData,"root-sub":"AD[0]","tag":"ru","changeUpdate":_vm.changeUpdate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var value = ref.value;
                  var change = ref.change;
return [_c('v-text-field',{attrs:{"value":value,"label":"РУ (номер РУ или кол-во РУ)"},on:{"change":change,"input":change}})]}}])}),_c('CompareRow',{attrs:{"root":"AL","root-index":_vm.selectedData,"root-sub":"AD[0]","tag":"ruEndDate","changeUpdate":_vm.changeUpdate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var value = ref.value;
                  var change = ref.change;
return [_c('v-text-field',{attrs:{"value":value,"label":"Дата окончания РУ"},on:{"change":change,"input":change}})]}}])}),_c('CompareRow',{attrs:{"root":"AL","root-index":_vm.selectedData,"root-sub":"AD[0]","tag":"obr","changeUpdate":_vm.changeUpdate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var value = ref.value;
                  var change = ref.change;
return [_c('AutoGrowTextEditor',{attrs:{"value":value,"label":"Обращение (выпуск в гражданский оборот)","rows":"1"},on:{"change":change,"input":change}})]}}])}),_c('CompareRow',{attrs:{"root":"AL","root-index":_vm.selectedData,"root-sub":"AD[0]","tag":"govPack","changeUpdate":_vm.changeUpdate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var value = ref.value;
                  var change = ref.change;
return [_c('v-text-field',{attrs:{"value":value,"label":"Гос. закупки: упаковки"},on:{"change":change,"input":change}})]}}])}),_c('CompareRow',{attrs:{"root":"AL","root-index":_vm.selectedData,"root-sub":"AD[0]","tag":"govPrice","changeUpdate":_vm.changeUpdate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var value = ref.value;
                  var change = ref.change;
return [_c('v-text-field',{attrs:{"value":value,"label":"Гос. закупки: стоимость"},on:{"change":change,"input":change}})]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }