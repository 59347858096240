<template>
  <v-container fluid>
    <v-card outlined class="mt-2">
      <v-card-title>Перечни</v-card-title>
      <v-card-text class="pb-0">
        <!-- ЖВНЛП -->
        <CompareRow tag="gnvlp" v-slot="{ value, change }">
          <v-checkbox
            label="Перечень ЖНВЛП"
            :input-value="value"
            @change="change"
          />
        </CompareRow>
        <!-- 14ВЗН -->
        <CompareRow tag="vzn14" v-slot="{ value, change }">
          <v-checkbox
            label="Перечень 14ВЗН"
            :input-value="value"
            @change="change"
          />
        </CompareRow>
        <!-- Орфанный статус на ГРЛС -->
        <CompareRow tag="isOrphan" v-slot="{ value, change }">
          <v-checkbox
            label="Орфанный статус на ГРЛС"
            :input-value="value"
            @change="change"
          />
        </CompareRow>
        <!-- ЛП предназначен для лечения заболеваний, включенных в перечень «17 нозологий» -->
        <CompareRow tag="isOrphanByIndication" v-slot="{ value, change }">
          <v-checkbox
            label="ЛП предназначен для лечения заболеваний, включенных в перечень «17 нозологий»"
            :input-value="value"
            @change="change"
          />
        </CompareRow>
      </v-card-text>
    </v-card>

    <v-card outlined class="mt-2">
      <v-card-title>Информация о препарате</v-card-title>
      <v-card-text class="pb-0">
        <!-- Тип наименования -->
        <CompareRow tag="nameType" v-slot="{ value, change }" class="d-flex">
          <v-select
            label="Тип наименования"
            :value="value"
            :items="nameTypes"
            @change="change"
            hide-details
            class="my-4"
          />
        </CompareRow>

        <!-- Химическое наименование -->
        <CompareRow tag="mnn" v-slot="{ value, change }" class="d-flex">
          <v-text-field
            @change="change"
            @input="change"
            :value="value"
            :label="labelMNN | capitalize"
          ></v-text-field>
        </CompareRow>

        <!-- АТХ -->
        <CompareRow tag="ath" v-slot="{ value, change }" class="d-flex">
          <AtcTextEditor
            title="Код анатомо-терапевтическо-химической классификации лекарственного препарата"
            :value="value"
            @change="change"
            @input="change"
            :maxlength="maxLengthAth"
          />
        </CompareRow>
      </v-card-text>
    </v-card>

    <!-- ЛекФорма -->
    <v-card outlined class="mt-2">
      <v-card-title>Лекарственная Форма</v-card-title>
      <v-card-text class="pb-0">
        <CompareRow tag="lForma" v-slot="{ value, change }" class="d-flex">
          <AutoGrowTextEditor @change="change" @input="change" :value="value" />
        </CompareRow>
      </v-card-text>
    </v-card>

    <!-- Государственная регистрация ЛП в РФ -->
    <v-card outlined class="mt-2">
      <v-card-title
        >Государственная регистрация лекарственного препарата в Российской
        Федерации</v-card-title
      ><v-card-text class="pb-0">
        <CompareRow tag="ruNumber" v-slot="{ value, change }">
          <v-text-field
            label="Номер РУ"
            :value="value"
            @change="change"
            hide-details
          />
        </CompareRow>
        <CompareRow tag="ruDate" v-slot="{ value, change }">
          <v-text-field
            label="Дата РУ"
            :value="value"
            @change="change"
            hide-details
          />
        </CompareRow>
        <CompareRow tag="ruDateConfirm" v-slot="{ value, change }">
          <v-text-field
            label="Дата подтверждения РУ"
            :value="value"
            @change="change"
            hide-details
          />
        </CompareRow>
        <CompareRow root="ruFiles" tag="text" v-slot="{ value, change }">
          <AutoGrowTextEditor
            label="Регистрационное удостоверение"
            @change="change"
            @input="change"
            :value="value"
          />
        </CompareRow>
        <!-- Файлы из plpData - ruFiles -->
        <PlpFilesList plpKey="ruFiles" />
      </v-card-text>
    </v-card>

    <!-- Сведения о воспроизведённых лекарственных препаратах -->
    <v-card outlined class="mt-2">
      <v-card-title
        >Сведения о воспроизведённых лекарственных препаратах</v-card-title
      >
      <v-card-text class="pb-0">
        <CompareRow tag="reproducedLP" v-slot="{ value, change }">
          <v-switch
            :input-value="value"
            label="Воспроизведенные ЛП"
            color="success"
            @change="change"
          />
        </CompareRow>
        <CompareRow tag="reproducedLPtext" v-slot="{ value, change, data }">
          <AutoGrowTextEditor
            label="Комментарий"
            @change="change"
            @input="change"
            :disabled="!data || !data.reproducedLP"
            :value="reproducedLPTextChange(value, data, change)"
          />
        </CompareRow>
      </v-card-text>
    </v-card>

    <!-- Показание препарата -->
    <v-card outlined class="mt-2">
      <v-card-title>Показание препарата</v-card-title>
      <v-card-text class="pb-0">
        <CompareRow tag="condition" v-slot="{ value, change }">
          <AutoGrowTextEditor
            @change="change"
            @input="change"
            :value="value"
            rows="10"
          />
        </CompareRow>
      </v-card-text>
    </v-card>

    <!-- Комментарий -->
    <v-card outlined class="mt-2">
      <v-card-title>Комментарий</v-card-title>
      <v-card-text class="pb-0">
        <CompareRow tag="comment" v-slot="{ value, change }">
          <AutoGrowTextEditor
            @change="change"
            @input="change"
            :value="value"
            rows="3"
          />
        </CompareRow>
      </v-card-text>
    </v-card>

    <!-- посмотреть все данные которые сюда приходят -->
    <!-- <CompareRow class="text-center" v-slot="{ value }">{{ value }}</CompareRow>-->
  </v-container>
</template>

<script>
import CompareRow from '@/components/compare/CompareRow'
import PlpFilesList from '@/components/plp/PlpFilesList'
import { MNN_PARSE, REPRODUCED_DEFAULT_TEXT } from '@/store/const/lpParse'
import { mapGetters } from 'vuex'
import { MAX_ATH_LENGTH } from '@/components/expAbout/const'
import AtcTextEditor from '@/components/editors/AtcTextEditor'
import AutoGrowTextEditor from '@/components/editors/AutoGrowTextEditor'

export default {
  name: 'Compare-About',
  components: {
    AtcTextEditor,
    AutoGrowTextEditor,
    CompareRow,
    PlpFilesList,
  },
  data: () => ({
    nameTypes: [],
    maxLengthAth: MAX_ATH_LENGTH,
  }),
  computed: {
    ...mapGetters(['compare_data']),
    labelMNN() {
      return this.compare_data?.nameType ?? 'МНН'
    },
  },
  created() {
    this.nameTypes = []
    for (let key in MNN_PARSE) {
      this.nameTypes.push(MNN_PARSE[key].name)
    }
  },
  methods: {
    reproducedLPTextChange(value, data, change) {
      const text = data?.reproducedLP ? value : REPRODUCED_DEFAULT_TEXT
      change(text)
      return text
    },
  },
}
</script>
