import { SUB_VERSION_MIGRATION, SUB_EX_ABOUT } from '@/store/const/expertise'

export const EX_ABOUT_TEMPLATE_VERSION = 1
// о препарате
export const EX_ABOUT_TEMPLATE = {
  gnvlp: false,
  vzn14: false,
  isOrphan: false,
  isOrphanByIndication: false,
  nameType: '',
  mnn: '',
  ath: '',
  lForma: '',
  ruNumber: '',
  ruDate: '',
  ruDateConfirm: '',
  ruFiles: {
    text: '',
  },
  ruEndDate: '',
  ruQuantity: '',
  reproducedLP: false,
  reproducedLPtext: '',
  comment: '',
  condition: '',
}

// Установили текущую версию данных
SUB_VERSION_MIGRATION[SUB_EX_ABOUT].v = EX_ABOUT_TEMPLATE_VERSION
// Добавили миграции для перехода на версию 2
SUB_VERSION_MIGRATION[SUB_EX_ABOUT][1] = function (prev) {
  return prev
}

// Максимальная длина строки АТХ
export const MAX_ATH_LENGTH = 5
