// ОЦЕНКА ЭКСПЕРТНОЙ ОРГАНИЗАЦИЙ

export function averageValue(arr) {
  const sum = arr.reduce((acc, current) => {
    return Number(acc) + Number(current.resultScore)
  }, 0)
  return Math.round(sum / arr.length) || 0
}
export function maxValue(arr) {
  return Math.max(...arr.map(item => item.resultScore)) || 0
}

export const QUALITY = 'quality'
export const SAFETY = 'safety'
export const ECONOMIC = 'economic'

export const DEFAULT_QUALITY = () => ({
  nameKi: '', //название КИ
  evidencesScore: 0, //оценка доказательности
  convictionScore: 0, //оценка убедительности
  resultScore: 0,
})

export const DEFAULT_SAFETY = () => ({
  sideEffect: '',
  pointsScore: 0,
  weightFactor: 0,
  resultScore: 0,
})

export const DEFAULT_ECONOMIC_INDICATOR = () => ({
  indicatorLP: 0,
  indicatorLPCompare: 0,
  percentDeviation: 0,
})

export const DEFAULT_ECONOMIC = () => ({
  condition: '',
  scoreKei: 0,
  scoreAvb: 0,
  keiCount: 1, //Количетсво сценариев кэи
  avbCount: 1, //количетсво сценариев авб
  indicatorsAvb: [DEFAULT_ECONOMIC_INDICATOR()], //Показатели для АВБ
  indicatorsKei: [DEFAULT_ECONOMIC_INDICATOR()], //Показатели для КЭИ
  resultScore: 0,
})

export const ASSESSMENT_ORGANIZATION = () => ({
  nameExpOrg: '', //Название экспертной организации
  qualityAssessment: {
    quality: [DEFAULT_QUALITY()],
    scoreEO: '',
    averageValue: 0,
  }, //Оценка качества КИ
  levelQuality: {
    scoreLevel: '', //Оценка эффективности
  },
  safetyAssessment: {
    safety: [DEFAULT_SAFETY()],
    scoreEO: '',
    averageValue: 0,
  }, //Оценка безопасности
  levelPrice: {
    scorePrice: '', //Оценка дополнительной терапевтической ценности
    isCalculate: false, //Учитывается ли в расчетах
  },
  sumScore: 0, //Суммарные баллы
  scoreEO: '',
  conclusionExpediency: false, //Вывод о целесообразности (да/нет)
  comment: '', //Комментарий
})

// КОНЕЦ ОЦЕНКИ ЭКСПЕРТНОЙ ОРГАНИЗАЦИЙ
