<template>
  <v-expansion-panels
    v-model="panels"
    multiple
    :disabled="storedPlpDataReady && !files.length"
    flat
    @
  >
    <v-expansion-panel @change="onExpansion">
      <v-expansion-panel-header class="text-subtitle-2">
        {{ text || defHeader }}
      </v-expansion-panel-header>
      <v-expansion-panel-content class="text-body-2">
        <v-alert v-if="error" type="error" outlined>{{ error }}</v-alert>
        <FilesList
          v-else
          :files="files"
          :path="storedPlpPath"
          :loading="loadingPlpData"
          hide-header
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FilesList from './../FilesList'

export default {
  name: 'Plp-Files-List',
  inheritAttrs: false,
  components: { FilesList },
  props: {
    text: { type: String },
    plpKey: { type: String, required: true },
    open: { type: Boolean, default: false },
  },
  data: () => ({
    panels: [],
    error: '',
  }),
  watch: {
    storedPlpData: 'onExpansion',
  },
  created() {
    if (this.open) {
      this.onExpansion()
      this.panels.push(0)
    }
  },
  computed: {
    ...mapGetters([
      'loadingPlpData',
      'storedPlpDataReady',
      'storedPlpData',
      'storedPlpPath',
    ]),
    files() {
      if (!this.storedPlpDataReady) return []
      return this.storedPlpData[this.plpKey]?.data || []
    },
    defHeader() {
      return this.files.length === 1 ? 'Файл' : 'Файлы'
    },
  },
  methods: {
    ...mapActions(['GET_PLP_DATA']),
    onExpansion() {
      this.error = ''
      if (
        !this.panels.length &&
        !this.storedPlpDataReady &&
        !this.loadingPlpData
      ) {
        this.GET_PLP_DATA().catch(err => {
          this.error = 'Ошибка данные не получены: ' + err
        })
      }
    },
  },
}
</script>
