<template>
  <div>
    <span v-if="!hideHeader" class="text-subtitle-2">{{ header }}:</span>
    <ul>
      <v-progress-linear v-if="loading" indeterminate :color="color" />
      <li v-else v-for="(file, i) in files" :key="i" class="text-body-2 mb-2">
        <v-icon
          v-if="!hideIcons"
          v-text="icons[i] || DEF_FILE_ICON"
          :color="color"
          left
        />
        <a v-if="is_link_mode" :href="getLink(file)" target="_blank">{{
          file
        }}</a>
        <span v-else>{{ file }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
const FILES_ICONS = {
  pdf: 'mdi-file-pdf-box',
  docx: 'mdi-microsoft-word',
  doc: 'mdi-microsoft-word',
  xls: 'mdi-microsoft-excel',
  xlsx: 'mdi-microsoft-excel',
  ppt: 'mdi-microsoft-powerpoint',
  pptx: 'mdi-microsoft-powerpoint',
  txt: 'mdi-note-text-outline',
  zip: 'mdi-package-variant-closed',
}
const DEF_FILE_ICON = 'mdi-file-document-outline'

// регулярка извлекающая расширение файла
const FILE_EXT_REGEXP = '.*[.](?<ext>.*)'

export default {
  name: 'Files-List',
  props: {
    text: { type: String },
    files: { type: Array, required: true, default: () => [] },
    path: { type: String },
    loading: { type: Boolean, default: false },
    color: { type: String, default: 'secondary' },
    hideIcons: { type: Boolean, default: false },
    hideHeader: { type: Boolean, default: false },
  },
  data: () => ({
    icons: [],
  }),
  watch: {
    files: 'initIcons',
  },
  created() {
    this.initIcons(this.files)
  },
  computed: {
    is_link_mode() {
      return !!this.path
    },
    header() {
      return this.text || this.files.length === 1 ? 'Файл' : 'Файлы'
    },
  },
  methods: {
    getIcons(extension) {
      return FILES_ICONS[extension] ?? DEF_FILE_ICON
    },

    initIcons(files) {
      const result = []
      if (files && !this.hideIcons) {
        // проходим по файлам и определяем их расширения
        files.forEach(file_name => {
          const regExp = new RegExp(FILE_EXT_REGEXP)
          const ext = regExp.exec(file_name)?.groups?.ext
          result.push(this.getIcons(ext))
        })
      }
      this.icons = result
    },
    getLink(file) {
      return this.path + file
    },

    goTo(file) {
      // не работает метод так как браузер не даёт переходить по ссылке на локальный ресурс
      window.open(this.getLink(file), '_blank')
    },
  },
}
</script>
