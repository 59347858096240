<template>
  <v-text-field
    :label="label"
    :value="value"
    :hint="`Код АТХ ${this.maxlength !== 5 ? 'от 5 до ' : ''}${
      this.maxlength || 7
    } символов`"
    :rules="[rules_latin, rules_min, rules_max]"
    @input="doChange"
    @blur="doBlur"
    :maxlength="maxlength"
    counter
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { lat_norm } from '@/lib/strUtils'

export default {
  name: 'atc-text-editor',
  inheritAttrs: true,
  props: {
    label: { type: String, default: 'АТХ' },
    value: { type: String },
    maxlength: { type: Number, default: null },
  },

  data() {
    return {}
  },
  methods: {
    doChange(newValue) {
      if (newValue !== this.value) this.$emit('input', newValue)
    },
    doBlur() {
      const outValue = lat_norm(this.value)
      this.doChange(outValue)
      this.$emit('blur')
    },

    rules_min: v => !v || v?.length >= 5 || 'Код АТХ не менее 5 символов',
    rules_max(v) {
      return (
        !v ||
        v?.length <= (this.maxlength ?? 7) ||
        `Код АТХ не более ${this.maxlength || 7} символов`
      )
    },
    rules_latin: v =>
      !v ||
      /^[A-Z](?:[0-9]{2}(?:[A-Z]{1}(?:[A-Z]{1}(?:[0-9]{2}|)|)|)|)$/i.test(v) ||
      'Не валидный код АТХ',
  },
}
</script>
