var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.tag)?_c('v-card',_vm._b({},'v-card',_vm.$attrs,false),[_c('v-expansion-panels',{staticClass:"mb-4",attrs:{"flat":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"deep-purple lighten-5 px-3 py-1"},[_c('div',[_c('div',{staticClass:"text-button",attrs:{"title":_vm.hideTitle ? _vm.tag.title : ''}},[_vm._v(" "+_vm._s(_vm.tag.name)+" ")]),(!_vm.hideTitle)?_c('div',{staticClass:"text-caption text--secondary"},[_vm._v(" "+_vm._s(_vm.tag.title)+" ")]):_vm._e()])]),_c('v-expansion-panel-content',{staticClass:"text-body-2 mt-4 text-pre-wrap"},[_vm._v(" "+_vm._s(_vm.tag.description)+" ")])],1)],1),_c('v-card-text',{staticClass:"pt-0"},[_c('v-select',{staticClass:"mt-2",attrs:{"value":_vm.internalValues,"items":_vm.tagValues,"multiple":_vm.isMultiple,"clearable":_vm.clearable,"title":_vm.title,"error-messages":_vm.errorText,"item-value":"value","item-text":"value","hide-details":!_vm.errorText,"dense":"","outlined":""},on:{"change":function (val) { return _vm.$emit('change', val); }},scopedSlots:_vm._u([(_vm.isLongTitle)?{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('span',[_vm._v(_vm._s(item.value))]):_vm._e(),(index === 1)?_c('span',{staticClass:"ml-1 text--disabled"},[_vm._v("(и еще "+_vm._s(_vm.valuesCount - 1)+")")]):_vm._e()]}}:null,{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"dense":""}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[(_vm.isMultiple)?_c('v-checkbox',{staticClass:"my-1",attrs:{"label":item.value,"hide-details":""},model:{value:(attrs.inputValue),callback:function ($$v) {_vm.$set(attrs, "inputValue", $$v)},expression:"attrs.inputValue"}}):_c('span',{staticClass:"v-label theme--light"},[_vm._v(" "+_vm._s(item.value)+" ")]),(item.description)?_c('v-list-item-subtitle',{staticClass:"text--disabled"},[_vm._v(" "+_vm._s(_vm._f("capitalize")(item.description))+" ")]):_vm._e()],1)],1)]}}],null,true)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }