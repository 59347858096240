<template>
  <v-card v-if="tag" v-bind="$attrs">
    <v-expansion-panels class="mb-4" flat>
      <v-expansion-panel>
        <v-expansion-panel-header class="deep-purple lighten-5 px-3 py-1">
          <div>
            <div class="text-button" :title="hideTitle ? tag.title : ''">
              {{ tag.name }}
            </div>
            <div v-if="!hideTitle" class="text-caption text--secondary">
              {{ tag.title }}
            </div>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="text-body-2 mt-4 text-pre-wrap">
          {{ tag.description }}
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card-text class="pt-0">
      <v-select
        :value="internalValues"
        :items="tagValues"
        :multiple="isMultiple"
        :clearable="clearable"
        :title="title"
        :error-messages="errorText"
        item-value="value"
        item-text="value"
        :hide-details="!errorText"
        dense
        outlined
        @change="val => $emit('change', val)"
        class="mt-2"
      >
        <template v-slot:selection="{ item, index }" v-if="isLongTitle">
          <span v-if="index === 0">{{ item.value }}</span>
          <span v-if="index === 1" class="ml-1 text--disabled"
            >(и еще {{ valuesCount - 1 }})</span
          >
        </template>
        <template v-slot:item="{ item, attrs, on }">
          <v-list-item v-bind="attrs" v-on="on" dense>
            <v-list-item-content>
              <v-checkbox
                v-if="isMultiple"
                v-model="attrs.inputValue"
                :label="item.value"
                class="my-1"
                hide-details
              /><span v-else class="v-label theme--light">
                {{ item.value }}
              </span>

              <v-list-item-subtitle
                v-if="item.description"
                class="text--disabled"
              >
                {{ item.description | capitalize }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-select>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'CriterionTagSelector',
  props: {
    tag: {
      type: Object || null,
    },
    values: {},
    clearable: {
      type: Boolean,
      default: false,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  computed: {
    internalValues() {
      if (!this.isMultiple && Array.isArray(this.values)) return this.values[0]
      else return this.values
    },
    tagValues() {
      return this.tag?.tagValues ?? []
    },
    isMultiple() {
      return this.tag?.is_multiple_values || false
    },
    valuesCount() {
      return Array.isArray(this.values) ? this.values.length : 1
    },
    title() {
      return Array.isArray(this.values)
        ? this.values.join(', ')
        : String(this.values || '')
    },
    isLongTitle() {
      return this.title.length > 40
    },
    errorText() {
      if (!this.values) return null

      const errVals = []
      if (Array.isArray(this.values)) {
        this.values
          .filter(v => v)
          .forEach(v => (this.contain(v) ? null : errVals.push(`"${v}"`)))
      } else {
        if (!this.contain(this.values)) errVals.push(`"${this.values}"`)
      }
      const isMany = errVals.length > 1
      return errVals.length
        ? `Внимание!!! потерян${isMany ? 'ы' : 'о'} значени${
            isMany ? 'я' : 'е'
          }: ${errVals.join(', ')}`
        : ''
    },
  },

  methods: {
    contain(value) {
      return !!this.tagValues.find(item => item.value === value)
    },
  },
}
</script>
