<template>
  <div v-if="!loading">
    <v-alert v-if="!expertiseReady" type="warning" outlined class="ma-0"
      >Нет сводной версий основной экспертизы
      {{ compare_ex_type_sub === 'kei-mod' ? 'КЭИ' : 'АВБ' }}</v-alert
    >
    <VersionComparator v-else v-slot="{ data_ready }">
      <CompareKA
        :dataReady="data_ready"
        :kei="compare_ex_type_sub === 'kei-mod'"
        :initMainExp="initData"
      ></CompareKA>
    </VersionComparator>
  </div>
</template>

<script>
import VersionComparator from '@/components/compare/VersionComparator'
import CompareKA from '@/components/expModAvbKei/CompareKA'
import { mapActions, mapGetters } from 'vuex'
import { SUB_EX_AVB, SUB_EX_KEI } from '@/store/const/expertise'

export default {
  components: { VersionComparator, CompareKA },
  data: () => ({
    loading: false,
    error: '',
    // данные из экспертизы
    initData: null,
  }),
  computed: {
    ...mapGetters(['compare_ex_type_sub']),
    keiReady() {
      return !!this.initData?.kei?.ka
    },
    avbReady() {
      return !!this.initData?.avb?.ka
    },
    expertiseReady() {
      return !this.loading && this.expReady
    },
    expReady() {
      return this.compare_ex_type_sub === 'kei-mod'
        ? this.keiReady
        : this.avbReady
    },
  },
  methods: {
    ...mapActions(['GET_PLP_REPORT']),
  },
  async created() {
    this.loading = true
    try {
      const { expertise } = await this.GET_PLP_REPORT([SUB_EX_AVB, SUB_EX_KEI])
      this.initData = expertise
    } catch (err) {
      this.error = `${err.message} ${err.error}`.trimEnd()
      throw err
    } finally {
      this.loading = false
    }
  },
}
</script>
