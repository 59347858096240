<template>
  <div>
    <ReferenceTip
      label="Для проведения экспертизы сравнения цитат из клинических рекомендаций необходимо последовательно сравнить все варианты КР от каждого исполнителя для каждого аналога"
    />
    <CompareRow
      hideUseBtn
      editable
      v-slot="{ value, position, isResult }"
      dense
    >
      <CrSelect
        v-model="selectedCr[position]"
        :items="getItems(value, position)"
        outlined
        class="growup"
        :mandatory="isResult"
        @change="crSelectChange(position)"
        :color="getColor(isResult)"
      />
    </CompareRow>
    <CompareRow
      root="CR"
      :root-index="selectedCr"
      hide-use
      append
      @append="appendCr"
      dense
    />
    <!-- Сравнение только КР -->
    <v-expansion-panels focusable flat class="mb-2">
      <v-expansion-panel>
        <v-expansion-panel-header class="text-overline py-0 pl-1">
          <div>
            <v-icon left small>mdi-chevron-double-right</v-icon>Подробнее
            <span
              class="text--disabled ml-3"
              title="Независимое от аналогов сравнение выбранных клинических рекомендаций экспертами"
              >( Сравнить клинические рекомендации )</span
            >
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="grey lighten-5">
          <CompareRow
            root="CR"
            :root-index="selectedCr"
            tag="shortName"
            v-slot="{ value, change }"
          >
            <v-text-field
              :value="value"
              label="Короткое название"
              :maxlength="maxShortName"
              @change="change"
              @input="change"
            />
          </CompareRow>
          <CompareRow
            root="CR"
            :root-index="selectedCr"
            tag="name"
            v-slot="{ value, change }"
          >
            <v-text-field
              :value="value"
              label="Название"
              :rules="rulesName"
              @change="change"
              @input="change"
            />
          </CompareRow>
          <CompareRow
            root="CR"
            :root-index="selectedCr"
            tag="crNumber"
            v-slot="{ value, change }"
          >
            <v-text-field
              :value="value"
              label="№ КР"
              @change="change"
              @input="change"
            />
          </CompareRow>
          <CompareRow
            root="CR"
            :root-index="selectedCr"
            tag="year"
            v-slot="{ value, change, isResult }"
          >
            <v-select
              :items="filterYears(value)"
              :value="value"
              label="год"
              :clearable="isResult"
              type="number"
              @change="change"
            />
          </CompareRow>
          <CompareRow
            root="CR"
            :root-index="selectedCr"
            tag="isOnRubricator"
            v-slot="{ value, change }"
          >
            <v-checkbox
              :input-value="value"
              label="Размещена на рубрикаторе"
              dense
              @change="change"
            />
          </CompareRow>
          <CompareRow
            root="CR"
            :root-index="selectedCr"
            tag="isApprovedNPS"
            v-slot="{ value, change }"
          >
            <v-checkbox
              :input-value="value"
              label="Одобрены НПС"
              dense
              @change="change"
            />
          </CompareRow>
          <CompareRow
            root="CR"
            :root-index="selectedCr"
            tag="associations"
            v-slot="{ value, change }"
          >
            <AutoGrowTextEditor
              :value="value"
              label="Ассоциации"
              :rows="2"
              @change="change"
              @input="change"
            />
          </CompareRow>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <!-- Сравнение цитат из кр -->
    <v-divider />

    <CompareRow
      root="AL"
      :root-index="selectedAnalog"
      root-sub="CR"
      :root-sub-index="selectedCr"
      tag="use"
      v-slot="{ value, change, isResult }"
      hide-use-btn
    >
      <v-switch
        :input-value="value"
        :color="getColor(isResult)"
        inset
        @change="change"
        hide-details
        label="Встречается в КР"
      />
    </CompareRow>
    <!-- текст цитаты из КР -->
    <CompareRow
      root="AL"
      :root-index="selectedAnalog"
      root-sub="CR"
      :root-sub-index="selectedCr"
      tag="description"
      v-slot="{ value, change, data }"
    >
      <AutoGrowTextEditor
        :value="data && data.use ? value : ''"
        :disabled="data ? !data.use : true"
        label="Цитата из КР"
        @change="change"
        @input="change"
      />
    </CompareRow>
    <!-- Кнопки использования цитаты из КР -->
    <CompareRow hide-use-btn v-slot="{ data, position, isResult }">
      <v-btn
        v-if="!isResult"
        text
        small
        color="primary"
        :disabled="!canAddCrUsedByPosition(data, position)"
        @click="addCrUsedByPosition(data, position)"
      >
        Добавить эту цитату из КР
      </v-btn>
    </CompareRow>
  </div>
</template>

<script>
import ReferenceTip from '@/components/ReferenceTip'
import CompareRow from '@/components/compare/CompareRow'
import CrSelect from './crSelect'
import {
  ANALOG_CR_YEAR_START,
  DEFAULT_CR_ANALOG_AL,
  SHORT_NAME_LENGTH,
  ANALOG_CR_SAME_FUNC,
} from './const'
import AutoGrowTextEditor from '@/components/editors/AutoGrowTextEditor'

import { copyObject, objectByPath } from '@/lib/objects'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'CompareAnalogCr',
  components: {
    ReferenceTip,
    CompareRow,
    CrSelect,
    AutoGrowTextEditor,
  },
  data: () => ({
    selectedCr: [0, 0, 0],
    maxShortName: SHORT_NAME_LENGTH,
    rulesName: [v => v?.length > 0 || 'Это поле должно быть заполнено'],
  }),
  props: {
    selectedAnalog: {
      type: Array,
    },
  },
  computed: {
    ...mapGetters([
      'compare_data',
      'compare_expertise_0_Data',
      'compare_expertise_1_Data',
    ]),
  },
  methods: {
    ...mapMutations(['COMPARE_PART_DATA']),
    getItems(value, position) {
      // фильтруем доступные КР
      return (
        value?.CR?.map((el, index) => {
          return {
            index,
            ...el,
            use: value?.AL[this.selectedAnalog[position]]?.CR[index]?.use,
          }
        }) || []
      )
    },
    crSelectChange(position) {
      if (position) {
        const another = position == 1 ? 2 : 1
        const index = this.selectedCr[position] ?? -1

        const cr = this[`compare_expertise_${position - 1}_Data`]?.CR?.[index]
        const CRs = this[`compare_expertise_${another - 1}_Data`]?.CR

        const newIndex = this.crFindIndex(CRs, cr)
        // ставим найденный КР по выбранному с другой стороны
        this.$set(this.selectedCr, another, newIndex)
      }
    },
    filterYears(value) {
      const year = new Date().getFullYear()
      const filledArray = []

      for (let i = year; i >= ANALOG_CR_YEAR_START; i--) {
        filledArray.push(i)
      }

      if (value && !filledArray.includes(value)) {
        filledArray.push(value)
      }

      return filledArray
    },
    appendCr(cr, index) {
      // добавить для всех аналогов ещё по новому КР в конце
      this.compare_data?.AL?.forEach((_, idx) => {
        const root = `AL[${idx}].CR`
        const arr = objectByPath(this.compare_data, root) || []
        if (arr.length <= index) {
          const value = [...arr, DEFAULT_CR_ANALOG_AL()]
          this.COMPARE_PART_DATA({ root, value })
        }
      })
      // переключить выбор результата на новый кр
      this.$set(this.selectedCr, 0, index)
    },
    canAddCrUsedByPosition(data, position) {
      if (position > 0) {
        const CrIndex = this.selectedCr[position]
        const Cr = objectByPath(data, `CR[${CrIndex}]`)
        const AlIndex = this.selectedAnalog[position]
        const AlCr = objectByPath(data, `AL[${AlIndex}].CR[${CrIndex}]`)
        // если есть цитата
        return Cr && AlCr?.use
      } else return false
    },
    addCrUsedByPosition(data, position) {
      if (position > 0) {
        const CrIndex = this.selectedCr[position]
        const Cr = objectByPath(data, `CR[${CrIndex}]`)
        const AlIndex = this.selectedAnalog[position]
        const AlCr = objectByPath(data, `AL[${AlIndex}].CR[${CrIndex}]`)

        this.addCrUsed(Cr, AlCr, this.selectedAnalog[0])
      }
    },

    crFindIndex(CRs, cr) {
      return CRs?.findIndex(cr0 => ANALOG_CR_SAME_FUNC(cr0, cr)) ?? -1
    },

    addCrUsed(Cr, AlCr, AlIndex) {
      // ищем индекс существующего КР
      let findIndex = this.crFindIndex(this.compare_data?.CR, Cr)

      if (findIndex > -1) {
        // нашли КР
        const root = `AL[${AlIndex}].CR[${findIndex}]`
        const value = copyObject(AlCr) || DEFAULT_CR_ANALOG_AL()
        this.COMPARE_PART_DATA({ root, value })
      } else {
        // не нашли КР + добавляем новый
        let root = 'CR'
        let arr = objectByPath(this.compare_data, root) || []
        let value = [...arr, copyObject(Cr)]
        this.COMPARE_PART_DATA({ root, value })
        findIndex = value.length - 1

        // добавляем цитату
        root = `AL[${AlIndex}].CR`
        arr = objectByPath(this.compare_data, root) || []
        value = [...arr, copyObject(AlCr) || DEFAULT_CR_ANALOG_AL()]

        this.COMPARE_PART_DATA({ root, value })
      }
      // переключить выбор результата на новый кр
      this.$set(this.selectedCr, 0, findIndex)
    },
    getColor(primary) {
      return primary ? 'success' : 'secondary'
    },
  },
}
</script>

<style scoped>
.growup {
  height: 100% !important;
}
</style>
