<template>
  <div class="d-flex mb-2 align-center">
    <!-- Кнопка слева -->
    <template v-if="left && !empty">
      <v-btn
        v-if="!hideUse"
        :disabled="disable"
        :color="color"
        :title="title"
        text
        class="font-weight-bold"
        x-small
        @click="$emit('click')"
        >{{ label
        }}<v-icon dense small right v-text="`mdi-chevron-right`" /></v-btn
      ><v-spacer />
      <!-- Дополнительная кнопка добавления -->
      <v-btn
        v-if="append"
        @click="$emit('append')"
        :color="color_append"
        :disabled="disable"
        text
        :title="apendTitle"
        x-small
        >{{ appendLabel }}<v-icon dense small right v-text="`mdi-plus`"
      /></v-btn>
      <v-spacer v-if="append" />
      <!-- иконка равенства -->
      <SameIcon :same="same" style="right: -30px !important" />
    </template>
    <!-- Кнопка справа -->
    <template v-else-if="right && !empty">
      <!-- иконка равенства -->
      <SameIcon :same="same" style="left: -30px !important" />
      <v-spacer />
      <!-- Дополнительная кнопка добавления -->
      <v-btn
        v-if="append"
        @click="$emit('append')"
        :color="color_append"
        :disabled="disable"
        text
        :title="apendTitle"
        x-small
        ><v-icon dense small left v-text="`mdi-plus`" />{{ appendLabel }}</v-btn
      >
      <v-spacer v-if="append" />
      <v-btn
        v-if="!hideUse"
        :disabled="disable"
        :color="color"
        :title="title"
        text
        class="font-weight-bold"
        x-small
        @click="$emit('click')"
        ><v-icon dense small left v-text="`mdi-chevron-left`" />{{
          label
        }}</v-btn
      >
    </template>
    <!-- пустышка -->
    <template v-else>
      <v-spacer />
      <!-- Дополнительная кнопка удаления -->
      <btn-with-confirm
        v-if="remove"
        @click="$emit('remove')"
        :color="color_remove"
        :disabled="disable"
        text
        icon-main="mdi-minus"
        :title="removeTitle"
        x-small
        >{{ removeLabel }}</btn-with-confirm
      >
      <v-icon v-else v-text="`mdi-blank`" /><v-spacer />
    </template>
  </div>
</template>

<script>
import SameIcon from '@/components/compare/SameIcon'
import btnWithConfirm from '@/components/buttons/btnWithConfirm'

export default {
  name: 'Compare-Row-Info',
  components: { SameIcon, btnWithConfirm },
  props: {
    label: { type: String, default: 'использовать' },
    title: { type: String, default: 'использовать вместо' },
    disable: { type: Boolean, default: false },
    same: { type: Boolean, default: false },
    appendLabel: { type: String, default: 'добавить' },
    append: { type: Boolean, default: false },
    apendTitle: { type: String, default: 'добавить как новый' },
    removeLabel: { type: String, default: 'удалить' },
    remove: { type: Boolean, default: false },
    removeTitle: { type: String, default: 'удалить блок полностью' },
    confirmation: { type: Boolean, default: false },
    right: { type: Boolean, default: false },
    empty: { type: Boolean, default: false },
    hideUse: { type: Boolean, default: false },
  },
  computed: {
    color() {
      return this.confirmation ? 'warning' : 'primary'
    },
    color_append() {
      return this.confirmation ? 'warning' : 'secondary'
    },
    color_remove() {
      return this.confirmation ? 'warning' : 'error'
    },
    left() {
      return !this.right
    },
  },
}
</script>
