<template>
  <div>
    <v-select
      v-model="selected"
      :items="items"
      item-text="mnn"
      item-value="index"
      persistent-hint
      :hint="itemHint"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template v-slot:selection="{ item }">
        <div>
          <AnalogIcon
            :value="item.anal"
            :index="item.index"
            left
            :disabled="!hideExists && !item.exist && !!item.index"
          />
          {{ item.mnn }}
        </div>
      </template>

      <template v-slot:item="{ item, attrs, on }">
        <v-list-item v-bind="attrs" v-on="on" dense>
          <v-list-item-icon>
            <AnalogIcon
              :value="item.anal"
              :index="item.index"
              :disabled="!hideExists && !item.exist && !!item.index"
          /></v-list-item-icon>
          <v-list-item-content
            :class="{
              [`text--disabled`]: !item.anal || (!hideExists && !item.exist),
            }"
          >
            <v-list-item-title
              >{{ item.ath }} : {{ item.mnn }}
              <!-- Кол-во упоминаний в КР -->
              <span
                v-if="item.count"
                class="ml-2 text--secondary"
                :title="`Упоминается в ${item.count} КР`"
                >( {{ item.count }} )</span
              ></v-list-item-title
            >
            <v-list-item-subtitle class="text-truncate" :title="item.lForma">{{
              item.lForma
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template #append-outer v-if="length > 1 && !hideUseCount"
        ><v-chip small class="my-0">{{ index + 1 }} из {{ length }}</v-chip>
      </template>
    </v-select>
    <span v-if="!hideUseCount && itemCount" class="mt-2 text-caption">
      * Встречается в {{ itemCount }} КР</span
    >
  </div>
</template>

<script>
import AnalogIcon from './analogIcon.vue'
export default {
  name: 'AnalogSelect',
  inheritAttrs: false,
  components: { AnalogIcon },
  props: {
    items: { type: Array || null },
    value: { type: Number },
    hideExists: {
      type: Boolean,
      default: false,
    },
    hideUseCount: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selected: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      },
    },
    index() {
      return this.items?.findIndex(({ index }) => index === this.selected)
    },
    item() {
      return this.index >= 0 ? this.items[this.index] : null
    },
    itemCount() {
      return this.item?.count ?? 0
    },
    itemHint() {
      return this.item?.lForma ?? '...'
    },
    length() {
      return this.items?.length ?? 0
    },
  },
}
</script>
