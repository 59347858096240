// длинна поля АТХ в карточке аналога
export const ATH_NAME_LENGTH = 5
export const SHORT_NAME_LENGTH = 20

// для бессрочного срока
export const UNLIMITED_TEXT = 'Бессрочный'

// флаг Встречается в клинических рекомендациях
export const DEFAULT_CR_ANALOG_AL = () => ({ use: false, description: '' })

// дефолтная data для всех аналогов
export const EX_ANALOG_DEFAULT = () => ({
  CR: [],
  AL: [
    {
      ...ANALOG_AL_DATA(),
      anal: true,
    },
  ],
})

//дефолтное тело для Перечня аналогов
export const ANALOG_AL_DATA = () => ({
  ath: '',
  mnn: '',
  lForma: '',
  condition: '',
  anal: false,
  AD: [
    {
      ru: '',
      ruEndDate: '',
      obr: '',
      govPack: '',
      govPrice: '',
      // ручтая коррекция
      manual: false,
    },
  ],
  CR: [],
})

// дефолтное тело КР в аналогах
export const ANALOG_CR_DATA = () => ({
  name: '', //Название КР
  isOnRubricator: false, //Размещены на рубрикаторе
  isApprovedNPS: false, //Одобрены НПС
  shortName: '', //Краткое название
  crNumber: null, //№ КР
  associations: '', //Ассоциации
  year: null, //Год
})

// сравнить КР
export const ANALOG_CR_SAME_FUNC = ({ shortName, crNumber }, cr) =>
  (shortName && shortName === cr?.shortName) ||
  (crNumber && crNumber === cr?.crNumber)

// сравнить аналог
export const ANALOG_SAME_FUNC = ({ mnn, ath, lForma }, al) =>
  mnn && al?.mnn === mnn && al?.ath === ath && al?.lForma === lForma

// диапазон лет в выпадающем списке формы в аналогах
//export const ANALOG_CR_YEAR_QUANTITY = 11 // устарело, см. ниже

// с какого года выпадающий список формы в аналогах
export const ANALOG_CR_YEAR_START = 2012

/** NumberToLocal
 * Число в локальной строку с разделителями
 * @param {Number} value
 * @param {String} defValue
 * @returns строка с в локализованном фомате ( разделитель тысячных долей )
 */
export function ntl(value, defValue = '0') {
  return typeof value === 'number' ? Number(value).toLocaleString() : defValue
}

export const ANALOG_AD_DATA_FIELDS = [
  'quantitybyYear0',
  'quantitybyYear1',
  'quantitybyYear2',
  'countPackbyYear0',
  'countPackbyYear1',
  'countPackbyYear2',
  'contractCostbyYear0',
  'contractCostbyYear1',
  'contractCostbyYear2',
]
// данные из справочника
export const ANALOG_AD_DATA_DEFAULT = yearData => ({
  yearData,
  quantitybyYear0: 0,
  quantitybyYear1: 0,
  quantitybyYear2: 0,
  countPackbyYear0: 0,
  countPackbyYear1: 0,
  countPackbyYear2: 0,
  contractCostbyYear0: 0,
  contractCostbyYear1: 0,
  contractCostbyYear2: 0,
})

/**
 * Функция возвращает набор значений по алгоритму
 * уточнения года обращения, в случае не состыковок с гос закупками
 * @param {boolean} useYear0 - использовать в выводе текущий год
 */
export const ANALOG_LOOKUP_DATA_FUNC = (dictData, useYear0 = false) => {
  // распакоука
  const {
    yearData: year,
    quantitybyYear0: q0,
    quantitybyYear1: q1,
    quantitybyYear2: q2,
    countPackbyYear1: count1,
    contractCostbyYear1: cost1,
  } = dictData

  const outData = {
    // обращения по умолчанию прошлый годы
    obr: ntl(q1),
    // ГЗ всегда за прошлый год
    govPack: ntl(count1),
    govPrice: ntl(cost1),
    // копия данных из справочника
    dictData: { ...dictData },
    algVer: 1, // версия алгоритма
  }

  // В прошлом году обращение и ГЗ = 0 то оставляем нули
  if (!useYear0 && !q1 && !count1) return outData
  // для основного ЛП + есть ли обращенеи за прошлые года? Если прошлый = 0 и позапрошлый = 0 то смотрим текущий
  if (useYear0 && !q1 && !q2) {
    if (q0 > 0)
      // «<упаковки за год> - <год> г. \n 0 - <год-1> г.»
      outData.obr = `${ntl(q0)} - ${year}г.\n0 - ${year - 1}г.`
    return outData
  }

  // гос закупки > 0
  if (count1 > 0) {
    // Обращение, за прошлый год = 0
    if (!q1) {
      // Обращение за позапрошлый год > 0
      if (q2 > 0) {
        // «0 - <год-1> г. \n <упаковки за год-2> - <год-2> г.»
        outData.obr = `0 - ${year - 1}г.\n${ntl(q2)} - ${year - 2}г.`
      }
      // иначе оставляем один 0 без указания года
    }
    // обращение, за прошлый год > 0
    else if (
      checkNeedYear2(q1, count1, [
        // обращение до 50 тыс упаковок и гос закупки превышают обращение больше чем на 4 тыс уп.
        { l: 0, h: 50000, d: 4000 },
        // обращение от 50 до 100 тыс упаковок и гос закупки превышают обращение больше чем на 10 тыс уп.
        { l: 50000, h: 100000, d: 10000 },
        // обращение от 100 тыс до 1 млн упаковок и гос закупки превышают обращение больше чем на 30 тыс уп.
        { l: 100000, h: 1000000, d: 30000 },
        // обращение от 1 млн упаковок и гос закупки превышают обращение больше чем на 30 тыс уп.
        { l: 100000, h: Number.MAX_SAFE_INTEGER, d: 300000 },
      ])
    ) {
      // «<упаковки за год-1> - <год-1> г.»
      outData.obr = `${ntl(q1)} - ${year - 1}г.`
      // обращение, за позапрошлый год > 0
      if (q2 > 0) {
        // «<упаковки за год-1> - <год-1> г. \n <упаковки год-2> - <год-2> г.»
        outData.obr = `${outData.obr}\n${ntl(q2)} - ${year - 2}г.`
      }
      // используем текущий год?
      else if (useYear0 && q0 > 0) {
        // «<упаковки за год> - <год> г. \n <упаковки год-1> - <год-1> г.»
        outData.obr = `${ntl(q0)} - ${year}г.\n${outData.obr}`
      }
    }
  }
  return outData
}
// сервисная функция
function checkNeedYear2(quantity, countPack, ranges = []) {
  if (!Array.isArray(ranges)) return false
  for (const range of ranges) {
    if (
      quantity >= range.l &&
      quantity < range.h &&
      countPack - quantity > range.d
    )
      return true
  }
  return false
}
// Для теста
// console.log(
//   'ANALOG_LOOKUP_DATA_FUNC - 0',
//   ANALOG_LOOKUP_DATA_FUNC({
//     yearData: 2022,
//     quantitybyYear1: 0,
//     quantitybyYear2: 746964,

//     countPackbyYear1: 436,
//     contractCostbyYear1: 326631.97,
//   })
// )

// console.log(
//   'ANALOG_LOOKUP_DATA_FUNC - 1',
//   ANALOG_LOOKUP_DATA_FUNC(
//     {
//       yearData: 2022,
//       quantitybyYear1: 100000,
//       quantitybyYear2: 0,

//       countPackbyYear1: 130002,
//       contractCostbyYear1: 326631.97,
//     },
//     true
//   )
// )
