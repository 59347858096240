<template>
  <v-container fluid class="pb-4">
    <v-row>
      <v-col cols="12">
        <v-text-field
          label="Показание"
          v-model="condition"
          :outlined="!readonly"
          placeholder="Введите показание"
          prepend-icon="mdi-text-box-search-outline"
          @input="doChange()"
          @blur="doBlur()"
          hide-details
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-tabs v-model="activeTab" centered center-active show-arrows>
          <v-tab>
            КЭИ
           </v-tab>
          <v-tab>
            АВБ
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="activeTab">
          <v-tab-item>
            <v-select
              label="Кол-во сценариев (КЭИ)"
              v-model="keiCount"
              class="mt-3 font-weight-bold"
              hide-details
              outlined
              dense
              :items="scoresIndicators"
              @change="doChangeIndicatorsKei" />
            <div v-for="(indicator, idx) in indicatorsKei" :key="indicator.id">
              <IndicatorsEditor
                class="mb-3"
                :indicator="{ ...indicator, idx }"
                @change="(kei, force) => doChangeKei(kei, idx, force)"
                @blur="doBlur"
                hint="ICER (при расчете в КЭИ) или затраты"
              /></div
            >
          </v-tab-item>
          <v-tab-item>
            <v-combobox
              label="Кол-во сценариев (АВБ)"
              v-model="avbCount"
              class="mt-3 font-weight-bold"
              hide-details
              outlined
              dense
              :items="scoresIndicators"
              @change="doChangeIndicatorsAvb"
            />
            <div v-for="(indicator, idx) in indicatorsAvb" :key="indicator.id">
              <IndicatorsEditor
                class="mb-3"
                :indicator="{ ...indicator, idx }"
                @change="(kei, force) => doChangeAvb(kei, idx, force)"
                @blur="doBlur"
              />
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
      <v-col cols="12" md="12">
        <v-select
          v-model.number="scoreKei"
          :items="scoresKei"
          :outlined="!readonly"
          label="Оценка КЭИ"
          prepend-icon="mdi-book-open-page-variant-outline"
          @input="doChange(true)"
          @blur="doBlur()"
          hide-details
          type="number"
        />
      </v-col>
      <v-col cols="12" md="12">
        <v-select
          v-model.number="scoreAvb"
          :items="scoresAvb"
          :outlined="!readonly"
          label="Оценка АВБ"
          prepend-icon="mdi-book-open-blank-variant"
          @input="doChange(true)"
          @blur="doBlur()"
          hide-details
          type="number"
        />
      </v-col>
      <v-col cols="12" md="12">
        <v-text-field
          readonly
          @input="doChange(true)"
          :value="resultScore"
          label="Итоговая оценка"
          hide-details
          type="number"
        />
      </v-col>
    </v-row>
    <!-- Удалить текущую строку -->
    <v-row v-if="!readonly && 0">
      <v-col class="text-right">
        <btn-with-confirm
          text
          icon-main="mdi-delete-forever-outline"
          title="Удалить препарат"
          @click="deleteAssessments"
        >
          Удалить
        </btn-with-confirm>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BtnWithConfirm from '@/components/buttons/btnWithConfirm'
import { DEFAULT_ECONOMIC_INDICATOR } from '@/components/expSummary/summaryScore/assessmentConst'
import IndicatorsEditor from '@/components/expSummary/summaryScore/comp/indicators/IndicatorsEditor'

export default {
  name: 'CompareEconomicEditor',
  components: { IndicatorsEditor, BtnWithConfirm },
  data: () => ({
    condition: '',
    scoreKei: 0,
    scoreAvb: 0,
    activeTab: 0,
    idx: '',
    scoresKei: [
      -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
    ],
    scoresAvb: [-3, -2, -1, 1, 0, 1, 2, 4, 6, 8],
    scoresIndicators: [1, 2, 3, 4],
    indicatorsKei: [],
    indicatorsAvb: [],
    keiCount: 1,
    avbCount: 1,
  }),
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.init()
  },
  watch: {
    value: 'init',
  },
  methods: {
    doChange(force = false) {
      if (!this.readonly) {
        const {
          condition,
          scoreKei,
          scoreAvb,
          keiCount,
          avbCount,
          indicatorsKei,
          indicatorsAvb,
        } = this
        const outObj = {
          ...this.value,
          resultScore: this.resultScore,
          condition,
          scoreKei,
          scoreAvb,
          keiCount,
          avbCount,
          indicatorsKei,
          indicatorsAvb,
        }
        this.$emit('change', outObj, force)
      }
    },
    // ушли с элемента
    doBlur() {
      this.$emit('blur')
    },
    deleteAssessments() {
      this.$emit('delete')
    },
    init() {
      this.condition = this.value?.condition || ''
      this.scoreKei = this.value?.scoreKei || 0
      this.scoreAvb = this.value?.scoreAvb || 0
      this.idx = this.value?.idx || ''
      this.keiCount = this.value?.keiCount || 1
      this.avbCount = this.value?.avbCount || 1
      this.indicatorsKei = this.value?.indicatorsKei || []
      this.indicatorsAvb = this.value?.indicatorsAvb || []
    },
    doChangeIndicatorsKei() {
      for (let i = 0; i < this.keiCount; i++) {
        if (!this.indicatorsKei[i])
          this.indicatorsKei.push(DEFAULT_ECONOMIC_INDICATOR())
      }
      this.indicatorsKei.splice(this.keiCount)
      this.doChange(true)
    },
    doChangeIndicatorsAvb() {
      for (let i = 0; i < this.avbCount; i++) {
        if (!this.indicatorsAvb[i])
          this.indicatorsAvb.push(DEFAULT_ECONOMIC_INDICATOR())
      }
      this.indicatorsAvb.splice(this.avbCount)
      this.doChange(true)
    },
    doChangeKei(kei, idx, force = false) {
      this.indicatorsKei[idx] = kei
      this.doChange(force)
    },
    doChangeAvb(kei, idx, force = false) {
      this.indicatorsAvb[idx] = kei
      this.doChange(force)
    },
  },
  computed: {
    resultScore() {
      const kei = Number(this.scoreKei || 0)
      const avb = Number(this.scoreAvb || 0)
      return kei + avb
    },
  },
}
</script>
