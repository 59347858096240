<template>
  <VersionComparator>
    <CompareAbout />
  </VersionComparator>
</template>

<script>
import VersionComparator from '@/components/compare/VersionComparator'
import CompareAbout from '@/components/expAbout/CompareAbout'

export default {
  components: {
    VersionComparator,
    CompareAbout,
  },
  data: () => ({}),
}
</script>
