<template>
  <div v-bind="$attrs" class="d-flex flex-column">
    <v-list dense v-bind="$attrs" v-on="$listeners" class="growup">
      <v-list-item-group
        v-model="selected"
        :color="color"
        :mandatory="mandatory"
      >
        <v-list-item v-for="(item, i) in itemsSort" :key="i" dense>
          <v-list-item-icon class="mr-4"
            ><v-icon v-if="item.use" title="Есть упоминание аналога в КР"
              >mdi-check-circle-outline</v-icon
            ><v-icon v-else disabled
              >mdi-radiobox-blank</v-icon
            ></v-list-item-icon
          >
          <v-list-item-content
            :class="{
              [`text--disabled`]: !item.use,
            }"
          >
            <v-list-item-title
              >{{ item.shortName }} : № {{ item.crNumber || ' - ' }} :
              {{ item.year || 'не указан' }}</v-list-item-title
            >
            <v-list-item-subtitle class="text-truncate" :title="item.name">{{
              item.name
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <div v-if="!hideInfo" class="mt-auto text-caption text-right">
      {{ selected + 1 }} из {{ length }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'CrSelect',
  inheritAttrs: false,
  props: {
    items: { type: Array || null },
    value: { type: Number },
    hideInfo: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'primary',
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    itemsSort() {
      const arr = this.items || []
      return arr?.sort((a, b) => a?.shortName?.localeCompare(b?.shortName))
    },
    selected: {
      get() {
        return (
          this.itemsSort?.findIndex(({ index }) => index === this.value) ?? -1
        )
      },
      set(newValue) {
        const index = this.items?.[newValue]?.index ?? -1
        this.$emit('input', index)
        this.$emit('change', index)
      },
    },
    index() {
      return this.items?.[this.selected]?.index
    },
    item() {
      return this.index >= 0 ? this.items[this.selected] : null
    },
    itemHint() {
      return this.item?.name ?? '...'
    },
    length() {
      return this.items?.length ?? 0
    },
  },
}
</script>

<style scoped>
.growup {
  height: 100% !important;
}
</style>
