<template>
  <VersionComparator>
    <CompareAssessment />
  </VersionComparator>
</template>

<script>
import VersionComparator from '@/components/compare/VersionComparator'
import CompareAssessment from '@/components/expAssessment/CompareAssessment.vue'

export default {
  components: {
    CompareAssessment,
    VersionComparator,
  },
  data: () => ({}),
}
</script>
