import {
  SUB_EX_AVB_MOD,
  SUB_EX_KEI_MOD,
  SUB_VERSION_MIGRATION,
} from '@/store/const/expertise'
import { copyObject } from '@/lib/objects'

const EX_KEI_TEMPLATE_VERSION = 8
const EX_AVB_TEMPLATE_VERSION = 7

// Сюда бы перенести определение всех констант для Экспертизы КЕИ и АВБ
// дефолтное поле elements для каркаса АВБ
export const EX_AVB_DEFAULT_DATA = {
  correspond: null,
  criteria: '',
  comment: '',
  tagsSelected: [], // массив объектов  { name: <имя тега>, values: []}
}

export const EX_CORRESPOND = 'Соответствует'
export const EX_NOT_CORRESPOND = 'Не соответствует'

export const EX_GET_CORRESPOND = is_matched =>
  is_matched ? EX_CORRESPOND : EX_NOT_CORRESPOND

const resultsText =
  'Большие/меньшие затраты: [+/-Х] руб. за Х года ([+/-Х] руб. за 1-й год)\n' +
  'Затраты на текущую практику: [Х] руб. за Х года ([Х] руб. за 1-й год)\n' +
  'Затраты на ожидаемую практику: [Х] руб. за Х года ([Х] руб. за 1-й год)'

const currentStateText =
  'Текущая практика лекарственной терапии, включенная в перечень [напишите название предлагаемого перечня/перечней]: [процент (%) и название ЛП].'
const expectedStateText =
  'Ожидаемая практика лекарственной терапии, включенная в перечень [напишите название предлагаемого перечня/перечней]: [процент (%) и название ЛП].'

// дефолтное содержание АВБ
export const EX_AVB_DEFAULT = {
  shortName: '',
  /*name: '',*/
  mkb10: '',
  /*mkb10link: '',*/
  reference: '',
  condition: '',
  initElements: [],
  elements: [],
  qLevels: '',
  resume: {
    author: '', // Автор АВБ
    scenario: 1,
    epidemiology: [''],
    currentState: [currentStateText],
    expectedState: [expectedStateText],
    results: [resultsText],
    comment: '',
  },
  note: '',
}

// максимальное кол-во сценариев для АВБ
export const EX_AVB_RESUME_MAX_SCENARIO = 3
export const EX_AVB_RESUME_SCENARIO_ARR = () =>
  Array.from({ length: EX_AVB_RESUME_MAX_SCENARIO }, (_, i) => i + 1)
// максимальное кол-во сценариев для КЕИ
export const EX_KEI_RESUME_MAX_SCENARIO = 3
export const EX_KEI_RESUME_SCENARIO_ARR = () =>
  Array.from({ length: EX_KEI_RESUME_MAX_SCENARIO }, (_, i) => i + 1)

// дефолтное поле elements для каркаса КЭИ
export const EX_KEI_DEFAULT_DATA = {
  correspond: null,
  criteria: '',
  comment: '',
  tagsSelected: [], // массив объектов  { name: <имя тега>, values: []}
}

export const EX_KEI_METHOD = [
  { id: 1, title: 'Анализ «затраты-полезность»' },
  { id: 2, title: 'Анализ «затраты-эффективность»', state: false },
  { id: 3, title: 'Анализ минимизации затрат', state: false },
  { id: 4, title: 'Нет', state: true, calculate: true },
]

export const EX_KEI_METHOD_CHECK = states => {
  return [
    states[0],
    states[1],
    states[2],
    !(states[0] || states[1] || states[2]),
  ]
}

export const EX_KEI_METHOD_DEFAULT = () => [false, false, false, true]
export const EX_KEI_ANNOTATIONS_DEFAULT = () => ['', '', '', '']

const kei_analog_template =
  'Препараты сравнения, включенные в перечень [напишите название предлагаемого перечня/перечней]: [названия ЛП].\n' +
  'Препараты сравнения, не включенные в перечень [напишите название предлагаемого перечня/перечней]: [названия ЛП].'

// дефолтное содержание КЭИ
export const EX_KEI_DEFAULT = {
  shortName: '',
  /*name: '',*/
  mkb10: '',
  /*mkb10link: '',*/
  reference: '',
  condition: '',
  elements: [],
  initElements: [], //Критерий основной экспертизы КЭИ
  qLevels: '',
  resume: {
    author: '', // Автор КЕИ
    method: {
      // Использованный метод
      items: EX_KEI_METHOD_DEFAULT(),
      annotations: EX_KEI_ANNOTATIONS_DEFAULT(),
    },
    scenario: 1,
    analog: [kei_analog_template], // Препараты сравнения
    clinicalAdvantages: [''], // Клинические преимущества
    econAdvantages: [''], // Экономические преимущества
    results: [''], // Основные результаты
    comment: '', // Комментарий к КЭИ
  },
  note: '', // Мои заметки к КЭИ
}

// Расчёт методологического качества
const EX_KA_MIDDLES = {
  [SUB_EX_KEI_MOD]: [5, 9, 11, 12],
  [SUB_EX_AVB_MOD]: [7, 10, 11],
}

export const EX_QLEVELS_HI = 'высокое'
export const EX_QLEVELS_MID = 'среднее'
export const EX_QLEVELS_LOW = 'низкое'

export const EX_QLEVELS_DEFAULT = [
  { active: false, color: 'green', title: EX_QLEVELS_HI },
  { active: false, color: 'warning', title: EX_QLEVELS_MID },
  { active: false, color: 'red', title: EX_QLEVELS_LOW },
]

export const checkQLevels = (
  expType,
  elements = [],
  version = 1,
  withModel = false
) => {
  if (version === 1) {
    return checkQLevels_1(expType, elements)
  }
  return checkQLevels_2(elements, withModel, expType)
}

/** Делаем расчёт методогического качества возвращаем весь массив  */
const checkQLevels_2 = (elements = [], withModel, expType) => {
  // const qLevels = copyObject(EX_QLEVELS_DEFAULT)
  const qLevels = checkQLevels_1(expType, elements)
  let resultWeight = withModel ? 3 : 0
  elements.forEach(({ correspond, weight }) => {
    if (correspond) {
      resultWeight = resultWeight + weight
    }
  })
  // if (withModel) {
  //   if (resultWeight >= 26.5) {
  //     qLevels[0].active = true
  //   } else if (resultWeight >= 24) {
  //     qLevels[1].active = true
  //   } else {
  //     qLevels[2].active = true
  //   }
  // } else {
  //   if (resultWeight >= 23.5) {
  //     qLevels[0].active = true
  //   } else if (resultWeight >= 21) {
  //     qLevels[1].active = true
  //   } else {
  //     qLevels[2].active = true
  //   }
  // }

  return { qLevels, resultWeight }
}

/** Делаем расчёт методогического качества возвращаем весь массив  */
const checkQLevels_1 = (expType, elements = []) => {
  let high = 0
  let medium = 0

  const count = elements.length
  const middles = EX_KA_MIDDLES[expType]
  const qLevels = copyObject(EX_QLEVELS_DEFAULT)

  elements.forEach(({ correspond, order }) => {
    if (correspond) high++
    if (middles?.includes(order) && !correspond) medium++
  })

  if (high === count) {
    qLevels[0].active = true
  } else {
    if (medium > 0 && high === count - medium) {
      //if (medium === middles.length && high === count - medium) {
      qLevels[1].active = true
    } else {
      qLevels[2].active = true
    }
  }
  return qLevels
}

function reDimArray(arr = [], newLength = 1, defValue = '') {
  // перекопируем массив: expectedState
  return Array.apply(null, Array(newLength)).map(
    (_, i) => (Array.isArray(arr) ? arr[i] : arr) ?? defValue
  )
}

/** Модифицирем кол-во сценариев АВБ и возвращаем новый объект резюме */
export const doChangeAvbResumeScenario = (resume, scenario_count = 1) => {
  const scenario = Number(scenario_count) || 1
  // Установить кол-во сценариев
  resume.scenario = scenario
  // перекопируем массив: epidemiology
  resume.epidemiology = reDimArray(resume.epidemiology, scenario)
  // перекопируем массив: currentState
  resume.currentState = reDimArray(
    resume.currentState,
    scenario,
    currentStateText
  )
  // перекопируем массив: expectedState
  resume.expectedState = reDimArray(
    resume.expectedState,
    scenario,
    expectedStateText
  )
  // перекопируем массив: results
  resume.results = reDimArray(resume.results, scenario, resultsText)

  return resume
}

/** Модифицирем кол-во сценариев КЕИ и возвращаем новый объект резюме */
export const doChangeKeiResumeScenario = (resume, scenario_count = 1) => {
  const scenario = Number(scenario_count) || 1
  // Установить кол-во сценариев
  resume.scenario = scenario
  // перекопируем массив: analog
  resume.analog = reDimArray(resume.analog, scenario, kei_analog_template)
  // перекопируем массив: clinicalAdvantages
  resume.clinicalAdvantages = reDimArray(resume.clinicalAdvantages, scenario)
  // перекопируем массив: econAdvantages
  resume.econAdvantages = reDimArray(resume.econAdvantages, scenario)
  // перекопируем массив: results
  resume.results = reDimArray(resume.results, scenario)

  return resume
}

/** достаём методогическое качество и возвращаем  */
export const getQLevel = (qLevels = EX_QLEVELS_DEFAULT) => {
  return qLevels?.find(({ active }) => active)?.title ?? EX_QLEVELS_LOW
}

/** Делаем расчёт методогического качества возвращаем   */
export const calcQLevel = (expType, elements) => {
  return getQLevel(checkQLevels(expType, elements))
}

// Установили текущую версию данных КЕИ
SUB_VERSION_MIGRATION[SUB_EX_KEI_MOD].v = EX_KEI_TEMPLATE_VERSION
// Добавили миграции для перехода на версию 2
SUB_VERSION_MIGRATION[SUB_EX_KEI_MOD][1] = function (prev) {
  const ka = []
  if (prev)
    for (const key in prev) {
      ka.push(prev[key])
    }
  return { ka, v: 2 }
}

// Добавили миграции для перехода на версию 3
SUB_VERSION_MIGRATION[SUB_EX_KEI_MOD][2] = function (prev) {
  prev?.ka.forEach(el => {
    if (!el.resume) {
      el.resume = {
        method: EX_KEI_METHOD_DEFAULT(),
        analog: '',
        clinicalAdvantages: '',
        econAdvantages: '',
        results: '',
      }
    }
  })
  return prev
}

const mkb10RegExp = /(?:\w\d{2}[.]\d|\w\d{2}[-]\w\d{2}|\w\d{2})(?=\s)/gm

// Добавили миграции для перехода на версию 4
SUB_VERSION_MIGRATION[SUB_EX_KEI_MOD][3] = function (prev) {
  prev?.ka.forEach(el => {
    if (!el.mkb10_codes)
      try {
        el.mkb10_codes = [...String(el.mkb10).matchAll(mkb10RegExp)].join(' / ')
      } catch {
        //
      }
  })
  return prev
}

// Добавили миграции для перехода на версию 5
SUB_VERSION_MIGRATION[SUB_EX_KEI_MOD][4] = function (prev) {
  prev?.ka.forEach(el => {
    // сценарии для ожидаемой и текущей практики
    el.resume = {
      ...el.resume,
      scenario: 1,
      analog: [el.resume.analog],
      clinicalAdvantages: [el.resume.clinicalAdvantages],
      econAdvantages: [el.resume.econAdvantages],
      results: [el.resume.results],
    }
  })
  return prev
}

// Добавили миграции для перехода на версию 6 -
SUB_VERSION_MIGRATION[SUB_EX_KEI_MOD][5] = function (prev) {
  prev?.ka.forEach(el => {
    el.resume.comment = ''
    el.note = el.comment
    delete el.comment
  })
  return prev
}

// Добавили миграции для перехода на версию 7 -
SUB_VERSION_MIGRATION[SUB_EX_KEI_MOD][6] = function (prev) {
  prev?.ka.forEach(el => {
    const items = el.resume.method
    el.resume.method = {
      items,
      annotations: EX_KEI_ANNOTATIONS_DEFAULT(),
    }
  })
  return prev
}

// Добавили миграции для перехода на версию 8 -
SUB_VERSION_MIGRATION[SUB_EX_KEI_MOD][7] = function (prev) {
  prev?.ka.forEach(el => {
    el.resume.author = ''
  })
  return prev
}

// Установили текущую версию данных АВБ
SUB_VERSION_MIGRATION[SUB_EX_AVB_MOD].v = EX_AVB_TEMPLATE_VERSION
// Добавили миграции для перехода на версию 2
SUB_VERSION_MIGRATION[SUB_EX_AVB_MOD][1] = function (prev) {
  const ka = []
  if (prev)
    for (const key in prev) {
      ka.push(prev[key])
    }
  return { ka, v: 2 }
}

// Добавили миграции для перехода на версию 3
SUB_VERSION_MIGRATION[SUB_EX_AVB_MOD][2] = function (prev) {
  prev?.ka.forEach(el => {
    if (!el.resume) {
      el.resume = {
        currentState: currentStateText,
        expectedState: expectedStateText,
        results: resultsText,
      }
    }
  })
  return prev
}

// Добавили миграции для перехода на версию 4
SUB_VERSION_MIGRATION[SUB_EX_AVB_MOD][3] = function (prev) {
  prev?.ka.forEach(el => {
    if (!el.mkb10_codes)
      try {
        el.mkb10_codes = [...String(el.mkb10).matchAll(mkb10RegExp)].join(' / ')
      } catch {
        //
      }
  })
  return prev
}

// Добавили миграции для перехода на версию 5 -
SUB_VERSION_MIGRATION[SUB_EX_AVB_MOD][4] = function (prev) {
  prev?.ka.forEach(el => {
    // сценарии для ожидаемой и текущей практики
    el.resume = {
      ...el.resume,
      scenario: 1,
      epidemiology: [el.epidemiology],
      currentState: [el.resume.currentState],
      expectedState: [el.resume.expectedState],
      results: [el.resume.results],
    }
    delete el.epidemiology
  })
  return prev
}

// Добавили миграции для перехода на версию 6 -
SUB_VERSION_MIGRATION[SUB_EX_AVB_MOD][5] = function (prev) {
  prev?.ka.forEach(el => {
    el.resume.comment = ''
    el.note = el.comment
    delete el.comment
  })
  return prev
}

// Добавили миграции для перехода на версию 7 -
SUB_VERSION_MIGRATION[SUB_EX_AVB_MOD][6] =
  SUB_VERSION_MIGRATION[SUB_EX_KEI_MOD][7]
