var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ReferenceTip',{attrs:{"label":"Для проведения экспертизы сравнения цитат из клинических рекомендаций необходимо последовательно сравнить все варианты КР от каждого исполнителя для каждого аналога"}}),_c('CompareRow',{attrs:{"hideUseBtn":"","editable":"","dense":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var position = ref.position;
var isResult = ref.isResult;
return [_c('CrSelect',{staticClass:"growup",attrs:{"items":_vm.getItems(value, position),"outlined":"","mandatory":isResult,"color":_vm.getColor(isResult)},on:{"change":function($event){return _vm.crSelectChange(position)}},model:{value:(_vm.selectedCr[position]),callback:function ($$v) {_vm.$set(_vm.selectedCr, position, $$v)},expression:"selectedCr[position]"}})]}}])}),_c('CompareRow',{attrs:{"root":"CR","root-index":_vm.selectedCr,"hide-use":"","append":"","dense":""},on:{"append":_vm.appendCr}}),_c('v-expansion-panels',{staticClass:"mb-2",attrs:{"focusable":"","flat":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"text-overline py-0 pl-1"},[_c('div',[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-chevron-double-right")]),_vm._v("Подробнее "),_c('span',{staticClass:"text--disabled ml-3",attrs:{"title":"Независимое от аналогов сравнение выбранных клинических рекомендаций экспертами"}},[_vm._v("( Сравнить клинические рекомендации )")])],1)]),_c('v-expansion-panel-content',{attrs:{"color":"grey lighten-5"}},[_c('CompareRow',{attrs:{"root":"CR","root-index":_vm.selectedCr,"tag":"shortName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var change = ref.change;
return [_c('v-text-field',{attrs:{"value":value,"label":"Короткое название","maxlength":_vm.maxShortName},on:{"change":change,"input":change}})]}}])}),_c('CompareRow',{attrs:{"root":"CR","root-index":_vm.selectedCr,"tag":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var change = ref.change;
return [_c('v-text-field',{attrs:{"value":value,"label":"Название","rules":_vm.rulesName},on:{"change":change,"input":change}})]}}])}),_c('CompareRow',{attrs:{"root":"CR","root-index":_vm.selectedCr,"tag":"crNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var change = ref.change;
return [_c('v-text-field',{attrs:{"value":value,"label":"№ КР"},on:{"change":change,"input":change}})]}}])}),_c('CompareRow',{attrs:{"root":"CR","root-index":_vm.selectedCr,"tag":"year"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var change = ref.change;
var isResult = ref.isResult;
return [_c('v-select',{attrs:{"items":_vm.filterYears(value),"value":value,"label":"год","clearable":isResult,"type":"number"},on:{"change":change}})]}}])}),_c('CompareRow',{attrs:{"root":"CR","root-index":_vm.selectedCr,"tag":"isOnRubricator"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var change = ref.change;
return [_c('v-checkbox',{attrs:{"input-value":value,"label":"Размещена на рубрикаторе","dense":""},on:{"change":change}})]}}])}),_c('CompareRow',{attrs:{"root":"CR","root-index":_vm.selectedCr,"tag":"isApprovedNPS"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var change = ref.change;
return [_c('v-checkbox',{attrs:{"input-value":value,"label":"Одобрены НПС","dense":""},on:{"change":change}})]}}])}),_c('CompareRow',{attrs:{"root":"CR","root-index":_vm.selectedCr,"tag":"associations"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var change = ref.change;
return [_c('AutoGrowTextEditor',{attrs:{"value":value,"label":"Ассоциации","rows":2},on:{"change":change,"input":change}})]}}])})],1)],1)],1),_c('v-divider'),_c('CompareRow',{attrs:{"root":"AL","root-index":_vm.selectedAnalog,"root-sub":"CR","root-sub-index":_vm.selectedCr,"tag":"use","hide-use-btn":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var change = ref.change;
var isResult = ref.isResult;
return [_c('v-switch',{attrs:{"input-value":value,"color":_vm.getColor(isResult),"inset":"","hide-details":"","label":"Встречается в КР"},on:{"change":change}})]}}])}),_c('CompareRow',{attrs:{"root":"AL","root-index":_vm.selectedAnalog,"root-sub":"CR","root-sub-index":_vm.selectedCr,"tag":"description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var change = ref.change;
var data = ref.data;
return [_c('AutoGrowTextEditor',{attrs:{"value":data && data.use ? value : '',"disabled":data ? !data.use : true,"label":"Цитата из КР"},on:{"change":change,"input":change}})]}}])}),_c('CompareRow',{attrs:{"hide-use-btn":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var position = ref.position;
var isResult = ref.isResult;
return [(!isResult)?_c('v-btn',{attrs:{"text":"","small":"","color":"primary","disabled":!_vm.canAddCrUsedByPosition(data, position)},on:{"click":function($event){return _vm.addCrUsedByPosition(data, position)}}},[_vm._v(" Добавить эту цитату из КР ")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }