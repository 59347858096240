<template>
  <div class="d-flex align-center">
    <v-btn
      v-if="!hideCheckbox"
      :color="selected ? 'primary' : 'default'"
      icon
      height="32px"
      width="32px"
      @click="$emit('click')"
    >
      <v-icon v-text="getIcon"> </v-icon>
    </v-btn>
    <span class="text--secondary ml-1">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: 'mkbCheckbox',
  props: {
    hideCheckbox: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
    },
    chs: {
      type: Boolean,
      default: false,
    },
    radio: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getIcon() {
      return this.radio ? this.getRadioIcon : this.getCheckIcon
    },
    getCheckIcon() {
      if (this.selected) {
        return 'mdi-checkbox-outline'
      } else if (this.chs) {
        return 'mdi-checkbox-intermediate-variant'
      } else {
        return 'mdi-checkbox-blank-outline'
      }
    },
    getRadioIcon() {
      if (this.selected) {
        return 'mdi-radiobox-marked'
      } else if (this.chs) {
        return 'mdi-radiobox-blank'
      } else {
        return 'mdi-radiobox-blank'
      }
    },
  },
}
</script>
