<template>
  <v-card :loading="loading" v-bind="$attrs">
    <v-card-subtitle v-if="!hideInfo" class="caption text-right pb-0"
      >{{
        dictRefresh
          ? '... проверка обновления справочника на НСИ'
          : dictMKBDescription
      }}
      <v-btn
        v-if="isManager"
        icon
        small
        title="Проверить и обновить на НСИ"
        class="ml-1"
        :loading="dictRefresh"
        @click="dictRefreshClick"
        ><v-icon small>mdi-refresh</v-icon></v-btn
      >
    </v-card-subtitle>

    <v-card-title v-if="dictMKBData">
      <v-text-field
        v-model="search"
        label="Поиск по МКБ-10/названию"
        clearable
        hide-details
        @keypress="searchTextKeyPress"
        @click:clear="searchNow()"
        :disabled="loading || !mkbData"
      >
        <template v-slot:[`append-outer`]>
          <v-btn
            @click="searchNow(search)"
            small
            text
            :disabled="search.length < 2"
            ><v-icon left>mdi-magnify</v-icon>Искать</v-btn
          >
        </template>
      </v-text-field>
    </v-card-title>
    <v-card-text v-if="!loading">
      <div style="min-height: 30px" v-if="showResult">
        <expand-text
          :value="sortedLine"
          :separator="separator"
          closed
          :words-count="20"
        />
      </div>
      <v-alert
        class="mt-1"
        dense
        text
        type="warning"
        v-if="missedItems && !hideAlert"
        dismissible
      >
        <span class="text--secondary"> Внимание! Не найдены коды МКБ-10: </span>
        <span class="text--primary">{{ missedItems }}</span>
      </v-alert>
      <v-treeview
        :search="searchText"
        :filter="someSearch"
        v-if="mkbData"
        :items="mkbData"
        :open.sync="openedItems"
        dense
      >
        <template v-slot:[`append`]="{ item }" v-if="isAdmin">
          <div class="text-right text--disabled">{{ item.id }}</div>
        </template>
        <template v-slot:[`label`]="{ item }">
          <span
            class="mr-4 text--secondary"
            :inner-html.prop="item.code | highlight(searchText)"
          />
          <span :inner-html.prop="item.name | highlight(searchText)" />
        </template>
        <template v-slot:[`prepend`]="{ item }">
          <mkb-checkbox
            :hide-checkbox="item.root"
            :selected="item.selected"
            :chs="item.chs"
            @click="confirmSelection(item)"
          />
        </template>
      </v-treeview>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { copyObject } from '@/lib/objects'
import ExpandText from '@/components/editors/expandText'
import MkbCheckbox from '@/components/dict/mkb10/mkbCheckbox'

export default {
  name: 'MKBTable',
  components: { MkbCheckbox, ExpandText },
  data: () => ({
    search: '',
    searchText: '',
    selectedItems: [],
    openedItems: [],
    missedItems: '',
    mkbData: null,
    loading: false,
    dictRefresh: false,
  }),
  props: {
    separator: { type: String, default: ' / ' },
    value: { type: String, default: '' },
    showResult: { type: Boolean, default: false },
    hideAlert: { type: Boolean, default: false },
    hideInfo: { type: Boolean, default: false },
    singleSelect: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      'dictMKBData',
      'dictMKBDescription',
      'isAdmin',
      'isManager',
    ]),

    sortedLine() {
      return [...this.selectedItems]
        .sort((a, b) => a.code?.localeCompare(b.code))
        .reduce(
          (fs, { code }, i) => (i > 0 ? fs + this.separator + code : code),
          ''
        )
    },
  },
  watch: {
    value() {
      this.initMkbData()
    },
    search(newVal) {
      if (!newVal) this.searchNow()
      if (newVal) this.searchText = ''
    },
    separator() {
      if (this.value) this.initMkbData()
    },
  },
  async created() {
    this.loading = true
    try {
      await this.GET_MKB_DICT()
      this.initMkbData()
    } finally {
      this.loading = false
    }
  },
  methods: {
    ...mapActions(['GET_MKB_DICT', 'IMPORT_MKB_DICT']),

    async dictRefreshClick() {
      this.dictRefresh = true
      try {
        const message = await this.IMPORT_MKB_DICT()
        this.$toast(message)
      } finally {
        this.dictRefresh = false
      }
    },
    someSearch(item, search) {
      if (
        item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
        item.code.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      )
        return item
    },
    initMkbData() {
      const mkbData = copyObject(this.dictMKBData)
      const preSelected = []

      // Множество входных кодов с разделителем
      const valSet = new Set(
        this.value?.split(this.separator.trim()).map(v => v.trim()) ?? []
      )
      // берем данные из стора и добавляем в каждый элемент флаг selected и chs
      // chs - childrenSelected, флаг, что хотя бы один из детей выбран
      const treeInit = (items, forceSelect) => {
        let chs = false // никто не выбран в начале
        items?.forEach(child => {
          if (!child.root) {
            if (forceSelect) {
              // если принудительно все чекаем
              child.selected = forceSelect
            } else {
              // иначе смотрим, нет ли кода в списке
              child.selected = valSet.has(child.code)
              // и добавить его же в выбранные объекты
              if (child.selected) {
                preSelected.push(child)
                valSet.delete(child.code)
              }
            }
          }
          // пошли по детям
          child.chs = treeInit(child.children, child.selected)
          // у родители выбрал хотя бы 1 деть (где то в недрах)
          chs = chs || child.selected || child.chs
        })
        return chs
      }
      treeInit(mkbData) // рекурсия
      // обновили данные
      this.mkbData = mkbData
      this.selectedItems = preSelected

      this.missedItems = [...valSet.values()].reduce(
        (fs, code, i) => (i > 0 ? fs + this.separator + code : code),
        ''
      )
      // преоткрыть родителей для выбранных?
      const oSet = new Set()
      preSelected.forEach(el => {
        let p = this.getParent(el)
        while (p) {
          oSet.add(p.id)
          p = this.getParent(p)
        }
      })
      this.openedItems = [...oSet.values()]
    },
    // Функция для выбора единственного кода МКБ
    oneSelectChangeState(item) {
      if (item.selected) {
        this.clearTree()
        this.selectedItems = []
      } else {
        this.clearTree()
        if (item.children.length) {
          this.changeState(item, true, true)
        }
        this.selectedItems = [item]
        item.selected = true
      }
      this.changeStateUp(item)
    },
    // Очищаем дерево (всё что установлено)
    clearTree() {
      const items = this.mkbData.reduce((acc, item) => {
        if (item.root) {
          acc.push(...item.children)
        } else acc.push(item)
        return acc
      }, [])
      items.forEach(item => {
        if (item.chs || item.selected) {
          this.changeState(item, false)
        }
      })
    },
    searchTextKeyPress(event) {
      if (event.charCode === 13) {
        this.searchNow(this.search)
      }
    },
    searchNow(text = '') {
      this.searchText = text
      this.search = text
    },
    confirmSelection(item) {
      // меняем состояние галки рекурсивно вниз, вверх
      // добавляем или удаляем элементы - список selectedItems и эмитим их
      if (this.singleSelect) {
        this.oneSelectChangeState(item)
      } else {
        this.changeState(item, !item.selected)
      }
      this.$emit('input', this.sortedLine, this.selectedItems)
    },

    // изменяем галки (item.selected) рекурсивно вниз и вверх
    changeState(item, state, flag = false) {
      item.selected = state // ставим галку или снимаем галку
      if (!state) item.chs = false
      // рекурсивный вызов вниз!
      item.children?.forEach(elem => {
        this.changeState(elem, state, true)
      })
      // сюда придем много раз, когда выполним код выше
      if (!flag) {
        this.changeSelectedItems(item, state, false)
        this.changeStateUp(item) // рекурсивный вызов вверх
      }
    },

    changeStateUp(item) {
      // снимаем галку или ставим у родителя рекуривно вверх
      let parent = this.getParent(item)
      while (parent) {
        // детки
        const ch = parent.children ?? []
        // ставим метки только если это не корневой элемент
        if (!parent.root) {
          // родитель выбран если выбраны все дети
          parent.selected = ch.findIndex(el => !el.selected) < 0
          // у родители выбрал хотя бы 1 деть
          parent.chs = ch.findIndex(el => el.selected || el.chs) > -1
          // отмечаем родителя - не рекурсивно!
          this.changeSelectedItems(parent, parent.selected, true)
        }
        // обходим детей, особенно рута
        ch.forEach(child => {
          if (child.selected) this.changeSelectedItems(child, true, false)
        })
        parent = this.getParent(parent)
      }
    },

    changeSelectedItems(item, state, up = false) {
      // или добавляем элементы в список, или удаляем их оттуда
      const index = this.selectedItems.indexOf(item)
      if (state) {
        if (index === -1) this.selectedItems.push(item)
      } else {
        if (index > -1) this.selectedItems.splice(index, 1)
      }

      if (!up && item.chs) {
        item.children.forEach(child => {
          this.changeSelectedItems(child, false, false)
        })
      }
    },

    getParent(item) {
      // находим родителя данного элемента по пути - item.path - в виде типа 1.2.3.4.
      const pathArray = item.path.split('.')
      if (pathArray.length <= 2) return null

      let parent = { children: this.mkbData }
      for (let i = 0; i < pathArray.length - 2; i++) {
        parent = parent.children.find(({ id }) => id == pathArray[i])
        if (!parent) return null
      }
      return parent
    },
  },
}
</script>
