<template>
  <CompareLayout v-bind="$attrs" :class="{ [`mb-2`]: !dense }">
    <!-- Данные пользователя == 1 == -->
    <v-form
      ref="user1"
      class="d-flex flex-column"
      :readonly="canEdit && !editable"
      :disabled="!canEdit && !editable"
    >
      <slot
        v-if="value_1_exists"
        :tag="tag"
        :position="1"
        :isLeft="true"
        :value="value_1"
        :data="data_1"
        :change="setValueFake"
        :dataResult="data"
        :changeResult="setValueFake"
      />
      <CompareRowInfo
        v-if="!hideUseBtn && data_1 && canEdit && value_1_exists"
        :disable="!canEdit || isSame_1"
        :same="isSame_1"
        :confirmation="!isSame_1 && !isSame_2"
        class="mr-2 mt-auto"
        @click="setV(1)"
        :append="isArray"
        :hide-use="hideUse"
        @append="appendV(1)"
      />
    </v-form>

    <!-- Данные резульата -->
    <v-form ref="result" class="d-flex flex-column" :disabled="!canEdit">
      <div v-if="!canEdit && !hideUseBtn" class="text-center">
        <SameIcon :same="isSame_1" style="top: 0px !important" />
      </div>
      <slot
        v-else-if="value_exists"
        :tag="tag"
        :position="0"
        :isResult="true"
        :value="value"
        :data="data"
        :dataResult="data"
        :change="setValue"
        :deleteItem="deleteV"
        :dataGlobal="compare_data"
        :changeGlobal="setGlodal"
        :changeResult="setValue"
      />
      <CompareRowInfo
        v-if="!hideUseBtn && canEdit && value_exists"
        empty
        :disable="!canEdit || !isArray || !data || data.length < 1"
        class="ml-2 mt-auto"
        :remove="remove"
        @remove="deleteV()"
      />
    </v-form>

    <!-- Данные пользователя == 2 == -->
    <v-form
      ref="user2"
      class="d-flex flex-column"
      :readonly="canEdit && !editable"
      :disabled="!canEdit && !editable"
    >
      <slot
        v-if="value_2_exists"
        :tag="tag"
        :position="2"
        :isRight="true"
        :value="value_2"
        :data="data_2"
        :change="setValueFake"
        :dataResult="data"
        :changeResult="setValueFake"
      />
      <CompareRowInfo
        v-if="!hideUseBtn && data_2 && canEdit && value_2_exists"
        :disable="!canEdit || isSame_2"
        :same="isSame_2"
        :confirmation="!isSame_1 && !isSame_2"
        right
        class="ml-2 mt-auto"
        @click="setV(2)"
        :append="isArray"
        :hide-use="hideUse"
        @append="appendV(2)"
      />
    </v-form>
  </CompareLayout>
</template>

<script>
import { sameObject, objectByPath } from '@/lib/objects'
import CompareLayout from '@/components/compare/CompareLayout'
import CompareRowInfo from '@/components/compare/CompareRowInfo'
import SameIcon from '@/components/compare/SameIcon'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Compare-Row',
  inheritAttrs: false,
  components: {
    CompareLayout,
    CompareRowInfo,
    SameIcon,
  },
  props: {
    /** путь к объекту по умолчанию пусто */
    root: { type: String, default: null },
    /** индекс в массиве root, если массив то выбор по позиции */
    rootIndex: { type: [String, Array], default: null },
    /** под-путь к объекту после root[root-index] по умолчанию пусто */
    rootSub: { type: String, default: null },
    /** индекс в массиве rootSub, если массив то выбор по позиции */
    rootSubIndex: { type: [String, Array], default: null },
    /** ключ в объекте */
    tag: { type: String, default: null },
    /** спрятать кнопки сравнения */
    hideUseBtn: { type: Boolean, default: false },
    /** если установлено поле не будет блокироваться readonly = false */
    editable: { type: Boolean, default: false },
    /** если установлено поле то будет возможность добавлять элементы массива */
    append: { type: Boolean, default: false },
    /** если установлено поле то будет возможность удалять элементы массива */
    remove: { type: Boolean, default: false },
    /** прятать кнопки "использовать" */
    hideUse: { type: Boolean, default: false },
    /** узкий стиль */
    dense: { type: Boolean, default: false },
    /** функция доп.обновления */
    changeUpdate: { type: Function || null },
  },

  data: () => ({}),
  computed: {
    ...mapGetters([
      'compare_expertise_0_Data',
      'compare_expertise_1_Data',
      'compare_data_ready',
      'compare_data',
    ]),
    canEdit() {
      return this.compare_data_ready
    },
    isArray() {
      return (
        (this.append &&
          this.root &&
          this.rootIndex &&
          Array.isArray(objectByPath(this.compare_data, this.root))) ||
        (this.rootSub &&
          this.rootSubIndex &&
          Array.isArray(objectByPath(this.compare_data, this.halfRoot(0))))
      )
    },
    data() {
      return objectByPath(this.compare_data, this.fullRoot(0))
    },
    data_1() {
      return objectByPath(this.compare_expertise_0_Data, this.fullRoot(1))
    },
    data_2() {
      return objectByPath(this.compare_expertise_1_Data, this.fullRoot(2))
    },
    value() {
      return this.tag ? this.data?.[this.tag] : this.data
    },
    value_1() {
      return this.tag ? this.data_1?.[this.tag] : this.data_1
    },
    value_2() {
      return this.tag ? this.data_2?.[this.tag] : this.data_2
    },
    value_exists() {
      return this.tag ? this.tag in (this.data || {}) : true
    },
    value_1_exists() {
      return this.tag ? this.tag in (this.data_1 || {}) : true
    },
    value_2_exists() {
      return this.tag ? this.tag in (this.data_2 || {}) : true
    },
    isSame_1() {
      return sameObject(
        this.compare_data_ready ? this.value : this.value_2,
        this.value_1
      )
    },
    isSame_2() {
      return sameObject(
        this.compare_data_ready ? this.value : this.value_1,
        this.value_2
      )
    },
  },
  watch: {
    compare_data() {
      this.$nextTick().then(() => this.$refs.result?.validate())
    },
    compare_expertise_0_Data() {
      this.$nextTick().then(() => this.$refs.user1?.validate())
    },
    compare_expertise_1_Data() {
      this.$nextTick().then(() => this.$refs.user2?.validate())
    },
  },
  mounted() {
    this.$nextTick().then(() => this.$refs.result?.validate())
  },
  methods: {
    ...mapMutations(['COMPARE_PART_DATA']),

    halfRoot(position) {
      const index = (
        Array.isArray(this.rootIndex)
          ? this.rootIndex[position] ?? -1
          : this.rootIndex
      )?.toString()

      const root = `${this.root ?? ''}${index ? `[${index}]` : ''}`
      return root || undefined
    },

    // достаём весть объект по пути root[root-index]
    fullRoot(position) {
      const index = (
        Array.isArray(this.rootSubIndex)
          ? this.rootSubIndex[position] ?? -1
          : this.rootSubIndex
      )?.toString()
      const preRoot = this.halfRoot(position) || ''

      const path = `${preRoot}${this.rootSub ? '.' + this.rootSub : ''}${
        index ? `[${index}]` : ''
      }`

      return path || undefined
    },

    setGlodal(value) {
      this.COMPARE_PART_DATA({ value })
      this.$refs.result?.validate()
      this.$emit('change')
    },
    setV(i) {
      // данные слева или справа
      if (this.canEdit && [1, 2].includes(i)) {
        // установить в рут результата
        const root = this.fullRoot(0)
        const tag = this.tag
        const value = this[`value_${i}`]
        this.COMPARE_PART_DATA({ root, tag, value })
        this.$emit('change')
        this.$emit('set-value', i, this[`data_${i}`])
        this.doChangeUpdate(root)
      }
    },
    appendV(i) {
      // если есть объект массив попробуем добавить
      if (this.canEdit && this.isArray && [1, 2].includes(i)) {
        // установить в рут результата
        const root = this.root
        const newItem = this[`value_${i}`]
        const arr = objectByPath(this.compare_data, root)
        const value = [...arr, newItem]
        this.COMPARE_PART_DATA({ root, value })
        this.$emit('append', newItem, value.length - 1)
        this.$emit('change')
      }
    },
    deleteV() {
      // если есть объект массив попробуем добавить
      if (this.canEdit && this.isArray) {
        // установить в рут результата
        const root = this.root
        const value = [...objectByPath(this.compare_data, this.root)]
        const index = Array.isArray(this.rootIndex)
          ? this.rootIndex[0]
          : this.rootIndex
        const outValue = value.splice(index, 1)

        this.COMPARE_PART_DATA({ root, value })
        this.$emit('remove', outValue, index, value.length)
        this.$emit('change')
      }
    },
    setValue(value, tag2 = undefined, value2 = undefined) {
      if (this.canEdit) {
        // установить в рут результата
        const root = this.fullRoot(0)
        const tag = this.tag
        this.COMPARE_PART_DATA({ root, tag, value, tag2, value2 })
        this.$emit('change')
        this.doChangeUpdate(root)
      }
      return value
    },
    doChangeUpdate(rootDef) {
      if (this.changeUpdate) {
        const { root, tag, value } = this.changeUpdate()
        this.COMPARE_PART_DATA({ root: root || rootDef, tag, value })
      }
    },
    setValueFake(value) {
      // Do nothing is fake
      return value
    },
  },
}
</script>
