<template>
  <VersionComparator v-slot="{ data_ready }">
    <CompareKA
      :dataReady="data_ready"
      :kei="compare_ex_type_sub === 'kei'"
    ></CompareKA>
  </VersionComparator>
</template>

<script>
import VersionComparator from '@/components/compare/VersionComparator'
import CompareKA from '@/components/expAvbKei/CompareKA'
import { mapGetters } from 'vuex'

export default {
  components: { VersionComparator, CompareKA },
  data: () => ({}),
  computed: {
    ...mapGetters(['compare_ex_type_sub']),
  },
}
</script>
