<template>
  <v-dialog fullscreen :value="true" persistent>
    <v-card>
      <v-card-title>Выбрать МКБ коды</v-card-title>
      <v-card-text>
        <MKBTable
          :single-select="singleSelect"
          show-result
          @input="getSelectedCodes"
          :value="codesString"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="confirmCodes"
          color="primary"
          class="mr-4"
          :disabled="!dictMKBData.length || !changed"
          >Подтвердить выбор</v-btn
        >
        <v-btn @click="closeMkbTree" class="mr-4">Отменить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import MKBTable from '@/components/dict/mkb10/MKBTable'
import { mapGetters } from 'vuex'
export default {
  name: 'MkbTreeSelectDialog',
  components: { MKBTable },
  props: {
    // входная строка с кодами для простановки галок выбора в дереве
    codesString: {
      type: String,
      default: '',
    },
    singleSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedCodes: '',
      selectedCodesWithNames: '',
    }
  },
  computed: {
    ...mapGetters(['dictMKBData']),
    // если мы ничего не изменили, то кнопку подтвердить блокируем
    changed() {
      return this.selectedCodes !== this.codesString
    },
  },
  created() {
    this.init()
  },
  watch: {
    codesString: 'init',
  },
  methods: {
    init() {
      this.selectedCodes = this.codesString
      this.selectedCodesWithNames = ''
    },
    // закрывает дерево без выбора
    closeMkbTree() {
      this.$emit('close-mkb-tree')
    },
    // закрывает дерево, выбрав коды, эмитит коды + коды с расшифровкой
    confirmCodes() {
      this.$emit(
        'confirm-selection',
        this.selectedCodes,
        this.selectedCodesWithNames
      )
    },
    // принимает эмит из дерева, 2 параметра - строку с кодами и список выбранных объектов
    getSelectedCodes(sortedLine, selectedItems) {
      this.selectedCodes = sortedLine
      this.selectedCodesWithNames = this.getItemsWithNames(selectedItems)
    },
    // собирает из списка выбранных объектов строку с коды+расшифровка, чисто для отображения в форме
    getItemsWithNames(selectedItems = []) {
      return selectedItems
        .sort((a, b) => a.code?.localeCompare(b.code))
        .reduce((result, item) => `${result}\n${item.code} ${item.name}`, '')
        .trim()
    },
  },
}
</script>

<style scoped>
.v-card {
  display: flex !important;
  flex-direction: column;
}
.v-card__text {
  flex-grow: 1;
  overflow: auto;
  height: 250px;
  overflow-y: scroll;
}
</style>
