<template>
  <VersionComparator v-slot="{ data_ready }">
    <CompareAnalogList :dataReady="data_ready" />
  </VersionComparator>
</template>

<script>
import VersionComparator from '@/components/compare/VersionComparator'
import CompareAnalogList from '@/components/expAnalogs/CompareAnalogList'

export default {
  components: { VersionComparator, CompareAnalogList },
  data: () => ({}),
}
</script>
