<template>
  <CompareLayout class="mt-2">
    <div>&nbsp;</div>
    <div class="text-center">
      <btn-with-confirm
        color="primary"
        iconMain="mdi-check-bold"
        left
        v-bind="$attrs"
        v-on="$listeners"
        title="Сохранённая версия будет доступна экспертам и именно финальная сводная версия пойдёт в заключение"
        >Сохранить сводную версию</btn-with-confirm
      >
    </div>
    <div>&nbsp;</div>
  </CompareLayout>
</template>

<script>
import CompareLayout from '@/components/compare/CompareLayout'
import btnWithConfirm from '@/components/buttons/btnWithConfirm'

export default {
  inheritAttrs: false,
  components: { CompareLayout, btnWithConfirm },
}
</script>
