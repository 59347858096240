<template>
  <div class="d-flex compare-layout justify-center" ref="compareLayout">
    <slot />
  </div>
</template>

<script>
export default {
  watch: {
    columnWidths() {
      this.resetWidth()
    },
  },
  computed: {
    columnWidths() {
      const range = this.$store.state.compareColumnWidth
      return [range[0], range[1], range[2]]
    },
  },
  methods: {
    resetWidth() {
      const children = this.$refs?.compareLayout?.children
      if (!children) return

      for (let i = 0; i < 3; i++) {
        children[i].style.width = this.columnWidths[i] + '%'
      }
    },
  },
  mounted() {
    this.resetWidth()
  },
}
</script>

<style>
.compare-layout > :first-child {
  padding: 4px !important;
  margin-right: 12px !important;
}
.compare-layout > :nth-child(2) {
  padding: 4px !important;
}
.compare-layout > :last-child {
  padding: 4px !important;
  margin-left: 12px !important;
}
</style>
