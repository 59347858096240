<template>
  <v-container v-if="tagsExist || lostTagsExist" fluid>
    <v-row>
      <v-col v-for="({ tag, values }, index) in data" :key="index" :md="md">
        <CriterionTagSelector
          v-bind="$attrs"
          :tag="tag"
          :values="values"
          :clearable="clearable"
          @change="val => change(index, val)"
          :hide-title="hideTitle"
        />
      </v-col>
    </v-row>
    <v-row v-if="lostTagsExist" class="error--text text-caption">
      <v-col>
        <!-- Обработка потеряных тегов -->
        Внимание потерянные теги:
        <span v-for="(tag, index) in lostTags" :key="index"
          ><span class="font-weight-bold">{{ tag[0] }}</span
          >: {{ tag[1] }}<span v-if="index < lostTags.length - 1">; </span>
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//import { copyObject } from '@/lib/objects'
import CriterionTagSelector from './CriterionTagSelector.vue'

export default {
  inheritAttrs: false,
  name: 'CriterionTags',
  components: { CriterionTagSelector },
  props: {
    value: {
      type: Array || null,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    md: {
      type: Number,
      default: 12,
    },
    tags: {
      type: Array || null,
      default: () => [],
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    data: [],
    lostTags: [],
  }),
  created() {
    this.init(this.value)
  },
  watch: {
    value(val) {
      this.init(val)
    },
    tags() {
      this.init(this.value)
    },
  },
  computed: {
    tagsExist() {
      return !!this.tags?.length
    },
    lostTagsExist() {
      return !!this.lostTags?.length
    },
  },
  methods: {
    init(tagsValues) {
      // разбераем данные для компонентов далее
      const tvs = new Map()
      tagsValues?.forEach(({ name, values }) => tvs.set(name, values))

      const data = []
      // выбираем только ответы для тегов
      this.tags?.forEach(tag => {
        const values = tvs.get(tag.name) ?? null
        data.push({ tag, values })
        // что бы найти потеряшки удаляем из Map найденные
        tvs.delete(tag.name)
      })
      this.data = data
      this.lostTags = [...tvs.entries()]
    },
    change(index, values) {
      this.data[index].values = values

      const newData = this.data.map(item => ({
        name: item.tag.name,
        values: item.values,
      }))
      this.$emit('change', newData)
    },
  },
}
</script>
