// ОЦЕНКА ЭКСПЕРТНОЙ ОРГАНИЗАЦИЙ
import {
  SUB_EX_ASSESSMENT,
  SUB_VERSION_MIGRATION,
} from '@/store/const/expertise'

export const EX_ASSESSMENT_TEMPLATE_VERSION = 1

export function averageValue(arr) {
  const sum = arr.reduce((acc, current) => {
    return Number(acc) + Number(current.resultScore)
  }, 0)
  return Math.round(sum / arr.length) || 0
}
export function maxValue(arr) {
  return Math.max(...arr.map(item => item.resultScore)) || 0
}

export const QUALITY = 'quality'
export const SAFETY = 'safety'
export const ECONOMIC = 'economic'

export const DEFAULT_QUALITY = () => ({
  nameKi: '', //название КИ
  evidencesScore: 0, //оценка доказательности
  convictionScore: 0, //оценка убедительности
  resultScore: 0,
})

export const DEFAULT_SAFETY = () => ({
  sideEffect: '',
  pointsScore: 0,
  weightFactor: 0,
  resultScore: 0,
})

export const DEFAULT_ECONOMIC_INDICATOR = () => ({
  indicatorLP: 0,
  indicatorLPCompare: 0,
  percentDeviation: 0,
})

export const DEFAULT_ECONOMIC = () => ({
  condition: '',
  scoreKei: 0,
  scoreAvb: 0,
  keiCount: 1, //Количетсво сценариев кэи
  avbCount: 1, //количетсво сценариев авб
  indicatorsAvb: [DEFAULT_ECONOMIC_INDICATOR()], //Показатели для АВБ
  indicatorsKei: [DEFAULT_ECONOMIC_INDICATOR()], //Показатели для КЭИ
  resultScore: 0,
})

export const ASSESSMENT_ORGANIZATION_DEFAULT = () => ({
  economic: [DEFAULT_ECONOMIC()],
  maxValue: 0,
  //Оценка экономических последствий
})

// Установили текущую версию данных
SUB_VERSION_MIGRATION[SUB_EX_ASSESSMENT].v = EX_ASSESSMENT_TEMPLATE_VERSION
