<template>
  <div>
    <v-progress-linear v-if="compare_loading" indeterminate />
    <CompareWidthSelector class="mb-6" />
    <CompareHeader @set-version="COMPARE_DATA" :disabled="mergering" />
    <IntersectionDiv rm="50" />

    <v-alert
      v-if="
        !compare_loading &&
        !compare_loading_0 &&
        !compare_loading_1 &&
        !canCompare
      "
      type="warning"
      outlined
      class="ma-5 mx-8"
      >ВНИМАНИЕ!!! Нет начатых экспертиз для проведения сравнения</v-alert
    >

    <v-slide-y-reverse-transition
      v-if="!compare_loading_0 && !compare_loading_1"
    >
      <compare-row
        v-if="!compare_data_ready"
        class="text-center mt-3"
        hideUseBtn
        v-slot="{ position, data }"
      >
        <btn-with-confirm
          v-if="position && is_completed_by(position)"
          outlined
          class="mx-7"
          left
          iconMain="mdi-arrow-up-left-bold"
          :disabled="reworking || !data"
          color="secondary"
          title="Отправить экспертизу на доработку эксперту"
          @click="sendToRework(position)"
          >Отправить на доработку
          <v-icon right>mdi-head-sync-outline</v-icon></btn-with-confirm
        >
      </compare-row>
    </v-slide-y-reverse-transition>

    <v-fade-transition v-if="!compare_loading_0 && !compare_loading_1">
      <v-alert
        v-if="canCompare && !compare_data_ready && !mergeringDone"
        type="info"
        outlined
        class="ma-5 mx-8"
        dismissible
        >Чтобы начать сравнение выбирите за основу один из вариантов!</v-alert
      >
    </v-fade-transition>

    <v-slide-y-reverse-transition
      v-if="!compare_loading_0 && !compare_loading_1"
    >
      <compare-row
        v-if="canCompare && !compare_data_ready && !mergeringDone"
        class="text-center mt-3"
        hideUseBtn
        v-slot="{ data, isResult, isLeft, isRight }"
      >
        <v-btn
          v-if="!isResult"
          color="primary"
          outlined
          :disabled="!data"
          @click="COMPARE_DATA(data)"
          ><v-icon v-if="isLeft" left>mdi-arrow-down-left-bold</v-icon>Принять
          за основу<v-icon v-if="isRight" right
            >mdi-arrow-down-right-bold</v-icon
          > </v-btn
        ><SameIcon v-else :same="is_same" large />
      </compare-row>
    </v-slide-y-reverse-transition>
    <v-slide-y-transition>
      <v-alert
        v-if="mergeringDone"
        type="info"
        outlined
        class="ma-5 mx-10"
        dismissible
      >
        Сводная версия сохранена<span v-if="!is_completed"
          >, и отправлена на доработку</span
        ><span v-else>, доработка не требуется</span>!
        <template #append
          ><btn-with-confirm
            v-if="is_completed"
            class="mr-5"
            left
            text
            iconMain="mdi-arrow-up-left-bold"
            :disabled="reworking"
            color="primary"
            title="Отправить экспертизу на доработку всем участникам"
            @click="sendToRework()"
            >Отправить на доработку всем</btn-with-confirm
          >
          <btn-with-confirm
            v-else
            class="mr-5"
            left
            text
            iconMain="mdi-check-bold"
            :disabled="reworking"
            color="success"
            title="Закрыть экспертизу, доработка участниками будет невозможна"
            @click="forceComplete"
            >Закрыть экспертизу всем</btn-with-confirm
          >
        </template>
      </v-alert>
    </v-slide-y-transition>
    <!-- Содержимое экспертизы -->
    <slot v-if="canCompare" :data_ready="compare_data_ready" />

    <v-divider v-if="canCompare" class="mt-2" />
    <!-- кнопка Сохранить -->
    <CompareCenterButton
      v-if="canCompare && compare_data_ready && !mergeringDone"
      :loading="mergering"
      @click="sendMergedVersion"
    />
    <!-- Завершение сравнения экспертиз или переход к следующей  -->
    <div
      v-if="canCompare && !compare_data_ready && mergeringDone"
      class="ma-3 text-end"
    >
      <v-btn
        v-if="next_route"
        :to="{ name: next_route.name }"
        :title="next_route.title"
        color="accent"
        >{{ next_route.label }}<v-icon right>mdi-chevron-right</v-icon>
      </v-btn>
      <btn-with-confirm
        v-else-if="compare_expertise_checked"
        color="success"
        iconMain="mdi-vector-combine"
        left
        :loading="checking"
        title="Возобновить сравниие версий экспертизу"
        @click="markAsChecked"
        >Возобновить сравнение</btn-with-confirm
      ><btn-with-confirm
        v-else
        color="primary"
        iconMain="mdi-check-bold"
        left
        :loading="checking"
        title="Завершить сравниие версий и закрыть экспертизу"
        @click="markAsChecked"
        >Завершить сравнение</btn-with-confirm
      >
    </div>
  </div>
</template>

<script>
import { sameObject } from '@/lib/objects'
import CompareWidthSelector from './CompareWidthSelector'
import CompareHeader from './CompareHeader'
import CompareRow from './CompareRow'
import CompareCenterButton from './CompareCenterButton'
import SameIcon from './SameIcon'
import IntersectionDiv from '@/components/overlay/IntersectionDiv'
import btnWithConfirm from '@/components/buttons/btnWithConfirm'

import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Version-Comparator',
  components: {
    CompareWidthSelector,
    CompareHeader,
    CompareRow,
    CompareCenterButton,
    IntersectionDiv,
    btnWithConfirm,
    SameIcon,
  },

  data() {
    return {
      mergering: false,
      reworking: false,
      checking: false,
    }
  },
  computed: {
    ...mapGetters([
      'compare_loading',
      'compare_error',
      'compare_data_ready',
      'compare_loading_0',
      'compare_task_0',
      'compare_expertise_0',
      'compare_expertise_0_Data',
      'compare_loading_1',
      'compare_task_1',
      'compare_expertise_1',
      'compare_expertise_1_Data',
      'compare_expertise_checked',
    ]),
    mergeringDone() {
      return (
        (!this.compare_expertise_0 || !!this.compare_expertise_0?.is_merged) &&
        (!this.compare_expertise_1 || !!this.compare_expertise_1?.is_merged) &&
        !this.compare_data_ready
      )
    },
    canCompare() {
      return !!this.compare_expertise_0_Data || !!this.compare_expertise_1_Data
    },
    is_completed() {
      const task0 = this.compare_task_0
      const task1 = this.compare_task_1

      return (
        (!task0?.user_id || task0?.is_completed) &&
        (!task1?.user_id || task1?.is_completed)
      )
    },
    next_route() {
      return this.$route.meta?.next ?? null
    },
    is_same() {
      return sameObject(
        this.compare_expertise_0_Data,
        this.compare_expertise_1_Data
      )
    },
  },
  methods: {
    ...mapActions([
      'COMPARE_SET_EXPERTISE_VERSION',
      'COMPARE_SET_MERGED_VERSION',
      'COMPARE_SEND_TO_REWORK',
      'COMPARE_FORCE_COMPLETED',
      'COMPARE_MARK_AS_CHECKED',
    ]),
    ...mapMutations(['COMPARE_DATA']),
    changeVersion(index, uuid) {
      this.COMPARE_SET_EXPERTISE_VERSION({ index, uuid })
    },
    async sendMergedVersion() {
      this.mergering = true
      try {
        await this.COMPARE_SET_MERGED_VERSION()
      } finally {
        this.mergering = false
      }
    },
    is_completed_by(position) {
      const task = this[`compare_task_${position - 1}`]

      return task?.user_id && task?.is_completed
    },
    async sendToRework(position) {
      this.reworking = true
      try {
        await this.COMPARE_SEND_TO_REWORK(position)
      } finally {
        this.reworking = false
      }
    },
    async forceComplete() {
      this.reworking = true
      try {
        await this.COMPARE_FORCE_COMPLETED()
      } finally {
        this.reworking = false
      }
    },
    async markAsChecked() {
      this.checking = true
      try {
        await this.COMPARE_MARK_AS_CHECKED(!this.compare_expertise_checked)
      } finally {
        this.checking = false
      }
    },
  },
}
</script>

<style>
.active-filter {
  background-color: lightgreen;
}
</style>
