<template>
  <v-icon
    v-if="same"
    v-bind="$attrs"
    v-on="$listeners"
    color="success"
    v-text="`mdi-equal`"
    title="Значения равны"
  /><v-icon
    v-else
    v-bind="$attrs"
    color="warning"
    v-text="`mdi-not-equal-variant`"
    title="Значения не равны"
  />
</template>

<script>
export default {
  name: 'Same-Icon',
  inheritAttrs: false,
  props: {
    same: {
      type: Boolean,
      require: true,
    },
  },
}
</script>
