var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_vm._l((_vm.other),function(el,index){return _c('v-card',{key:el.id,staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(_vm._s(index + 1)+". "+_vm._s(el.text))]),_c('v-card-subtitle',{staticClass:"red-line"},[_vm._v(_vm._s(_vm._f("capitalize")(el.commentary))+" "),_c('span',{staticClass:"font-italic text--disabled cursor-help"},[_vm._v("( "+_vm._s(_vm._f("ru_ending")(el.weight,['балл', 'балла', 'баллов']))+" )")])]),_c('v-card-text',{staticClass:"pb-0"},[_c('CompareRow',{attrs:{"root":"other","tag":index.toString()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var change = ref.change;
return [(value)?_c('v-checkbox',{staticClass:"my-0 align-self-center",attrs:{"input-value":value.state,"title":("Добавляет баллов: " + (el.weight))},on:{"change":function (state) { return _vm.calcResult(change, value, state, index); }}}):_vm._e()]}}],null,true)})],1)],1)}),_c('v-card',{staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("СУММА БАЛЛОВ")]),_c('v-card-text',{staticClass:"pb-0"},[_c('CompareRow',{attrs:{"hide-use-btn":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var change = ref.change;
var data = ref.data;
return [(value && _vm.changeSum(data, change))?_c('v-chip',{staticClass:"text-h5 px-5 text--primary align-self-center"},[_vm._v(" "+_vm._s(value.sum)+" ")]):_vm._e()]}}])})],1)],1),_c('HelpCard',{staticClass:"mt-2",attrs:{"outlined":"","open":""}},[_c('PlpFilesList',{attrs:{"plp-key":"epidData","text":"5.1 Эпидемиологические данные"}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }