<template>
  <div>
    <div class="text-end">
      <span class="tip pointer" @click="changeOpen">{{ openText }}</span>
    </div>
    <v-expand-transition>
      <div v-show="isOpen">
        <v-range-slider
          :value="range"
          @input="setCompareColumnWidth"
          hide-details
          max="100"
          min="1"
          class="mb-2"
        ></v-range-slider>
        <v-btn-toggle
          dense
          :value="buttonToggle"
          class="mb-6"
          :mandatory="mandatory"
        >
          <v-btn
            v-for="r in preparedRanges"
            :key="r.text"
            @click="r.clickHandler"
            class="px-6"
            style="min-width: 240px"
          >
            {{ r.text }}
          </v-btn>
        </v-btn-toggle>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  data() {
    return {
      isOpen: false,
      ranges: [
        { text: 'Одинаковые колонки', range: [33, 33, 33] },
        { text: 'Широкая слева', range: [50, 25, 25] },
        { text: 'Широкая сводная', range: [25, 50, 25] },
        { text: 'Широкая справа', range: [25, 25, 50] },
      ],
    }
  },
  computed: {
    buttonToggle() {
      return this.ranges.findIndex(
        r =>
          this.range[0] === r.range[0] &&
          this.range[1] === r.range[0] + r.range[1]
      )
    },
    mandatory() {
      return !(this.buttonToggle === -1)
    },
    preparedRanges() {
      return this.ranges.map(r => ({
        text: r.text,
        clickHandler: () => this.setCompareColumnWidth(r.range),
      }))
    },
    range() {
      const range = this.$store.state.compareColumnWidth
      return [range[0], range[0] + range[1]]
    },
    openText() {
      return this.isOpen
        ? 'Скрыть настройку ширины'
        : 'Настроить ширину колонок'
    },
  },
  methods: {
    ...mapMutations(['setCompareColumnWidth']),
    changeOpen() {
      this.isOpen = !this.isOpen
    },
  },
}
</script>
