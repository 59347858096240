var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"mt-2",attrs:{"shaped":"","outlined":""}},[_c('v-card-title',{staticClass:"pb-0 text-button"},[_vm._v("Выбор экспертизы")]),_c('v-card-text',[_c('CompareRow',{attrs:{"root":"ka","hideUseBtn":"","editable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var position = ref.position;
return [_c('v-select',{staticClass:"font-weight-bold",attrs:{"items":_vm.getItems(value),"item-text":"name","item-value":"index","hide-details":""},scopedSlots:_vm._u([(_vm.getItems(value).length > 1)?{key:"append-outer",fn:function(){return [_c('v-chip',{staticClass:"my-0",attrs:{"small":""}},[_vm._v(_vm._s(_vm.selectedData[position] + 1)+" из "+_vm._s(_vm.getItems(value).length))])]},proxy:true}:null],null,true),model:{value:(_vm.selectedData[position]),callback:function ($$v) {_vm.$set(_vm.selectedData, position, $$v)},expression:"selectedData[position]"}})]}}])}),_c('CompareRow',{attrs:{"root":"ka","root-index":_vm.selectedData,"append":"","remove":""},on:{"append":_vm.updateAppend,"remove":_vm.removeItem},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [(!value)?_c('v-alert',{attrs:{"type":"warning","border":"top","colored-border":"","elevation":"1"}},[_vm._v("Нет данных")]):_vm._e()]}}])}),_c('v-card',{attrs:{"shaped":"","outlined":""}},[_c('v-card-text',[_c('CompareRow',{attrs:{"root":"ka","root-index":_vm.selectedData,"tag":"shortName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var change = ref.change;
return [_c('v-text-field',{attrs:{"label":"Короткое название","value":value},on:{"change":change}})]}}])}),_c('CompareRow',{attrs:{"root":"ka","root-index":_vm.selectedData,"tag":"mkb10_codes"},on:{"set-value":function (i, data) { return _vm.copyMkbCodes(i, data); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var change = ref.change;
var isResult = ref.isResult;
return [_c('v-text-field',{attrs:{"label":"МКБ-10 (коды)","readonly":"","value":value},on:{"change":change},scopedSlots:_vm._u([(isResult)?{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.showMkbTree}},[_c('v-icon',[_vm._v(" mdi-store-search-outline ")])],1)]},proxy:true}:null],null,true)}),(isResult && _vm.mkbTree)?_c('MkbTreeSelectDialog',{attrs:{"codes-string":value},on:{"close-mkb-tree":_vm.closeMkbTree,"confirm-selection":function (codes, codesWithNames) { return _vm.confirmMkbCodes(codes, codesWithNames, change); }}}):_vm._e()]}}])}),_c('CompareRow',{attrs:{"root":"ka","root-index":_vm.selectedData,"tag":"mkb10","hide-use":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var value = ref.value;
                  var change = ref.change;
return [_c('AutoGrowTextEditor',{attrs:{"label":"МКБ-10","readonly":"","value":value},on:{"change":change,"input":change}})]}}])}),_c('CompareRow',{attrs:{"root":"ka","root-index":_vm.selectedData,"tag":"condition"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var value = ref.value;
                  var change = ref.change;
return [_c('AutoGrowTextEditor',{attrs:{"label":"Показание","value":value},on:{"change":change,"input":change}})]}}])}),_c('CompareRow',{attrs:{"root":"ka","root-index":_vm.selectedData,"tag":"qLevels","hide-use-btn":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var data = ref.data;
                  var change = ref.change;
return [(data)?_c('span',{staticClass:"text-body-1"},[_vm._v(" Методологическое качество: "),_c('strong',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.calcQLevels(data, change))))])]):_vm._e()]}}])})],1)],1)],1)],1),_c('v-tabs',{staticClass:"mt-2",attrs:{"show-arrows":"","center-active":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_vm._l((_vm.criteriaList),function(tabName,tabIndex){return _c('v-tab',{key:tabIndex,attrs:{"title":tabName.name}},[_c('span',{class:_vm.someClass(tabIndex)},[_vm._v(_vm._s(tabIndex + 1)+". "+_vm._s(tabName.name.split(' ')[0] || '')+"...")])])}),_vm._l((_vm.criteriaList),function(tabName,tabIndex){return _c('v-tab-item',{key:tabIndex},[_c('v-card',{staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"pb-0 text--secondary"},[_vm._v(_vm._s(tabIndex + 1)+". "+_vm._s(tabName.name))]),_c('v-card-text',[_c('div',{staticClass:"mb-0 mt-2 text-center text-button"},[_vm._v(" Методологическое качество ")]),_c('CompareRow',{attrs:{"root":"ka","root-index":_vm.selectedData,"root-sub":("elements[" + tabIndex + "]"),"tag":"correspond"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var value = ref.value;
                  var change = ref.change;
return [_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-text',[_c('v-radio-group',{staticClass:"mt-0 mb-4 pa-0",attrs:{"hide-details":"","row":"","dense":"","value":value},on:{"change":change}},[_c('v-radio',{attrs:{"color":"green","value":true,"label":_vm.EX_CORRESPOND}}),_c('v-radio',{attrs:{"color":"red","value":false,"label":_vm.EX_NOT_CORRESPOND}})],1)],1)],1)]}}],null,true)}),_c('v-divider'),_c('div',{staticClass:"mb-0 mt-2 text-center text-button"},[_vm._v("Обоснование")]),_c('CompareRow',{attrs:{"root":"ka","root-index":_vm.selectedData,"root-sub":("elements[" + tabIndex + "]"),"tag":"criteria"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var value = ref.value;
                  var change = ref.change;
                  var data = ref.data;
return [(data)?_c('AutoGrowTextEditor',{attrs:{"value":value,"rows":"10"},on:{"change":change,"input":change}}):_vm._e()]}}],null,true)}),_c('v-divider'),_c('div',{staticClass:"mb-0 mt-2 text-center text-button"},[_vm._v("Комментарий")]),_c('CompareRow',{attrs:{"root":"ka","root-index":_vm.selectedData,"root-sub":("elements[" + tabIndex + "]"),"tag":"comment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var value = ref.value;
                  var change = ref.change;
                  var data = ref.data;
return [(data)?_c('AutoGrowTextEditor',{attrs:{"value":value,"rows":"2"},on:{"change":change,"input":change}}):_vm._e()]}}],null,true)}),_c('v-divider'),_c('div',{staticClass:"mb-0 mt-2 text-center text-button"},[_vm._v("Тэги")]),_c('CompareRow',{staticClass:"mt-4",attrs:{"hide-use-btn":"","root":"ka","root-index":_vm.selectedData,"root-sub":("elements[" + tabIndex + "]"),"tag":"tagsSelected"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var value = ref.value;
                  var change = ref.change;
                  var isResult = ref.isResult;
return [(_vm.renderComponent && value)?_c('CriterionTags',{attrs:{"tags":_vm.getTags(tabIndex),"value":value,"clearable":isResult,"hide-title":"","flat":"","outlined":""},on:{"change":change}}):_vm._e()]}}],null,true)}),_c('CompareRow',{attrs:{"root":"ka","root-index":_vm.selectedData,"root-sub":("elements[" + tabIndex + "]"),"tag":"tagsSelected"},on:{"change":function($event){return _vm.changeIt()}}})],1)],1)],1)})],2),_c('v-card',{staticClass:"mt-2",attrs:{"outlined":"","shaped":""}},[_c('v-card-title',[_vm._v(" Информация для сводного заключения и представления об ЛП ")]),_c('v-card-text',[_c('CompareRow',{attrs:{"root":"ka","root-index":_vm.selectedData,"root-sub":"resume","tag":"scenario","hide-use":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var value = ref.value;
                  var data = ref.data;
return [(data)?_c('v-combobox',{staticClass:"mt-3 font-weight-bold",attrs:{"label":"Кол-во сценариев","value":value,"items":_vm.getResumeScenarioArr},on:{"change":function (v) { return _vm.doChangeScenario(v, data); }}}):_vm._e()]}}])}),_c('v-card',{staticClass:"mt-2",attrs:{"shaped":"","outlined":""}},[_c('v-card-title',{staticClass:"text-subtitle-2"},[_vm._v("Автор, год")]),_c('v-card-text',{staticClass:"pb-0"},[_c('CompareRow',{attrs:{"root":"ka","root-index":_vm.selectedData,"root-sub":"resume","tag":"author"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var value = ref.value;
                  var change = ref.change;
return [_c('v-text-field',{attrs:{"value":value,"placeholder":"ФИО автора, год"},on:{"change":change,"input":change}})]}}])})],1)],1),(!_vm.kei)?_c('v-card',{staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"text-subtitle-2"},[_vm._v("Численность целевой популяции")]),_c('v-card-text',{staticClass:"pb-0"},_vm._l((_vm.getScenarioMaxArray),function(idx){return _c('CompareRow',{key:idx,attrs:{"root":"ka","root-index":_vm.selectedData,"root-sub":"resume.epidemiology","tag":String(idx),"hide-use":idx >= _vm.getScenarioResult},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var value = ref.value;
                  var change = ref.change;
                  var data = ref.data;
return [(data)?_c('AutoGrowTextEditor',{attrs:{"label":_vm.getScenarioMaxCount > 1 ? ("Сценарий " + (idx + 1)) : '',"value":value},on:{"change":change,"input":change}}):_vm._e()]}}],null,true)})}),1)],1):_vm._e(),(_vm.kei)?_c('v-card',{staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"text-subtitle-2"},[_vm._v("Использованный метод")]),_c('v-card-text',{staticClass:"pb-0"},[_c('CompareRow',{attrs:{"root":"ka","root-index":_vm.selectedData,"root-sub":"resume","tag":"method"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var value = ref.value;
                  var change = ref.change;
return [_c('KeiMethodEditor',{staticClass:"mb-2",attrs:{"value":value,"readonly":""},on:{"change":change,"input":change}})]}}],null,false,2993656624)})],1)],1):_vm._e(),(!_vm.kei)?_c('v-card',{staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"text-subtitle-2"},[_vm._v("Текущая практика")]),_c('v-card-text',{staticClass:"pb-0"},_vm._l((_vm.getScenarioMaxArray),function(idx){return _c('CompareRow',{key:idx,attrs:{"root":"ka","root-index":_vm.selectedData,"root-sub":"resume.currentState","tag":String(idx),"hide-use":idx >= _vm.getScenarioResult},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var value = ref.value;
                  var change = ref.change;
                  var data = ref.data;
return [(data)?_c('AutoGrowTextEditor',{attrs:{"label":_vm.getScenarioMaxCount > 1 ? ("Сценарий " + (idx + 1)) : '',"value":value},on:{"change":change,"input":change}}):_vm._e()]}}],null,true)})}),1)],1):_vm._e(),(!_vm.kei)?_c('v-card',{staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"text-subtitle-2"},[_vm._v("Ожидаемая практика")]),_c('v-card-text',{staticClass:"pb-0"},_vm._l((_vm.getScenarioMaxArray),function(idx){return _c('CompareRow',{key:idx,attrs:{"root":"ka","root-index":_vm.selectedData,"root-sub":"resume.expectedState","tag":String(idx),"hide-use":idx >= _vm.getScenarioResult},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var value = ref.value;
                  var change = ref.change;
                  var data = ref.data;
return [(data)?_c('AutoGrowTextEditor',{attrs:{"label":_vm.getScenarioMaxCount > 1 ? ("Сценарий " + (idx + 1)) : '',"value":value},on:{"change":change,"input":change}}):_vm._e()]}}],null,true)})}),1)],1):_vm._e(),(_vm.kei)?_c('v-card',{staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"text-subtitle-2"},[_vm._v("Препарат сравнения")]),_c('v-card-text',{staticClass:"pb-0"},_vm._l((_vm.getScenarioMaxArray),function(idx){return _c('CompareRow',{key:idx,attrs:{"root":"ka","root-index":_vm.selectedData,"root-sub":"resume.analog","tag":String(idx),"hide-use":idx >= _vm.getScenarioResult},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var value = ref.value;
                  var change = ref.change;
                  var data = ref.data;
return [(data)?_c('AutoGrowTextEditor',{attrs:{"label":_vm.getScenarioMaxCount > 1 ? ("Сценарий " + (idx + 1)) : '',"rows":"1","value":value},on:{"change":change,"input":change}}):_vm._e()]}}],null,true)})}),1)],1):_vm._e(),(_vm.kei)?_c('v-card',{staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"text-subtitle-2"},[_vm._v(" Клинические преимущества ")]),_c('v-card-text',{staticClass:"pb-0"},_vm._l((_vm.getScenarioMaxArray),function(idx){return _c('CompareRow',{key:idx,attrs:{"root":"ka","root-index":_vm.selectedData,"root-sub":"resume.clinicalAdvantages","tag":String(idx),"hide-use":idx >= _vm.getScenarioResult},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var value = ref.value;
                  var change = ref.change;
                  var data = ref.data;
return [(data)?_c('AutoGrowTextEditor',{attrs:{"label":_vm.getScenarioMaxCount > 1 ? ("Сценарий " + (idx + 1)) : '',"value":value},on:{"change":change,"input":change}}):_vm._e()]}}],null,true)})}),1)],1):_vm._e(),(_vm.kei)?_c('v-card',{staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"text-subtitle-2"},[_vm._v("Экономические преимущества")]),_c('v-card-text',{staticClass:"pb-0"},_vm._l((_vm.getScenarioMaxArray),function(idx){return _c('CompareRow',{key:idx,attrs:{"root":"ka","root-index":_vm.selectedData,"root-sub":"resume.econAdvantages","tag":String(idx),"hide-use":idx >= _vm.getScenarioResult},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var value = ref.value;
                  var change = ref.change;
                  var data = ref.data;
return [(data)?_c('AutoGrowTextEditor',{attrs:{"label":_vm.getScenarioMaxCount > 1 ? ("Сценарий " + (idx + 1)) : '',"value":value},on:{"change":change,"input":change}}):_vm._e()]}}],null,true)})}),1)],1):_vm._e(),_c('v-card',{staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"text-subtitle-2"},[_vm._v("Основные результаты")]),_c('v-card-text',{staticClass:"pb-0"},_vm._l((_vm.getScenarioMaxArray),function(idx){return _c('CompareRow',{key:idx,attrs:{"root":"ka","root-index":_vm.selectedData,"root-sub":"resume.results","tag":String(idx),"hide-use":idx >= _vm.getScenarioResult},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var value = ref.value;
                  var change = ref.change;
                  var data = ref.data;
return [(data)?_c('AutoGrowTextEditor',{attrs:{"label":_vm.getScenarioMaxCount > 1 ? ("Сценарий " + (idx + 1)) : '',"value":value},on:{"change":change,"input":change}}):_vm._e()]}}],null,true)})}),1)],1),_c('v-card',{staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"text-subtitle-2"},[_vm._v("Комментарий (сводное заключение)")]),_c('v-card-text',{staticClass:"pb-0"},[_c('CompareRow',{attrs:{"root":"ka","root-index":_vm.selectedData,"root-sub":"resume","tag":"comment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var value = ref.value;
                  var change = ref.change;
return [_c('AutoGrowTextEditor',{attrs:{"value":value},on:{"change":change,"input":change}})]}}])})],1)],1)],1)],1),_c('v-card',{staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Заметки")]),_c('v-card-text',{staticClass:"pb-0"},[_c('CompareRow',{attrs:{"root":"ka","root-index":_vm.selectedData,"tag":"note"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var value = ref.value;
                  var change = ref.change;
                  var data = ref.data;
return [(data)?_c('AutoGrowTextEditor',{attrs:{"value":value},on:{"change":change,"input":change}}):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }