<template>
  <CompareLayout class="sticky">
    <!-- Пользователь слева == 0 == -->
    <v-card
      flat
      :disabled="!is_user_0_exist || !is_version_0_exist || disabled"
      class="d-flex flex-column"
    >
      <v-card-title class="text--secondary" :title="fio_0"
        >{{ fio_0 | shortFIO }}<v-spacer />
        <btn-with-confirm
          icon
          class="float-right"
          icon-main="mdi-content-copy"
          color="primary"
          icon-small
          title="Скопировать (взять за основу) всю версию слева ==>"
          @click="setV0"
        />
      </v-card-title>
      <v-card-subtitle
        v-if="!hasTitleGone"
        class="text-caption text--disabled text-start pt-2"
        >{{ position_0 }}</v-card-subtitle
      >
      <v-card-actions class="mt-auto">
        <VerExpSelect
          :value="compare_expertise_0_UUID"
          :items="compare_version_0_list"
          item-value="version_uuid"
          item-text="version_name"
          @change="uuid => changeVersion(0, uuid)"
          persistent-hint
          :hint="version_hint_0"
          :hide-details="hasTitleGone"
          :disabled="vl_loading_0 || compare_loading"
          :loading="compare_loading_0"
          :is_completed="is_completed_0"
          :is_checked="is_checked_0"
          :is_analog_completed="is_analog_completed_0"
        />
      </v-card-actions>
    </v-card>

    <!-- Сводная -->
    <v-card flat class="d-flex flex-column" :disabled="disabled">
      <v-card-title class="justify-center"
        ><v-spacer />Сводная версия<v-spacer />
        <btn-with-confirm
          :disabled="!compare_data_ready"
          icon
          icon-main="mdi-delete-forever-outline"
          class="float-right"
          title="Отчистить сводную (начать сначала)"
          @click="clearResult"
        />
      </v-card-title>
      <v-card-text>
        <ReferenceTip
          v-if="!hasTitleGone"
          label='Скопировать значение одного из элементов можно с помощью кнопки
        "использовать" под соответствующим элементом.'
        />
      </v-card-text>
      <v-card-actions class="mt-auto"
        ><v-alert
          v-if="!hasTitleGone && compare_local_save_date && !disabled"
          v-model="compare_local_save_date_alert"
          type="warning"
          colored-border
          border="bottom"
          dismissible
          dense
          class="ma-0"
          >Внимаение загружена обнаружена не сохранённая версия сравнения от
          {{ compare_local_save_date }}</v-alert
        >
      </v-card-actions>
    </v-card>

    <!-- Пользователь справа == 1 == -->
    <v-card
      flat
      :disabled="!is_user_1_exist || !is_version_1_exist || disabled"
      class="d-flex flex-column"
    >
      <v-card-title class="text--secondary" :title="fio_1"
        >{{ fio_1 | shortFIO }}<v-spacer />
        <btn-with-confirm
          icon
          class="float-right"
          icon-main="mdi-content-copy"
          color="primary"
          icon-small
          title="Скопировать (взять за основу) всю версию справа <=="
          @click="setV1"
        />
      </v-card-title>
      <v-card-subtitle
        v-if="!hasTitleGone"
        class="text-caption text--disabled text-start pt-2"
        >{{ position_1 }}</v-card-subtitle
      >
      <v-card-actions class="mt-auto">
        <VerExpSelect
          :value="compare_expertise_1_UUID"
          :items="compare_version_1_list"
          item-value="version_uuid"
          item-text="version_name"
          @change="uuid => changeVersion(1, uuid)"
          persistent-hint
          :hint="version_hint_1"
          :hide-details="hasTitleGone"
          :disabled="vl_loading_1 || compare_loading"
          :loading="compare_loading_1"
          :is_completed="is_completed_1"
          :is_checked="is_checked_1"
          :is_analog_completed="is_analog_completed_1"
        />
      </v-card-actions>
    </v-card>
  </CompareLayout>
</template>

<style scoped>
.sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 48px;
  display: flex;
  /*justify-content: center;*/
  align-items: stretch;
  z-index: 3;
  background-color: white;
  border-bottom: 2px solid #424242;
}

.v-card {
  margin-bottom: 1pt;
  padding: 0px;
}
.v-card__title {
  padding: 0px;
}
.v-card__subtitle {
  padding: 0px;
  padding-top: 8pt;
}
.v-card__text {
  padding: 0px;
}
.v-card__actions {
  padding: 0px;
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 2.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>

<script>
import CompareLayout from '@/components/compare/CompareLayout'
import ReferenceTip from '@/components/ReferenceTip'
import btnWithConfirm from '@/components/buttons/btnWithConfirm'
import VerExpSelect from '@/components/editors/VerExpSelect'

import { mapActions, mapGetters } from 'vuex'

export default {
  components: { CompareLayout, ReferenceTip, VerExpSelect, btnWithConfirm },
  props: {
    disabled: { type: Boolean, default: false },
  },
  data: () => ({
    compare_local_save_date_alert: true,
  }),
  watch: {
    hasTitleGone(value) {
      if (!value && this.compare_local_save_date_alert)
        this.compare_local_save_date_alert = false
    },
  },
  computed: {
    ...mapGetters([
      'hasTitleGone',
      'compare_loading',
      'compare_data_ready',
      'compare_local_save_date',
      // user - 0
      'compare_task_0',
      'compare_task_0_id',
      'compare_user_0',
      'compare_version_0_list',
      'compare_loading_0',
      'compare_expertise_0',
      'compare_expertise_0_UUID',
      'compare_expertise_0_Data',
      // user - 1
      'compare_task_1',
      'compare_task_1_id',
      'compare_user_1',
      'compare_version_1_list',
      'compare_loading_1',
      'compare_expertise_1',
      'compare_expertise_1_UUID',
      'compare_expertise_1_Data',
    ]),

    is_user_0_exist() {
      return !!this.compare_user_0
    },
    is_user_1_exist() {
      return !!this.compare_user_1
    },

    fio_0() {
      return this.compare_user_0?.fio ?? 'не назначен'
    },
    fio_1() {
      return this.compare_user_1?.fio ?? 'не назначен'
    },

    position_0() {
      return this.compare_user_0?.position ?? '...'
    },
    position_1() {
      return this.compare_user_1?.position ?? '...'
    },

    is_version_0_exist() {
      return !!this.compare_version_0_list?.length > 0
    },
    is_version_1_exist() {
      return !!this.compare_version_1_list?.length > 0
    },

    is_completed_0() {
      return !!this.compare_task_0?.is_completed
    },
    is_completed_1() {
      return !!this.compare_task_1?.is_completed
    },

    is_checked_0() {
      return !!this.compare_task_0?.is_checked
    },
    is_checked_1() {
      return !!this.compare_task_1?.is_checked
    },

    is_analog_completed_0() {
      return !!this.compare_task_0?.is_analog_completed
    },
    is_analog_completed_1() {
      return !!this.compare_task_1?.is_analog_completed
    },

    vl_loading_0() {
      return (
        !this.is_user_0_exist ||
        !this.compare_version_0_list ||
        this.compare_loading_0
      )
    },
    vl_loading_1() {
      return (
        !this.is_user_1_exist ||
        !this.compare_version_1_list ||
        this.compare_loading_1
      )
    },

    version_hint_0() {
      if (!this.compare_expertise_0?.time_edit)
        return '!!! Экспертиза не проводилась'

      const updateTime = new Date(
        this.compare_expertise_0?.time_edit
      ).toLocaleString()
      return `Обновлена: ${updateTime}`
    },
    version_hint_1() {
      if (!this.compare_expertise_1?.time_edit)
        return '!!! Экспертиза не проводилась'

      const updateTime = new Date(
        this.compare_expertise_1?.time_edit
      ).toLocaleString()
      return `Обновлена: ${updateTime}`
    },
  },
  methods: {
    ...mapActions(['COMPARE_SET_EXPERTISE_VERSION']),

    setV0() {
      this.$emit('set-version', this.compare_expertise_0_Data)
    },
    setV1() {
      this.$emit('set-version', this.compare_expertise_1_Data)
    },
    clearResult() {
      this.$emit('set-version', null)
    },
    changeVersion(index, uuid) {
      this.COMPARE_SET_EXPERTISE_VERSION({ index, uuid })
    },
  },
}
</script>
