<template>
  <span v-if="!expanded || lineCount === wordsCount"
    >{{ firstLine
    }}<span v-if="lineCount - 1 > wordsCount">
      <v-chip :title="chipTitle" small @click="expanded = true" class="ml-2">
        и ещё {{ lineCount - wordsCount }}...
      </v-chip>
    </span>
  </span>
  <span v-else
    >{{ value }}
    <v-chip
      v-if="closed && lineCount - 1 > wordsCount"
      small
      @click="expanded = false"
      class="ml-2"
    >
      скрыть элементы
    </v-chip>
  </span>
</template>

<script>
export default {
  name: 'expandText',
  data: () => ({
    expanded: false,
  }),
  props: {
    value: {
      type: String,
    },
    chipTitle: {
      type: String,
      default: 'Показать всё',
    },
    separator: {
      type: String,
      default: '\n',
    },
    closed: {
      type: Boolean,
      default: false,
    },
    wordsCount: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    lineCount() {
      return this.lines?.length
    },
    lines() {
      return this.value?.split(this.separator)
    },
    firstLine() {
      return this.lines?.slice(0, this.wordsCount).join(this.separator)
    },
  },
}
</script>
