<template>
  <v-icon
    v-if="index === 0"
    title="Основной препарат"
    v-text="`mdi-checkbox-marked-circle-outline`"
    class="primary--text text--darken-1"
    v-bind="$attrs"
  /><v-icon
    v-else-if="value"
    title="Используется как аналог"
    v-text="`mdi-checkbox-marked-circle-outline`"
    class="success--text"
    v-bind="$attrs"
  /><v-icon
    v-else
    title="Не используется как аналог"
    v-text="`mdi-checkbox-blank-circle-outline`"
    class="grey--text text--lighten-2"
    v-bind="$attrs"
  />
</template>

<script>
export default {
  name: 'AnalogIcon',
  inheritAttrs: false,
  props: {
    value: { type: Boolean },
    index: { type: Number },
  },
}
</script>
